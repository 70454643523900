import React from "react";
import Blog1Image from "../../assets/Blog3Image.png";
import leftArrow from "../../assets/leftArrow.svg";
import Bookmarkbw from "../../assets/Bookmarkbw.svg";
import { Link } from "react-router-dom";
import Layout from "../../components/JourneyLayout";

const Blog3 = () => {
  return (
    <>
      <div>
        <img src={Blog1Image} alt="Blog3" />
        <div className="fixed top-4 left-6">
          <Link to="/home">
            <div className="p-[10px] shadow-back_button flex items-center justify-center h-[32px] w-[32px] bg-base_white rounded-full">
              <img src={leftArrow} alt="back" className="w-full h-full" />
            </div>
          </Link>
        </div>
        <div className="fixed top-4 right-6">
          <Link to="/home">
            <div className="p-[10px] shadow-back_button flex items-center justify-center h-[32px] w-[32px] bg-base_white rounded-full">
              <img src={Bookmarkbw} alt="bookmark" className="w-full h-full" />
            </div>
          </Link>
        </div>
      </div>
      <Layout>
        <h1 className="ph-display-title leading-[39.6px] font-inter font-[400] text-light_bg_primary mt-6">
          How to Lower Your Resting Heart Rate
        </h1>
        <div className="flex items-start mt-3">
          <div className="px-1 max-w-5 rounded-full pt-[4.5px] pb-[3.5px] bg-[#1763D8]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                d="M9.76497 3.20474C10.0661 3.48915 10.0797 3.96383 9.79526 4.26497L5.54526 8.76497C5.40613 8.91228 5.21332 8.99703 5.01071 8.99993C4.8081 9.00282 4.61295 8.92361 4.46967 8.78033L2.21967 6.53033C1.92678 6.23744 1.92678 5.76257 2.21967 5.46967C2.51256 5.17678 2.98744 5.17678 3.28033 5.46967L4.98463 7.17397L8.70474 3.23503C8.98915 2.9339 9.46383 2.92033 9.76497 3.20474Z"
                fill="white"
              />
            </svg>
          </div>
          <p className="text-light_bg_primary opacity-65 body-small ml-[6px]">
            Reviewed by healthcare professionals
          </p>
        </div>
        <div className="flex mt-2">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
            >
              <path
                d="M10 2.5C14.4183 2.5 18 6.08172 18 10.5C18 14.9183 14.4183 18.5 10 18.5C5.58172 18.5 2 14.9183 2 10.5C2 6.08172 5.58172 2.5 10 2.5ZM10 3.5C6.13401 3.5 3 6.63401 3 10.5C3 14.366 6.13401 17.5 10 17.5C13.866 17.5 17 14.366 17 10.5C17 6.63401 13.866 3.5 10 3.5ZM9.5 5.5C9.74546 5.5 9.94961 5.67688 9.99194 5.91012L10 6V10.5H12.5C12.7761 10.5 13 10.7239 13 11C13 11.2455 12.8231 11.4496 12.5899 11.4919L12.5 11.5H9.5C9.25454 11.5 9.05039 11.3231 9.00806 11.0899L9 11V6C9 5.72386 9.22386 5.5 9.5 5.5Z"
                fill="#6C737F"
              />
            </svg>
          </div>
          <p className="text-light_bg_primary opacity-65 body-small ml-[6px]">
            12 min read
          </p>
        </div>
        <h1 className="heading-large text-light_bg_primary mt-10 mb-2">
          Lifestyle Changes to Lower Your Heart Rate
        </h1>
        <p className="body-large text-light_bg_secondary">
          In general, people who are fitter and less stressed are more likely to
          have a good resting heart rate. A few lifestyle changes can help you
          slow it down:
        </p>
        <ul className="list-disc mt-3 ml-6">
          <li className="body-large text-light_bg_secondary">
            <span className="text-light_bg_primary">Exercise regularly. </span>
            It raises your pulse for a while, but over time, exercise makes your
            heart stronger, so it works better.
          </li>
          <li className="body-large text-light_bg_secondary">
            <span className="text-light_bg_primary">Eat right. </span>Foods that
            are high in sodium, sugar, and carbs can sometimes cause a temporary
            spike in your heart rate. Studies have found lower heart rates in
            those assigned male at birth (AMAB) who eat more fish.
          </li>
          <li className="body-large text-light_bg_secondary">
            <span className="text-light_bg_primary">Tackle stress. </span>Set
            aside time to disconnect from electronic devices and relax each day.
            Meditation, tai chi, and breathing exercises can also help.
          </li>
          <li className="body-large text-light_bg_secondary">
            <span className="text-light_bg_primary">Stop smoking. </span>It’s
            one of the best things you can do for your overall health. Among its
            many harmful effects, smoking boosts your resting heart rate.
          </li>
          <li className="body-large text-light_bg_secondary">
            <span className="text-light_bg_primary">Limit caffeine. </span>
            Drinking too much coffee can increase your heart rate.
          </li>
          <li className="body-large text-light_bg_secondary">
            <span className="text-light_bg_primary">Limit energy drinks. </span>
            Energy drinks contain ingredients that magnify the heart-stimulating
            effects of caffeine.
          </li>
          <li className="body-large text-light_bg_secondary">
            <span className="text-light_bg_primary">Drink enough water. </span>
            Your blood gets sluggish when you're dehydrated, and your heart must
            use more force to pump it.
          </li>
          <li className="body-large text-light_bg_secondary">
            <span className="text-light_bg_primary">Limit alcohol. </span>It
            raises your heart rate, as does cannabis.
          </li>
          <li className="body-large text-light_bg_secondary">
            <span className="text-light_bg_primary">Get enough sleep. </span>Not
            getting a full night's sleep is unhealthy for your heart. Make sure
            to treat sleep apnea if you have it.
          </li>
          <li className="body-large text-light_bg_secondary">
            <span className="text-light_bg_primary">
              Maintain a healthy weight.{" "}
            </span>
            If you are overweight or obese, your heart needs to work harder.
          </li>
        </ul>
        <h1 className="heading-large text-light_bg_primary mt-6">
          Heart Rate Medications
        </h1>
        <p className="body-large text-light_bg_secondary mt-2">
          If you have a faster-than-normal heart rate (your heart beats more
          than 100 times per minute), you have what doctors call tachycardia.
          It’s not always a bad thing. Exercise, for example, can push your
          heart rate above 100 bpm. However, you may require medication to slow
          your heart rate if it’s fast due to a health condition such as atrial
          fibrillation or arrhythmia. These medications include:
        </p>
        <ul className="list-disc mt-3 ml-6">
          <li className="body-large text-light_bg_secondary">
            <span className="text-light_bg_primary">Beta-blockers. </span>This
            class of blood pressure medication slows your heart rate. Some
            examples of beta-blockers are:
            <ul className="list-disc mt-3 ml-6">
              <li className="body-large text-light_bg_secondary">
                Atenolol (Tenormin)
              </li>
              <li className="body-large text-light_bg_secondary">
                Bisoprolol (Zebeta)
              </li>
              <li className="body-large text-light_bg_secondary">
                Carvedilol (Coreg)
              </li>
              <li className="body-large text-light_bg_secondary">
                Metoprolol (Lopressor)
              </li>
            </ul>
          </li>
          <li className="body-large text-light_bg_secondary mt-2">
            <span className="text-light_bg_primary">
              Calcium channel blockers.{" "}
            </span>
            This class of blood pressure medication also slows your heart rate.
            These medications include:
            <ul className="list-disc mt-3 ml-6">
              <li className="body-large text-light_bg_secondary">
                Diltiazem (Cardizem)
              </li>
              <li className="body-large text-light_bg_secondary">
                Verapamil (Calan)
              </li>
            </ul>
          </li>
          <li className="body-large text-light_bg_secondary mt-2">
            <span className="text-light_bg_primary">Cardiac glycosides. </span>
            This class of medication, which treats atrial fibrillation and heart
            failure, slows your heart rate. The most commonly prescribed drug in
            this class is digoxin (Cardoxin).
          </li>
        </ul>
      </Layout>
    </>
  );
};

export default Blog3;
