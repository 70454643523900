import React, { useState } from "react";
import BloodPressurebw from "../assets/BloodPressurebw.svg";
import CaretUp from "../assets/CaretUp.svg";
import CaretDown from "../assets/CaretDown.svg";
import BloodPressureModal from "./BloodPressureModal";

const BloodPressureCard = ({ bloodPressureDia, bloodPressureSys }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const mockbloodPressureDia = 78;
  const mockbloodPressureSys = 120;

  const diaValue = bloodPressureDia || mockbloodPressureDia;
  const sysValue = bloodPressureSys || mockbloodPressureSys;

  const isSysInRange = sysValue >= 90 && sysValue <= 120; // Adjust range as needed
  const isDiaInRange = diaValue >= 60 && diaValue <= 80; // Adjust range as needed

  return (
    <>
      <div
        className="p-3 bg-base_white shadow-result_card rounded-[16px] w-full h-[175px] max-w-[175px] mt-3 border-[1px] border-light_bg_primary border-opacity-[0.12]"
        onClick={handleOpenModal}
      >
        <div className="flex gap-[6px] items-center">
          <img src={BloodPressurebw} alt="blood pressure" />
          <p className="text-sm-medium text-light_bg_primary text-opacity-95">
            Blood Pressure
          </p>
        </div>
        <div className="flex items-baseline gap-[6px] mt-[3px]">
          <h3 className="ph-display-number text-light_bg_primary">
            {sysValue}/{diaValue}
          </h3>
        </div>
        <p className="body-small text-light_bg_secondary">mmHg</p>
        <div className="flex items-center justify-between mt-4">
          <p className="body-small text-light_bg_secondary">Systolic</p>
          <div className="flex">
            <p className="text-sm-regular font-[600]">{sysValue}</p>
            <img
              src={isSysInRange ? CaretDown : CaretUp}
              alt={isSysInRange ? "caret down" : "caret up"}
              className="ml-1"
            />
          </div>
        </div>
        <div className="flex items-center justify-between">
          <p className="body-small text-light_bg_secondary">Diastolic</p>
          <div className="flex">
            <p className="text-sm-regular font-[600]">{diaValue}</p>
            <img
              src={isDiaInRange ? CaretDown : CaretUp}
              alt={isDiaInRange ? "caret down" : "caret up"}
              className="ml-1"
            />
          </div>
        </div>
      </div>
      <BloodPressureModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        systolic={sysValue}
        diastolic={diaValue}
      />
    </>
  );
};

export default BloodPressureCard;
