import React from "react";

const BlogCard = ({ time, title, description, image }) => {
  return (
    <>
      <div className="rounded-[16px] border-[1px] border-[#E5E7EB] mb-4">
        <img src={image} alt="blog" className="pt-1 pl-1 pr-1"></img>
        <div className="pt-2 px-4 pb-4">
          <h1 className="heading-large text-light_bg_primary opacity-85 mt-1">
            {title}
          </h1>
          <p className="text-sm-regular text-light_bg_primary opacity-65 mt-1">
            {description}
          </p>
        </div>
      </div>
    </>
  );
};

export default BlogCard;
