import React from "react";
import Blog1Image from "../../assets/Blog2Image.png";
import leftArrow from "../../assets/leftArrow.svg";
import Bookmarkbw from "../../assets/Bookmarkbw.svg";
import { Link } from "react-router-dom";
import Layout from "../../components/JourneyLayout";

const Blog2 = () => {
  return (
    <>
      <div>
        <img src={Blog1Image} alt="Blog2" />
        <div className="fixed top-4 left-6">
          <Link to="/home">
            <div className="p-[10px] shadow-back_button flex items-center justify-center h-[32px] w-[32px] bg-base_white rounded-full">
              <img src={leftArrow} alt="back" className="w-full h-full" />
            </div>
          </Link>
        </div>
        <div className="fixed top-4 right-6">
          <Link to="/home">
            <div className="p-[10px] shadow-back_button flex items-center justify-center h-[32px] w-[32px] bg-base_white rounded-full">
              <img src={Bookmarkbw} alt="bookmark" className="w-full h-full" />
            </div>
          </Link>
        </div>
      </div>
      <Layout>
        <h1 className="ph-display-title leading-[39.6px] font-inter font-[400] text-light_bg_primary mt-6">
          Heart Rate and Anxiety
        </h1>
        <div className="flex items-start mt-3">
          <div className="px-1 max-w-5 rounded-full pt-[4.5px] pb-[3.5px] bg-[#1763D8]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                d="M9.76497 3.20474C10.0661 3.48915 10.0797 3.96383 9.79526 4.26497L5.54526 8.76497C5.40613 8.91228 5.21332 8.99703 5.01071 8.99993C4.8081 9.00282 4.61295 8.92361 4.46967 8.78033L2.21967 6.53033C1.92678 6.23744 1.92678 5.76257 2.21967 5.46967C2.51256 5.17678 2.98744 5.17678 3.28033 5.46967L4.98463 7.17397L8.70474 3.23503C8.98915 2.9339 9.46383 2.92033 9.76497 3.20474Z"
                fill="white"
              />
            </svg>
          </div>
          <p className="text-light_bg_primary opacity-65 body-small ml-[6px]">
            Reviewed by healthcare professionals
          </p>
        </div>
        <div className="flex mt-2">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
            >
              <path
                d="M10 2.5C14.4183 2.5 18 6.08172 18 10.5C18 14.9183 14.4183 18.5 10 18.5C5.58172 18.5 2 14.9183 2 10.5C2 6.08172 5.58172 2.5 10 2.5ZM10 3.5C6.13401 3.5 3 6.63401 3 10.5C3 14.366 6.13401 17.5 10 17.5C13.866 17.5 17 14.366 17 10.5C17 6.63401 13.866 3.5 10 3.5ZM9.5 5.5C9.74546 5.5 9.94961 5.67688 9.99194 5.91012L10 6V10.5H12.5C12.7761 10.5 13 10.7239 13 11C13 11.2455 12.8231 11.4496 12.5899 11.4919L12.5 11.5H9.5C9.25454 11.5 9.05039 11.3231 9.00806 11.0899L9 11V6C9 5.72386 9.22386 5.5 9.5 5.5Z"
                fill="#6C737F"
              />
            </svg>
          </div>
          <p className="text-light_bg_primary opacity-65 body-small ml-[6px]">
            8 min read
          </p>
        </div>
        <h1 className="heading-large text-light_bg_primary mt-10 mb-2">
          Heart Rate and Anxiety
        </h1>
        <p className="body-large text-light_bg_secondary">
          When you feel anxious or stressed, your heart rate goes up. Why? It’s
          part of what’s called our fight or flight response, in which a
          perceived danger triggers the release of stress hormones, such as
          adrenaline. This puts your body in high gear to allow you to face
          threats or make a swift escape. Your brain does this when you're
          facing physical danger, such as an armed robber. But this process can
          kick in for more mundane stressors, such as a fast-approaching work
          deadline.
        </p>
        <p className="body-large text-light_bg_secondary mt-6">
          These stress hormones also impact the parts of the brain that manage
          both your blood pressure and your heart rate. So, as a result of your
          anxiety (whatever the cause), your heart can start pounding, beating
          fast, or skipping beats. These are called heart palpitations.
        </p>
        <h1 className="mt-6 heading-large text-light_bg_primary">
          Anxiety and Heart Health
        </h1>
        <p className="body-large text-light_bg_secondary mt-2">
          If this happens often, such as if you have an anxiety disorder, the
          effect on your heart rate could contribute to heart disease. In very
          serious cases, the effect on your heart rate could lead to cardiac
          arrest, in which your heart suddenly becomes unable to pump blood.
          This can quickly result in death if not treated promptly.
        </p>
        <p className="body-large text-light_bg_secondary mt-6">
          It works both ways, though. If you have palpitations due to an
          arrhythmia, for example, you may develop anxiety, which can then lead
          to more palpitations, creating a tough cycle.
        </p>
        <h1 className="heading-large text-light_bg_primary mt-6">
          When to Seek Help
        </h1>
        <p className="body-large text-light_bg_secondary mt-2">
          If you feel anxious or experience heart palpitations occasionally,
          that’s probably normal. We all get stressed sometimes. But if it
          occurs frequently, see a doctor to determine what’s going on.
        </p>
      </Layout>
    </>
  );
};

export default Blog2;
