import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import Mood1 from "../../assets/Emojis/Mood6.png";
import Mood2 from "../../assets/Emojis/Mood7.png";
import Mood3 from "../../assets/Emojis/Mood8.png";
import Mood4 from "../../assets/Emojis/Mood9.png";
import Mood5 from "../../assets/Emojis/Mood10.png";
import chevronLeft from "../../assets/chevronLeft.svg";
import { useNavigate } from "react-router-dom";
import { setMoodData } from "../../utils/MoodLocalStorage";
import { Link } from "react-router-dom";
import { sendAmplitudeData } from "../../utils/amplitude";

const emojis = [Mood1, Mood2, Mood3, Mood4, Mood5];

const MoodLog2 = () => {
  const [sliderValue, setSliderValue] = useState(2);
  const [dayValue, setDayValue] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const day = localStorage.getItem("Day");
    setDayValue(day);
  }, []);

  const handleSliderChange = (e) => {
    setSliderValue(Number(e.target.value));
  };

  const handleSliderRelease = () => {
    sendAmplitudeData("MOOD_SLIDER2_CHANGE");
  };

  const emojiIndex = Math.round(sliderValue);

  const handleSeeResultsClick = () => {
    setMoodData(dayValue, "mood2", sliderValue);
    if (!dayValue || dayValue === "null") {
      navigate("/intro-scan/results");
      sendAmplitudeData("DAY1_MOOD_COMPLETED");
    } else {
      navigate("/intro-questionnaire/questions");
    }
  };

  return (
    <Layout>
      <div className="flex-col items-center">
        {/* Back button left aligned */}
        <div className="flex justify-between items-center">
          <Link to="/intro-scan/scan/mood1" className="absolute top-4 left-5">
            <div className="w-8 h-8 p-[5px] flex justify-center items-center bg-[#F3F4F6] rounded-full">
              <img
                className="min-h-[18px] min-w-[18px]"
                src={chevronLeft}
                alt="Back Button"
              />
            </div>
          </Link>

          {/* Share your mood center aligned */}
          <div className="flex-1 flex justify-center">
            <h1 className="mt-5 mb-6 text-light_bg_secondary heading-large">
              Share your mood
            </h1>
          </div>
        </div>
        <h1 className="display-title text-light_bg_primary text-opacity-95">
          How energetic are you feeling today?
        </h1>
        <p className="mt-2 text-md-regular text-light_bg_secondary">
          This information is helpful for us to compute your overall health and
          produce accurate results
        </p>
      </div>
      <div className="flex justify-center mt-[33px] mb-[40px]">
        <img
          src={emojis[emojiIndex]}
          alt="Mood Emoji"
          className="transition duration-500 ease-in-out"
        />
      </div>
      <div className="flex justify-center">
        <input
          type="range"
          min="0"
          max="4"
          step="0.01"
          value={sliderValue}
          onChange={handleSliderChange}
          onMouseUp={handleSliderRelease} // Send event when the mouse button is released
          onTouchEnd={handleSliderRelease} // Send event when the touch ends
          className="w-full slider"
        />
      </div>
      <div className="flex justify-between w-full mt-[22px] mb-6 text-sm-regular text-light_bg_primary">
        <span>Very sleepy</span>
        <span>Very energetic</span>
      </div>

      <div className="w-full z-50 flex-col max-w-[393px] mx-auto fixed bottom-0 left-0 right-0 px-5 pb-5 mt-4">
        <div className="w-full">
          <button
            onClick={handleSeeResultsClick}
            className="text-md-semibold max-h-[45.2px] font-inter flex w-full justify-center items-center bg-primary_500 px-5 py-3 border border-primary_500 border-solid rounded-lg mb-3 text-base_white shadow-xs"
          >
            See results
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default MoodLog2;
