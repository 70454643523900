import amplitude from 'amplitude-js';
import getVariant from './GetVariant';

export const initAmplitude = () => {
  let variant = localStorage.getItem('variant');
  if (!variant) {
    localStorage.setItem('variant', getVariant());
  }
  amplitude.getInstance().init(
    process.env.REACT_APP_AMPLITUDE_API_KEY,
    null,
    {
      logLevel: 'INFO',
      apiEndpoint: 'https://api.eu.amplitude.com/2/httpapi',
    },
    () => {
      console.log('Amplitude initialized successfully');
    }
  );
};

export const setAmplitudeUserDevice = (installationToken) => {
  amplitude.getInstance().setDeviceId(installationToken);
};

export const setAmplitudeUserId = (userId) => {
  amplitude.getInstance().setUserId(userId);
};

export const setAmplitudeUserProperties = (properties) => {
  amplitude.getInstance().setUserProperties(properties);
};

export const sendAmplitudeData = async (eventType, eventProperties = {}) => {
  const apiKey = process.env.REACT_APP_AMPLITUDE_API_KEY;
  const userId = localStorage.getItem('userId');
  const deviceId = localStorage.getItem('installationToken');
  const variant = localStorage.getItem('variant');

  // Validate that userId and deviceId are present
  if (!userId || !deviceId) {
    console.error('Missing userId or deviceId. Cannot send event.');
    return;
  }

  const payload = {
    api_key: apiKey,
    events: [
      {
        user_id: userId,
        device_id: deviceId,
        event_type: eventType,
        event_properties: {
          ...eventProperties,
          feature_variant: variant, //for A/B testing
        },
      },
    ],
  };

  // Send the POST request to Amplitude
  try {
    const response = await fetch('https://api.eu.amplitude.com/2/httpapi', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error(
        'Failed to send event to Amplitude:',
        response.status,
        errorText
      );
    } else {
      console.log('Event sent successfully to Amplitude.');
    }
  } catch (error) {
    console.error('Error sending event to Amplitude:', error);
  }
};
