import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import axios from "axios";
import Loader from "../../utils/Loader";
import CtaButton from "../../components/CtaButton";
import HeartRate from "../../components/HeartRate";
import { Link } from "react-router-dom";
import xclose from "../../assets/x-close.svg";
import LockedGaugeGraph from "../../components/LockedGaugeCard";
import LockedLinearCard from "../../components/LockedLinearCard";
import DocumentText from "../../assets/DocumentText.svg";

const Result = () => {
  const [data, setData] = useState(null);
  const [showSplash, setShowSplash] = useState(true);
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    const splashTimeout = setTimeout(() => {
      setShowSplash(false);
    }, 5000);

    return () => clearTimeout(splashTimeout);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/journeys/${userId}`
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [userId]);

  if (showSplash) {
    return (
      <div className="w-screen h-screen flex justify-center items-center bg-base_white transition-opacity duration-500 ease-in-out">
        <div className="flex flex-col justify-center items-center">
          <div>
            <img src={DocumentText} alt="logo" />
          </div>
          <p className="heading-large mx-16 text-center text-light_bg_primary">
            We are preparing your first report...
          </p>
          <div
            className="w-[245px] mt-4 h-2 rounded-full overflow-hidden"
            style={{ backgroundColor: "rgba(31, 42, 55, 0.12)" }}
          >
            <div
              className="h-full rounded-full"
              style={{
                backgroundColor: "rgba(31, 42, 55, 0.85)",
                animation: "fillProgress 3s linear forwards",
              }}
            ></div>
          </div>
        </div>
        <style>
          {`
            @keyframes fillProgress {
              from { width: 0; }
              to { width: 100%; }
            }
          `}
        </style>
      </div>
    );
  }

  if (!data) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <div className="bg-gray_neutral_50 h-screen pb-12">
      <Layout>
        <div className="pb-24">
          <>
            <h3 className="text-center heading-large text-light_bg_secondary mt-4 mb-4">
              Results
            </h3>
            <Link to="/home">
              <button className="absolute top-[16px] right-5 p-[6.33px] flex items-center justify-center bg-gray_100 rounded-[133.333px]">
                <img src={xclose} alt="Close" />
              </button>
            </Link>
            <p className="text-gray_500 text-sm-regular">
              All of your health results from your frequent scans are present
              here
            </p>
          </>
          {data?.HR && <HeartRate heartRate={data.HR} />}
          <p className="heading-small text-light_bg_secondary mt-8">
            Come back everyday to unlock more results
          </p>
          <div className="mt-2">
            <LockedLinearCard
              CardName="Cardiorespiratory rhythm"
              Icon={3}
              day={2}
            />
            <LockedLinearCard CardName="Heart age" Icon={2} day={3} />
            <LockedLinearCard CardName="Heart energy" Icon={1} day={4} />
            <LockedGaugeGraph CardName="Sleep Apnea Score" Icon={1} day={4} />
            <LockedGaugeGraph CardName="Diabetes Risk Score" Icon={2} day={5} />
          </div>
          <div
            onClick={() =>
              (window.location.href = "/create-account/permissions")
            }
            className="w-full z-50 bg-white max-w-[393px] mx-auto fixed bottom-0 left-0 right-0 pt-1 px-5 pb-5"
          >
            <CtaButton ButtonText="Done" />
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Result;
