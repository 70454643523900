import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import JourneyLayout from '../../components/JourneyLayout';
import ProfilerBG from '../../assets/ProfilerBG.png';
import chevron from '../../assets/chevronLeft.svg';
import Calender from '../../assets/Calender.svg';
import Clock from '../../assets/clock.svg';
import ProgressDial from '../../components/ProgressDial';
import CtaButton from '../../components/CtaButton';
import ProfilerDetailCard from '../../components/ProfilerDetailCard';
import HeartPulseRed from '../../assets/HeartPulseRed.svg';
import DiabetesSyringeRed from '../../assets/DiabetesSyringeRed.svg';
import SleepApneaRed from '../../assets/SleepApneaRed.svg';
import Loader from '../../utils/Loader';
import { sendAmplitudeData } from '../../utils/amplitude';

// Set this flag to true to disable the scan restriction for testing
const devMode = process.env.DEV_MODE || false;

const Profiler = () => {
  const navigate = useNavigate();
  const [pageState, setPageState] = useState({
    isLoading: true,
    day: 0,
    scanAllowed: devMode,
    isSubscribed: false,
  });

  useEffect(() => {
    const initializePageState = async () => {
      let storedDay = parseInt(localStorage.getItem('Day'), 10);
      const isSubscribed = !isNaN(storedDay);
      let scanAllowed = devMode;

      // Fetch scan availability only if the user is subscribed
      if (isSubscribed) {
        const scanAvailability = await checkScanAvailability();
        scanAllowed = scanAvailability.allowed;
      }

      // Get the day value from localStorage
      storedDay = parseInt(localStorage.getItem('Day'), 10);

      setPageState({
        isLoading: false,
        day: isSubscribed ? storedDay : 0,
        scanAllowed,
        isSubscribed,
      });
    };

    initializePageState();
  }, []);

  const checkScanAvailability = async () => {
    if (devMode) {
      return { allowed: true }; // Always return an object with allowed true in dev mode
    }

    const userId = localStorage.getItem('userId');
    if (!userId) {
      console.error('User ID not found in local storage.');
      return { allowed: false }; // Return false if no user ID found
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/scans/${userId}`
      );
      const scans = response.data;

      if (scans && scans.length > 0) {
        const latestScan = scans[scans.length - 1];

        const latestScanTimeIST = new Date(
          latestScan.scan_completion_time
        );
        const currentTimeIST = new Date(); // Assuming client's clock is in IST

        const next6AM = new Date(latestScanTimeIST);
        next6AM.setHours(6, 0, 0, 0);

        if (next6AM <= latestScanTimeIST) {
          // If the latest scan was at or after 6 AM, set next6AM to 6 AM on the next day
          next6AM.setDate(next6AM.getDate() + 1);
        }

        if (currentTimeIST >= next6AM) {
          // Scan is allowed after 6 AM IST
          return { allowed: true };
        } else {
          // Scan is not allowed before 6 AM IST of the next day
          return { allowed: false };
        }
      } else {
        // No scans exist, allow scan
        return { allowed: true };
      }
    } catch (error) {
      console.error('Error fetching scans:', error);
      return { allowed: false }; // Return false in case of an error
    }
  };

  const handleBeginScan = () => {
    if (pageState.scanAllowed || devMode) {
      navigate('/intro-scan/scan', { replace: true });
    } else {
      console.error('Scan is not allowed yet.');
    }
  };

  const handleSubscribe = async () => {
    const userId = localStorage.getItem('userId');

    if (!userId) {
      console.error('User ID not found in local storage.');
      return;
    }

    try {
      await Promise.all([
        axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/questionnaires/${userId}`
        ),
        axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/journeys/${userId}`
        ),
      ]);

      localStorage.setItem('Day', 1);

      setPageState((prevState) => ({
        ...prevState,
        day: 1,
        isSubscribed: true,
      }));

      navigate('/intro-questionnaire', { replace: true });
      sendAmplitudeData('RESTING_HEART_SIGN_UP');

      console.log(
        'Questionnaire, Journey created, and Day value set successfully.'
      );
    } catch (error) {
      console.error(
        'Error creating questionnaire or journey, or updating Day value:',
        error
      );
    }
  };

  const cardsData = [
    {
      image: HeartPulseRed,
      title: 'Baseline heart health',
      description:
        'This will help you know if your heart is doing well and if there are any changes that might happen in the future which can be prevented.',
      expandedContent: (
        <div>
          <h1 className="heading-large text-base_white">
            What you'll learn
          </h1>
          <h2 className="text-sm-medium text-base_white mt-2">
            Resting heart rate
          </h2>
          <p className="text-sm-regular text-base_white opacity-65 mt-[2px] flex items-center">
            A measure of your maximum heart rate within a safe range
            (50%-85%).
          </p>
          <h2 className="text-sm-medium text-base_white mt-2">
            Cardiorespiratory rhythm
          </h2>
          <p className="text-sm-regular text-base_white opacity-65 mt-[2px] flex items-center">
            A measure of your heart and lungs activity.
          </p>
          <h2 className="text-sm-medium text-base_white mt-2">
            Heart age
          </h2>
          <p className="text-sm-regular text-base_white opacity-65 mt-[2px] flex items-center">
            An estimate of your heart's age that gives you an idea
            about how healthy your heart is.
          </p>
        </div>
      ),
    },
    {
      image: DiabetesSyringeRed,
      title: 'Diabetes risk',
      description: 'Measures risk and guides accordingly',
      expandedContent: (
        <div>
          <h1 className="heading-large text-base_white">
            What you'll learn
          </h1>
          <h2 className="text-sm-medium text-base_white mt-2">
            Diabetes risk
          </h2>
          <p className="text-sm-regular text-base_white opacity-65 mt-[2px] flex items-center">
            Information about your diabetes risk and how to manage it.
          </p>
        </div>
      ),
    },
    {
      image: SleepApneaRed,
      title: 'Sleep Apnea',
      description: 'Risk of acquiring Obstructive sleep apnea',
      expandedContent: (
        <div>
          <h1 className="heading-large text-base_white">
            What you'll learn
          </h1>
          <h2 className="text-sm-medium text-base_white mt-2">
            Sleep Apnea
          </h2>
          <p className="text-sm-regular text-base_white opacity-65 mt-[2px] flex items-center">
            Information about sleep apnea and its effects on your
            health.
          </p>
        </div>
      ),
    },
  ];

  if (pageState.isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div
        className="w-full relative h-[200px] flex flex-col"
        style={{
          backgroundImage: `url(${ProfilerBG})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="fixed top-4 left-6">
          <Link to="/home">
            <div className="shadow-back_button flex items-center justify-center h-[32px] w-[32px] bg-base_white rounded-full">
              <img
                src={chevron}
                alt="back"
                className="max-w-[18px] max-h-[18px]"
              />
            </div>
          </Link>
        </div>

        <h1 className="display-title text-light_bg_primary opacity-95 mx-6 mt-auto">
          Resting heart
        </h1>
        <p className="mt-2 text-light_bg_secondary text-sm-regular mx-6">
          A 5 day programme designed to profile your heart health and
          help you identify related risks.
        </p>
        <div className="flex items-center mt-2 mx-6 mb-4">
          <img
            src={Calender}
            className="mr-[6px]"
            alt="calender"
          ></img>
          <p className="text-sm-regular mr-5 text-light_bg_secondary">
            5 Days
          </p>
          <img src={Clock} className="mr-[6px]" alt="clock"></img>
          <p className="text-sm-regular text-light_bg_secondary">
            5 min per day
          </p>
        </div>
      </div>
      <JourneyLayout>
        <div className="mt-6">
          <ProgressDial scans={pageState.day - 1} />
        </div>
        <div className="mt-8">
          {cardsData.map((card, index) => (
            <ProfilerDetailCard
              key={index}
              image={card.image}
              title={card.title}
              description={card.description}
              expandedContent={card.expandedContent}
              expandable={index === 0}
            />
          ))}
        </div>
        <div className="w-full bg-white max-w-[393px] mx-auto fixed bottom-0 left-0 right-0 pt-1 px-5 pb-5">
          {pageState.day > 5 ? (
            <div className="opacity-25">
              <CtaButton
                ButtonText="Remind me to test in 30 days"
                LinkTo="/home"
              />
            </div>
          ) : pageState.isSubscribed ? (
            <div
              onClick={
                pageState.scanAllowed ? handleBeginScan : undefined
              }
            >
              {pageState.scanAllowed ? (
                <CtaButton
                  ButtonText={`Begin scan - Day ${pageState.day}`}
                  LinkTo="/intro-scan/scan"
                />
              ) : (
                // Custom button for when scan is not allowed
                <button className="text-sm-regular font-inter flex w-full max-w-[353px] justify-center items-center bg-[#F3F4F6] px-5 py-3 rounded-lg mb-3 text-light_bg_secondary shadow-xs z-10">
                  Come back tomorrow for your next report
                </button>
              )}
            </div>
          ) : (
            <div onClick={handleSubscribe}>
              <CtaButton
                ButtonText="Get your heart care report"
                LinkTo="/intro-questionnaire"
              />
            </div>
          )}
        </div>
      </JourneyLayout>
    </>
  );
};

export default Profiler;
