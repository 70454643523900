import React from "react";
import SignatureGradient from "../../src/assets/SignatureGradient.png";
import PersonHeart from "../../src/assets/PersonHeart.svg";
import PersonRunning from "../../src/assets/PersonRunning.svg";
import BrainCircuit from "../../src/assets/BrainCircuit.svg";

const SignatureJourney = ({ completedSteps = 0 }) => {
  const steps = [
    {
      title: "Circulation efficiency",
      description:
        "Measure how effectively your heart pumps blood during activity.",
      image: PersonHeart,
    },
    {
      title: "Performance and recovery",
      description:
        "Track your heart’s oxygen supply during and after exercise.",
      image: PersonRunning,
    },
    {
      title: "Stress response",
      description: "Detect how your heart adapts to daily stressors.",
      image: BrainCircuit,
    },
  ];

  return (
    <div className="max-w-md mt-4 mx-auto bg-white rounded-[16px] overflow-hidden shadow-journey_card">
      <div
        style={{
          backgroundImage: `url(${SignatureGradient})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="pb-4 pl-4 pr-4 pt-5"
      >
        <h2 className="heading-large text-light_bg_primary opacity-85">
          Active heart journey
        </h2>
        <p className="text-sm-regular text-light_bg_primary opacity-65">
          Challenge your heart over 7 days to reveal its dynamic responses to
          exercise, stress, and recovery.
        </p>
      </div>

      <div className="pt-4 pl-4 pr-4 pb-0">
        <div className="flex">
          <div className="flex-grow pr-4 ">
            {steps.map((step, index) => (
              <div key={index} className="mb-4">
                <div className="flex gap-x-4">
                  <div className="min-w-16 min-h-16 max-h-16 max-w-16 flex items-center justify-center bg-[#F9FAFB] rounded-[8px]">
                    <img src={step.image} alt={step.title}></img>
                  </div>
                  <div>
                    <h3 className="text-sm-medium text-light_bg_primary">
                      {step.title}
                    </h3>
                    <p className="text-sm-regular text-light_bg_secondary">
                      {step.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="relative mr-4 ml-4 mt-6 ">
            <div
              className="absolute left-1/2 transform -translate-x-1/2 w-px bg-[#E5E7EB]"
              style={{ height: `${(steps.length - 1) * 33.33}%` }}
            ></div>
            {steps.map((_, index) => (
              <div
                key={index}
                className={`absolute w-5 h-5 rounded-full -left-[0.6rem] ${
                  index < completedSteps
                    ? "bg-[#36BFFA]" //completed
                    : "bg-base_white border-[1px] border-[#E5E7EB]" //incomplete
                }`}
                style={{ top: `${index * 33.33}%` }}
              >
                {index < completedSteps && (
                  <svg
                    className="w-3 h-3 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M5 13l4 4L19 7"></path>
                  </svg>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="mx-4 pb-5">
        <button className="text-sm-regular text-light_bg_secondary flex gap-x-3 w-full items-center justify-center max-w-[313px] h-[48px] bg-[#F3F4F6] rounded-[12px] p-5">
          Complete the Profiler journey to unlock
        </button>
      </div>
    </div>
  );
};

export default SignatureJourney;
