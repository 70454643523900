import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';

import image1 from '../../assets/Image1.png';
import image2 from '../../assets/Image2.png';
import image3 from '../../assets/Image3.png';
import Logo from '../../assets/Frame.svg';

function SignUp() {
  const [progress, setProgress] = useState(0);
  const [currentImage, setCurrentImage] = useState(0);
  const [showContent, setShowContent] = useState(false);
  const intervalRef = useRef(null);

  const texts = [
    {
      title: 'Your heart’s best friend',
      description:
        'Track your heart health and spot potential risks with just your phone. No wearables needed.',
    },
    {
      title: 'Understand your heart',
      description:
        'Track key metrics and get data-driven recommendations to keep your heart healthy.',
    },
    {
      title: 'Small steps, healthier you',
      description:
        'Learn what helps your heart. Receive tailored advice to optimize your wellbeing.',
    },
  ];

  const startImageCycle = () => {
    intervalRef.current = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % texts.length);
      setProgress(
        (prevProgress) => (prevProgress + 1) % texts.length
      );
    }, 5000);
  };

  useEffect(() => {
    // Splash screen
    const splashTimeout = setTimeout(() => {
      setShowContent(true);
    }, 3000);

    // Start the image cycle
    startImageCycle();

    return () => {
      clearTimeout(splashTimeout);
      clearInterval(intervalRef.current);
    };
  });

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      clearInterval(intervalRef.current);
      setCurrentImage((prevImage) => (prevImage + 1) % texts.length);
      setProgress(
        (prevProgress) => (prevProgress + 1) % texts.length
      );
      startImageCycle();
    },
    onSwipedRight: () => {
      clearInterval(intervalRef.current);
      setCurrentImage(
        (prevImage) => (prevImage - 1 + texts.length) % texts.length
      );
      setProgress(
        (prevProgress) =>
          (prevProgress - 1 + texts.length) % texts.length
      );
      startImageCycle();
    },
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
  });

  if (!showContent) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-splash transition-opacity duration-500 ease-in-out">
        <div className="flex flex-col gap-x-3 items-center">
          <img src={Logo} alt="logo" className="w-[43px] h-[43px]" />
          <p className="text-[20.7px] font-normal font-inter text-[#FD6F8E]">
            heartcheck
          </p>
          <p className="heading-large text-light_bg_secondary mx-10 text-center mt-4">
            Heart care made simple
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="overflow-hidden flex flex-col">
      <div {...handlers} className="flex flex-col h-full">
        <div
          className="w-full h-[60vh] absolute bg-cover transition duration-500 ease-in-out"
          style={{
            backgroundImage: `url(${
              currentImage === 0
                ? image1
                : currentImage === 1
                ? image2
                : image3
            })`,
            backgroundPosition: 'center',
            aspectRatio: 'auto',
            maskImage: `linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 10%, rgba(255, 255, 255, 1) 100%)`,
          }}
        ></div>

        <div className="w-full max-w-[393px] mx-auto z-10 flex flex-col items-center justify-end bottom-0 fixed">
          <div>
            <div className="w-full px-5">{/* Progress bar */}</div>

            <div className="w-full flex-col items-center justify-center px-5">
              <div className="space-x-2 max-w-[88px] flex mb-3">
                {[0, 1, 2].map((index) => (
                  <div
                    key={index}
                    className={`h-[6px] w-[28.58%] transition duration-500 ease-in-out ${
                      progress === index
                        ? 'bg-[#1F2A37] opacity-[0.64]'
                        : 'bg-[#1F2A37] opacity-[0.12]'
                    } rounded`}
                  ></div>
                ))}
              </div>
              <h1 className="display-title mb-2 text-light_bg_primary opacity-85">
                {texts[currentImage].title}
              </h1>
              <p className="mb-4 text-md-regular text-light_bg_primary opacity-65">
                {texts[currentImage].description}
              </p>
              <div className="flex gap-x-4 z-10">
                <Link to="/login" className="w-full">
                  <button className="heading-small min-h-12 flex w-full max-w-[353px] bg-base_white justify-center py-3 border-[1px] border-solid border-[#D0D5DD] rounded-lg mb-3 text-light_bg_primary text-opacity-95 shadow-xs z-10">
                    Login
                  </button>
                </Link>
                <Link
                  className="w-full"
                  to="/create-account/user-details"
                >
                  <button className="heading-small text-dark_bg_primary text-opacity-[0.92] flex w-full min-h-12 max-w-[353px] bg-primary_500 justify-center items-center py-3 rounded-lg mb-3 shadow-xs z-10">
                    Get Started
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div
            style={{
              background:
                'linear-gradient(to top, rgba(254, 205, 214, 0.6) 0%, rgba(254, 205, 214, 0.6) 88.89%, rgba(254, 205, 214, 0) 100%)',
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: -1,
              filter: 'blur(32px)',
            }}
            className="w-full h-[45vh]"
          />
        </div>
      </div>
    </div>
  );
}

export default SignUp;
