import React from "react";
import ProfilerSuccess2 from "../../assets/profilerSuccess2.png";
import xclose from "../../assets/x-close.svg";
import { useNavigate } from "react-router-dom";

const ProfilerSuccess = () => {
  const navigate = useNavigate();

  const handleNavigateHome = () => {
    navigate("/home");
  };

  return (
    <>
      <div className="relative">
        <img src={ProfilerSuccess2} alt="Scan" />
        <button
          onClick={handleNavigateHome}
          className="absolute top-4 right-6 p-[6.66px] flex items-center justify-center bg-gray_100 rounded-[133.333px]"
        >
          <img src={xclose} alt="Close" />
        </button>
        <h1 className="absolute bottom-[175px] left-6 mr-6 transform ph-display-title text-light_bg_primary opacity-85">
          Great work!
        </h1>
        <p className="absolute bottom-[117px] body-large left-6 mr-6 text-light_bg_primary opacity-65 mt-1">
          You’ve successfully completed the resting heart journey.
        </p>
        <h1 className="ph-display-title-small text-light_bg_primary opacity-85 absolute left-6 bottom-2">
          What’s next
        </h1>
        <div className="flex-col flex gap-y-3 items-start shadow-profiler_success_card p-4 left-6 right-6 rounded-[16px] bg-[#ffffff4d] justify-center absolute">
          <div className="flex gap-x-3 items-start">
            <div className="bg-[#36BFFA] mt-1 rounded-full p-1">
              <svg
                className="w-3 h-3 text-white"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M5 13l4 4L19 7"></path>
              </svg>
            </div>
            <p className="body-large text-light_bg_primary opacity-85">
              Continue exercising regularly, sleeping on time and keeping a
              healthy diet.
            </p>
          </div>
          <div className="flex gap-x-3 items-start">
            <div className="bg-[#36BFFA] mt-1 rounded-full p-1">
              <svg
                className="w-3 h-3 text-white"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M5 13l4 4L19 7"></path>
              </svg>
            </div>
            <p className="body-large text-light_bg_primary opacity-85">
              Reduce/stop alcohol consumption and smoking cigarette
            </p>
          </div>
          <div className="flex gap-x-3 items-start">
            <div className="bg-[#36BFFA] mt-1 rounded-full p-1">
              <svg
                className="w-3 h-3 text-white"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M5 13l4 4L19 7"></path>
              </svg>
            </div>
            <p className="body-large text-light_bg_primary opacity-85">
              Keep away from stressful activities
            </p>
          </div>
        </div>
      </div>
      <p className="body-small fixed bottom-[60px] left-0 right-0 text-center text-light_bg_secondary">
        Come back in a month to check your progress
      </p>
      <button
        onClick={handleNavigateHome}
        className="text-[16px] fixed bottom-0 left-6 right-6 font-[500] leading-[19.2px] tracking-[-0.08px] flex bg-light_bg_primary justify-center items-center py-3 border-[1px] border-solid border-light_bg_primary rounded-lg mb-3 text-[#F9F5FF] shadow-xs z-10"
      >
        Remind me to test in 30 days
      </button>
    </>
  );
};

export default ProfilerSuccess;
