import React from "react";
import xclose from "../../src/assets/x-close.svg";
import HandOpenHeart from "../../src/assets/HandOpenHeart.svg";

// Simulating a database fetch function for guidance text
const fetchRespirationRateText = (respirationRate) => {
  if (respirationRate < 12) {
    return "Your breathing rate is lower than normal. You should seek immediate medical attention to find out if you have any underlying conditions. You should also perform regular exercise, yoga, and pranayama to bring back your breathing rate to a normal range.";
  } else if (respirationRate > 21) {
    return "Your breathing rate is higher than normal. You should seek immediate medical attention to find out if you have any underlying conditions. You should also perform regular exercise, yoga, and pranayama to bring back your breathing rate to a normal range.";
  } else {
    return "Congrats! Your breathing rate is normal. However, you can improve further by maintaining a healthy lifestyle and including exercise as a part of your daily routine.";
  }
};

const RespirationRateModal = ({ isOpen, onClose, respirationRate }) => {
  const isRespirationRateNormal =
    respirationRate >= 12 && respirationRate <= 21;

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const guidanceText = fetchRespirationRateText(respirationRate);

  return (
    <>
      <div
        className={`fixed inset-0 z-50 flex items-end justify-center backdrop-brightness-50 bg-black/30 ${
          isOpen ? "visible" : "invisible"
        }`}
        onClick={handleBackdropClick}
      >
        <div
          className={`w-full max-w-md rounded-t-[16px] bg-base_white shadow-md transition-transform duration-500 max-h-[75%] overflow-y-auto ${
            isOpen ? "translate-y-0" : "translate-y-full"
          }`}
        >
          {/* Modal content */}
          <div className="p-5">
            <div className="flex justify-between items-center mb-[2px]">
              <h2 className="display-title text-light_bg_primary">
                Respiration rate
              </h2>
              <button
                onClick={onClose}
                className="p-[6.66px] flex items-center justify-center bg-gray_100 rounded-[133.333px]"
              >
                <img src={xclose} alt="Close" />
              </button>
            </div>
            <p className="text-light_bg_secondary text-md-regular mb-5">
              Number of breaths you take in a minute
            </p>
            <div className="bg-[#F9FAFB] rounded-[16px]">
              <div className="flex pt-3 px-4 mt-2 items-baseline gap-x-[6px]">
                <h3 className="ph-display-number text-light_bg_primary">
                  {respirationRate}
                </h3>
                <p className="text-sm-regular text-gray_400">Breaths/min</p>
              </div>
              <p className="text-light_bg_secondary text-sm-regular pb-3 px-4 mt-[4px]">
                Adults at rest have a regular respiratory rate of 12 to 21 bpm.{" "}
                <span
                  className={
                    isRespirationRateNormal
                      ? "text-success_500"
                      : "text-[#F79009]"
                  }
                >
                  {isRespirationRateNormal
                    ? "Yours lies in the typical range of resting adults."
                    : "Yours lies outside the typical range of resting adults."}
                </span>
              </p>
            </div>
            <div className="my-6">
              <div className="bg-[#0BA5EC] rounded-[16px] py-3 px-4 mt-2">
                <div className="flex items-center">
                  <img src={HandOpenHeart} alt="Heart" />
                  <h3 className="ml-2 text-[#F5FBFF] text-[20px] font-[500] font-inter leading-[24px] tracking-[-0.2px]">
                    Our advice
                  </h3>
                </div>
                <p className="text-[#F5FBFF] text-md-regular mt-2">
                  {guidanceText}
                </p>
              </div>
            </div>
            <div>
              <p className="text-light_bg_primary heading-large mb-2">
                Symptom information
              </p>
              <div className="bg-[#F9FAFB] p-4 rounded-[16px]">
                <ul className="list-disc text-light_bg_secondary pl-5 text-md-regular">
                  <li className="mb-2">
                    Hyperventilation, also known as deep breathing, can result
                    from anxiety, fear, and specific medical issues such as
                    heart and lung problems.
                  </li>
                  <li>
                    Breathlessness, chest pain, and bloating are also symptoms
                    of rapid breathing, as are light-headedness, dizziness,
                    confusion, and numbness or tingling.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RespirationRateModal;
