import React from "react";
import Blog1Image from "../../assets/Blog1Image.png";
import leftArrow from "../../assets/leftArrow.svg";
import Bookmarkbw from "../../assets/Bookmarkbw.svg";
import { Link } from "react-router-dom";
import Layout from "../../components/JourneyLayout";
import Blog1Measure from "../../assets/Blog1Measure.png";

//all blogs are hardcoded for now with hardcoded routes

const Blog1 = () => {
  return (
    <>
      <div>
        <img src={Blog1Image} alt="Blog1" />
        <div className="fixed top-4 left-6">
          <Link to="/home">
            <div className="p-[10px] shadow-back_button flex items-center justify-center h-[32px] w-[32px] bg-base_white rounded-full">
              <img src={leftArrow} alt="back" className="w-full h-full" />
            </div>
          </Link>
        </div>
        <div className="fixed top-4 right-6">
          <Link to="/home">
            <div className="p-[10px] shadow-back_button flex items-center justify-center h-[32px] w-[32px] bg-base_white rounded-full">
              <img src={Bookmarkbw} alt="back" className="w-full h-full" />
            </div>
          </Link>
        </div>
      </div>
      <Layout>
        <h1 className="ph-display-title leading-[39.6px] font-inter font-[400] text-light_bg_primary mt-6">
          Your heart rate: What does it say?
        </h1>
        <div className="flex items-start mt-3">
          <div className="px-1 max-w-5 rounded-full pt-[4.5px] pb-[3.5px] bg-[#1763D8]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                d="M9.76497 3.20474C10.0661 3.48915 10.0797 3.96383 9.79526 4.26497L5.54526 8.76497C5.40613 8.91228 5.21332 8.99703 5.01071 8.99993C4.8081 9.00282 4.61295 8.92361 4.46967 8.78033L2.21967 6.53033C1.92678 6.23744 1.92678 5.76257 2.21967 5.46967C2.51256 5.17678 2.98744 5.17678 3.28033 5.46967L4.98463 7.17397L8.70474 3.23503C8.98915 2.9339 9.46383 2.92033 9.76497 3.20474Z"
                fill="white"
              />
            </svg>
          </div>
          <p className="text-light_bg_primary opacity-65 body-small ml-[6px]">
            Reviewed by healthcare professionals
          </p>
        </div>
        <div className="flex mt-2">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
            >
              <path
                d="M10 2.5C14.4183 2.5 18 6.08172 18 10.5C18 14.9183 14.4183 18.5 10 18.5C5.58172 18.5 2 14.9183 2 10.5C2 6.08172 5.58172 2.5 10 2.5ZM10 3.5C6.13401 3.5 3 6.63401 3 10.5C3 14.366 6.13401 17.5 10 17.5C13.866 17.5 17 14.366 17 10.5C17 6.63401 13.866 3.5 10 3.5ZM9.5 5.5C9.74546 5.5 9.94961 5.67688 9.99194 5.91012L10 6V10.5H12.5C12.7761 10.5 13 10.7239 13 11C13 11.2455 12.8231 11.4496 12.5899 11.4919L12.5 11.5H9.5C9.25454 11.5 9.05039 11.3231 9.00806 11.0899L9 11V6C9 5.72386 9.22386 5.5 9.5 5.5Z"
                fill="#6C737F"
              />
            </svg>
          </div>
          <p className="text-light_bg_primary opacity-65 body-small ml-[6px]">
            10 min read
          </p>
        </div>
        <h1 className="heading-large text-light_bg_primary mt-10 mb-2">
          What Is a Heart Rate?
        </h1>
        <p className="body-large text-light_bg_secondary">
          Your heart rate is the number of times your heart beats in 1 minute.
          Heart rates vary from person to person. It’s lower when you’re at rest
          and higher when you exercise.
        </p>
        <p className="body-large text-light_bg_secondary mt-6">
          Knowing how to find your pulse can help you figure out your best
          exercise program. If you’re taking heart medications, recording your
          pulse daily and reporting the results to your doctor can help them
          learn if your treatment is working.
        </p>
        <h1 className="mt-6 heading-large text-light_bg_primary">
          Heart rate vs. pulse rate
        </h1>
        <p className="body-large text-light_bg_secondary mt-2">
          While the two go hand in hand, they are different. Your heart rate is
          a measure of the number of times your heart beats in a minute. With
          each beat, your heart squeezes. That creates enough pressure to push
          blood out of your heart to the rest of your body through a network of
          arteries. And that brief rise in pressure creates your pulse, which
          allows you to feel and count your heart rate. Pulse is a way to
          measure your heart rate.
        </p>
        <p className="body-large text-light_bg_secondary mt-6">
          You can feel your pulse on certain parts of your body where there’s an
          artery close to your skin. Two easy spots are:
        </p>
        <ul className="list-disc mt-3 ml-6">
          <li className="body-large text-light_bg_secondary">
            Your radial artery, located near where your wrist bone meets your
            thumb muscle
          </li>
          <li className="body-large text-light_bg_secondary">
            Your carotid artery, located on the side of your neck just below
            your jawbone (Don’t check your pulse here if you have carotid artery
            disease, in which plaque builds up on the walls of the carotid
            artery.)
          </li>
        </ul>
        <h1 className="heading-large text-light_bg_primary mt-6">
          How to Check Your Heart Rate
        </h1>
        <p className="body-large text-light_bg_secondary mt-2">
          There are a few places on your body where it’s easier to take your
          pulse:
        </p>
        <ul className="list-disc mt-3 ml-6">
          <li className="body-large text-light_bg_secondary">
            The insides of your wrists
          </li>
          <li className="body-large text-light_bg_secondary">
            The insides of your elbows
          </li>
          <li className="body-large text-light_bg_secondary">
            The sides of your neck
          </li>
          <li className="body-large text-light_bg_secondary">
            The tops of your feet
          </li>
        </ul>
        <img src={Blog1Measure} alt="Blog1Measure" className="mt-2 mb-2" />
        <p className="body-large text-light_bg_secondary">
          Put the tips of your index and middle fingers on your skin. Press
          lightly until you feel the blood pulsing beneath your fingers. You may
          need to move your fingers around until you feel it. Count the beats
          you feel for 10 seconds. Multiply this number by six to get your
          heartbeats per minute.
        </p>
        <h1 className="mt-6 heading-large text-light_bg_primary">
          What Affects Your Heart Rate?
        </h1>
        <p className="body-large mt-2 text-light_bg_secondary">
          Other than exercise, things that can affect your heart rate include:
        </p>
        <ul className="list-disc mt-2 ml-6">
          <li className="text-light_bg_secondary body-large mb-2">
            <span className="text-light_bg_primary">Weather.</span> Your pulse
            may go up a bit in higher temperatures and humidity.
          </li>
          <li className="text-light_bg_secondary body-large mb-2">
            <span className="text-light_bg_primary">Standing up. </span>It might
            spike for about 20 seconds when you stand up after sitting for a
            while.
          </li>
          <li className="text-light_bg_secondary body-large mb-2">
            <span className="text-light_bg_primary">Emotions. </span>It might
            Stress and anxiety can raise your heart rate. It may also go up when
            you’re very happy or sad.
          </li>
          <li className="text-light_bg_secondary body-large mb-2">
            <span className="text-light_bg_primary">Body size. </span>
            People with obesity can have a slightly faster pulse.
          </li>
          <li className="text-light_bg_secondary body-large mb-2">
            <span className="text-light_bg_primary">Medications. </span>
            Beta-blockers slow your heart rate. Too much thyroid medicine can
            speed it up.
          </li>
          <li className="text-light_bg_secondary body-large mb-2">
            <span className="text-light_bg_primary">
              Caffeine and nicotine.{" "}
            </span>
            Coffee, tea, and soda raise your heart rate. So does tobacco.
          </li>
          <li className="text-light_bg_secondary body-large mb-2">
            <span className="text-light_bg_primary">Pain. </span>Sudden or
            chronic pain may trigger a stress hormone response that makes your
            heart beat faster.
          </li>
          <li className="text-light_bg_secondary body-large mb-2">
            <span className="text-light_bg_primary">Hormones. </span>There may
            be a link between the postmenopausal decline of estrogen and your
            heart rate.
          </li>
          <li className="text-light_bg_secondary body-large mb-2">
            <span className="text-light_bg_primary">Aging. </span>As you age,
            your heart rate won't rise as much during exercise or stress.
          </li>
        </ul>
      </Layout>
    </>
  );
};

export default Blog1;
