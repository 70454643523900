import React, { useEffect, useState } from "react";
import Smilybw from "../assets/Smilybw.svg";
import Mood1 from "../assets/Emojis/Mood1.png";
import Mood2 from "../assets/Emojis/Mood2.png";
import Mood3 from "../assets/Emojis/Mood3.png";
import Mood4 from "../assets/Emojis/Mood4.png";
import Mood5 from "../assets/Emojis/Mood5.png";
import { calculateAverageMood } from "../utils/MoodLocalStorage";

const emojis = [Mood1, Mood2, Mood3, Mood4, Mood5];
const moodDescriptions = [
  "Very unpleasant",
  "Unpleasant",
  "Neutral",
  "Pleasant",
  "Very pleasant",
];

const MoodCard = () => {
  const [averageMood, setAverageMood] = useState(0);

  useEffect(() => {
    const calculatedMood = calculateAverageMood();
    setAverageMood(calculatedMood);
  }, []);

  const moodIndex = Math.min(Math.max(Math.round(averageMood), 0), 4);

  return (
    <div className="bg-base_white p-3 rounded-[16px] shadow-result_card flex items-center mt-3 border-[1px] border-light_bg_primary border-opacity-[0.12]">
      <div>
        <div className="flex gap-x-1 items-center">
          <img src={Smilybw} alt="smily" />
          <p className="text-sm-medium text-light_bg_primary text-opacity-95">
            Mood
          </p>
        </div>
        <h3 className="font-inter text-[32px] font-[300] leading-[38.4px] mt-1 text-light_bg_primary">
          {moodDescriptions[moodIndex]}
        </h3>
        <p className="body-small text-light_bg_secondary mt-6 mr-3">
          Your overall mood throughout the week
        </p>
      </div>
      <div>
        <img src={emojis[moodIndex]} alt="Mood"></img>
      </div>
    </div>
  );
};

export default MoodCard;
