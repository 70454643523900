import React from "react";
import { Link } from "react-router-dom";

const ProgressDial = ({ scans }) => {
  const maxScans = 5;
  const validScans = scans > 0 ? scans : 1; // Ensure scans is not negative
  const percentage = (validScans / maxScans) * 100;
  const radius = 40;
  const circumference = 2 * Math.PI * radius;
  const fillPercentage = percentage / 100;
  const dashOffset = circumference * (1 - fillPercentage);

  return (
    <div className="bg-primary_100 p-4 rounded-xl flex items-center space-x-4">
      <div className="relative w-16 h-16">
        <svg
          className="w-full h-full transform -rotate-90"
          viewBox="0 0 120 120"
        >
          <circle
            cx="60"
            cy="60"
            r={radius}
            stroke="#1F2A3706"
            strokeWidth="8"
            fill="none"
          />
          <circle
            cx="60"
            cy="60"
            r={radius}
            stroke="#F63D68"
            strokeWidth="8"
            fill="none"
            strokeDasharray={circumference}
            strokeDashoffset={dashOffset}
            strokeLinecap="round"
          />
        </svg>
        <div className="absolute inset-0 flex items-center justify-center">
          <span className="text-[14px] font-[600] font-inter leading-[21px] text-light_bg_primary opacity-95">
            {Math.round(percentage)}%
          </span>
        </div>
      </div>
      <div className="flex-col">
        <div className="text-[14px] font-[600] leading-[21px] font-inter text-light_bg_primary opacity-85">
          Your progress
        </div>
        <div className="text-[14px] font-[400] leading-[20px] font-inter text-light_bg_secondary">
          {validScans} Scan{validScans !== 1 ? "s" : ""} completed
        </div>
      </div>
      <div className="shadow-report_button bg-base_white opacity-60 py-2 px-3 rounded-[8px]">
        <Link to="/results">
          <button className=" text-[14px] font-inter font-[500] leading-[21px] text-light_bg_primary opacity-85">
            View report
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ProgressDial;
