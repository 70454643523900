import React from "react";
import xclose from "../../assets/x-close.svg";
import DownArrowRed from "../../assets/DownArrowRed.svg";
import TickGreen from "../../assets/TickGreen.svg";
import ListRedDisc from "../../assets/ListRedDisc.png";

const CrrRateDetail = ({ isOpen, onClose, heartRate }) => {
  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      <div
        className={`fixed inset-0 z-50 flex items-end justify-center backdrop-brightness-50 bg-black/30 ${
          isOpen ? "visible" : "invisible"
        }`}
        onClick={handleBackdropClick}
      >
        <div
          className={`w-full max-w-md rounded-t-[16px] bg-base_white shadow-md transition-transform duration-500 max-h-[75%] overflow-y-auto ${
            isOpen ? "translate-y-0" : "translate-y-full"
          }`}
        >
          {/* Modal content */}
          <div className="p-5">
            <div className="flex justify-between items-center mb-[2px]">
              <h2 className="text-[28px] font-inter font-normal leading-[33.6px] tracking-[-0.28px] text-light_bg_primary">
                Heart Age
              </h2>
              <button
                onClick={onClose}
                className="p-[6.66px] flex items-center justify-center bg-gray_100 rounded-[133.333px]"
              >
                <img src={xclose} alt="Close" />
              </button>
            </div>
            <p className="text-light_bg_secondary body-large font-inter mt-5">
              A measurement of the heart’s efficiency in pumping blood
              considering the blood pressure relative to body size.
            </p>

            <div className="flex-col flex gap-y-2">
              <h1 className="heading-large text-light_bg_primary mt-10 mb-2">
                What does it indicate
              </h1>
              <div className="p-4 bg-[#FEF3F2] rounded-[16px]">
                <div className="flex">
                  <img src={DownArrowRed} alt="Down Arrow" />
                  <h1 className="heading-small ml-1 text-[#F04438]">Low CPI</h1>
                </div>
                <div className="flex items-baseline mt-4">
                  <img
                    src={ListRedDisc}
                    alt="List Icon"
                    className="w-3 h-3 mr-2"
                  />
                  <p className="body-large text-light_bg_primary opacity-85">
                    Poor cardiac output
                  </p>
                </div>
                <div className="flex items-baseline mt-2">
                  <img
                    src={ListRedDisc}
                    alt="List Icon"
                    className="w-3 h-3 mr-2"
                  />
                  <p className="body-large text-light_bg_primary opacity-85">
                    Insufficient blood supply to the body
                  </p>
                </div>
              </div>
              <div className="p-4 bg-[#ECFDF3] rounded-[16px]">
                <div className="flex">
                  <img src={TickGreen} alt="Tick Green" />
                  <h1 className="heading-small ml-1 text-success_500">
                    Normal CPI
                  </h1>
                </div>
                <p className="body-large mt-2 text-light_bg_primary opacity-85">
                  Well-functioning heart and lungs
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CrrRateDetail;
