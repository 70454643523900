import React from "react";
import xclose from "../../assets/x-close.svg";
import DownArrowRed from "../../assets/DownArrowRed.svg";
import TickGreen from "../../assets/TickGreen.svg";

const HeartRateDetail = ({ isOpen, onClose, heartRate }) => {
  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      <div
        className={`fixed inset-0 z-50 flex items-end justify-center backdrop-brightness-50 bg-black/30 ${
          isOpen ? "visible" : "invisible"
        }`}
        onClick={handleBackdropClick}
      >
        <div
          className={`w-full max-w-md rounded-t-[16px] bg-base_white shadow-md transition-transform duration-500 max-h-[75%] overflow-y-auto ${
            isOpen ? "translate-y-0" : "translate-y-full"
          }`}
        >
          {/* Modal content */}
          <div className="p-5">
            <div className="flex justify-between items-center mb-[2px]">
              <h2 className="text-[28px] font-inter font-normal leading-[33.6px] tracking-[-0.28px] text-light_bg_primary">
                Target heart rate
              </h2>
              <button
                onClick={onClose}
                className="p-[6.66px] flex items-center justify-center bg-gray_100 rounded-[133.333px]"
              >
                <img src={xclose} alt="Close" />
              </button>
            </div>
            <p className="text-light_bg_secondary text-md-regular font-inter mt-5">
              It is a measure of your maximum heart rate within a safe range
              (50%-80%)
            </p>
            <h1 className="heading-large text-light_bg_primary mt-10 mb-2">
              What does it tell?
            </h1>
            <div className="bg-[#F9FAFB] rounded-[16px]">
              <p className="text-light_bg_secondary text-[16px] font-inter p-4 font-normal leading-[22.4px] pb-3 px-4 mt-[4px]">
                How fast your heart should be beating when you exercise.
              </p>
            </div>

            <div className="flex-col flex gap-y-2">
              <h1 className="heading-large text-light_bg_primary mt-10 mb-2">
                What does it indicate
              </h1>
              <div className="p-4 bg-[#FEF3F2] rounded-[16px]">
                <div className="flex">
                  <img src={DownArrowRed} alt="Down Arrow" />
                  <h1 className="heading-small ml-1 text-[#F04438]">Low THR</h1>
                </div>
                <p className="text-md-regular mt-2 text-light_bg_primary opacity-85">
                  Increase your exercise gradually, especially if you’re trying
                  to lose weight.
                </p>
              </div>
              <div className="p-4 bg-[#ECFDF3] rounded-[16px]">
                <div className="flex">
                  <img src={TickGreen} alt="Tick Green" />
                  <h1 className="heading-small ml-1 text-success_500">
                    Low THR
                  </h1>
                </div>
                <p className="text-md-regular mt-2 text-light_bg_primary opacity-85">
                  Optimum exercise intensity.
                </p>
              </div>
              <div className="p-4 bg-[#FEF3F2] rounded-[16px]">
                <div className="flex">
                  <img
                    src={DownArrowRed}
                    alt="Up Arrow"
                    className="rotate-180"
                  />
                  <h1 className="heading-small ml-1 text-[#F04438]">Low THR</h1>
                </div>
                <p className="text-md-regular mt-2 text-light_bg_primary opacity-85">
                  Watch out and reduce your exercise intensity.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeartRateDetail;
