import React from "react";
import xclose from "../../src/assets/x-close.svg";
import HandOpenHeart from "../../src/assets/HandOpenHeart.svg";

// Simulating a database fetch function for guidance text
const fetchOxygenSaturationText = (oxygenSaturation) => {
  if (oxygenSaturation < 95) {
    return oxygenSaturation < 90
      ? "Your SpO2 is very low. You may have chronic lung disease or sleep apnea. You need to seek medical attention to control your SpO2 from going further low. Also, you should include regular exercise, intake of more fresh air and quit smoking to improve your SpO2 levels."
      : "Your SpO2 is lower than normal. You have a higher risk of developing chronic lung disease or sleep apnea. So, exercise regularly, intake more fresh air and quit smoking to improve your SpO2 levels now!";
  } else {
    return "Congrats! Your SpO2 is normal. However, we encourage you to exercise regularly, intake more fresh air, and quit smoking to maintain a healthy SpO2.";
  }
};

const OxygenSaturationModal = ({ isOpen, onClose, oxygenSaturation }) => {
  const isOxygenSaturationNormal =
    oxygenSaturation >= 95 && oxygenSaturation <= 100;

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const guidanceText = fetchOxygenSaturationText(oxygenSaturation);

  return (
    <>
      <div
        className={`fixed inset-0 z-50 flex items-end justify-center backdrop-brightness-50 bg-black/30 ${
          isOpen ? "visible" : "invisible"
        }`}
        onClick={handleBackdropClick}
      >
        <div
          className={`w-full max-w-md rounded-t-[16px] bg-base_white shadow-md transition-transform duration-500 max-h-[75%] overflow-y-auto ${
            isOpen ? "translate-y-0" : "translate-y-full"
          }`}
        >
          {/* Modal content */}
          <div className="p-5">
            <div className="flex justify-between items-center mb-[2px]">
              <h2 className="display-title text-light_bg_primary">
                Oxygen saturation
              </h2>
              <button
                onClick={onClose}
                className="p-[6.66px] flex items-center justify-center bg-gray_100 rounded-[133.333px]"
              >
                <img src={xclose} alt="Close" />
              </button>
            </div>
            <p className="text-light_bg_secondary text-md-regular mb-5">
              Percentage of oxygen in your blood
            </p>
            <div className="bg-[#F9FAFB] rounded-[16px]">
              <div className="flex pt-3 px-4 mt-2 items-baseline gap-x-[6px]">
                <h3 className="ph-display-number text-light_bg_primary">
                  {oxygenSaturation}%
                </h3>
              </div>
              <p className="text-light_bg_secondary text-sm-regular pb-3 px-4 mt-[4px]">
                A healthy oxygen saturation level, according to a pulse
                oximeter, ranges between 95% and 100%.{" "}
                <span
                  className={
                    isOxygenSaturationNormal
                      ? "text-success_500"
                      : "text-[#F79009]"
                  }
                >
                  {isOxygenSaturationNormal
                    ? "Yours lie in the healthy range."
                    : "Yours lie outside the healthy range."}
                </span>
              </p>
            </div>
            <div className="my-6">
              <div className="bg-[#0BA5EC] rounded-[16px] py-3 px-4 mt-2">
                <div className="flex items-center">
                  <img src={HandOpenHeart} alt="Heart" />
                  <h3 className="ml-2 text-[#F5FBFF] text-[20px] font-[500] font-inter leading-[24px] tracking-[-0.2px]">
                    Our advice
                  </h3>
                </div>
                <p className="text-[#F5FBFF] text-md-regular mt-2">
                  {guidanceText}
                </p>
              </div>
            </div>
            <div>
              <p className="text-light_bg_primary heading-large mb-2">
                Symptom information
              </p>
              <div className="bg-[#F9FAFB] p-4 rounded-[16px]">
                <ul className="list-disc text-light_bg_secondary pl-5 text-md-regular">
                  <li className="mb-2">
                    If a person's levels drop below 95%, they may experience
                    symptoms of low oxygen, including:
                  </li>
                  <li>Trouble breathing</li>
                  <li>Confusion</li>
                  <li>Blue tint to lips or face</li>
                  <li>Difficulty waking up</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OxygenSaturationModal;
