import React from "react";
import xclose from "../../src/assets/x-close.svg";

const LearnMoreModal = ({ isOpen, onClose }) => {
  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      <div
        className={`fixed inset-0 z-50 flex items-end justify-center backdrop-brightness-50 bg-black/30 ${
          isOpen ? "visible" : "invisible"
        }`}
        onClick={handleBackdropClick}
      >
        <div
          className={`w-full max-w-md rounded-t-[16px] bg-base_white shadow-md transition-transform duration-500 max-h-[75%] overflow-y-auto ${
            isOpen ? "translate-y-0" : "translate-y-full"
          }`}
        >
          {/* Modal content */}
          <div className="p-5">
            <div className="flex justify-between items-center mb-[20px]">
              <h2 className="text-[28px] font-inter font-normal leading-[33.6px] tracking-[-0.28px] text-light_bg_primary">
                What are vitals?
              </h2>
              <button
                onClick={onClose}
                className="p-[6.66px] flex items-center justify-center bg-gray_100 rounded-[133.333px]"
              >
                <img src={xclose} alt="Close" />
              </button>
            </div>
            <div className="flex-col">
              <div>
                <h3 className="heading-large text-light_bg_primary">
                  Heart rate
                </h3>

                <p className="text-md-regular text-light_bg_secondary mt-2">
                  Your heart rate is the number of times your heart beats in one
                  minute.
                </p>
                <p className="text-md-regular text-light_bg_secondary mt-4 mb-6">
                  The normal resting heart rate for adults is between 50 and 84
                  beats per minute.
                </p>
              </div>
              <div>
                <h3 className="heading-large text-light_bg_primary">
                  Respiratory rate
                </h3>

                <p className="text-md-regular text-light_bg_secondary mt-2">
                  It is the number of breaths you normally take in 1 minute.
                </p>
                <p className="text-md-regular text-light_bg_secondary mt-4 mb-6">
                  In adults, the usual breathing rate is 12-21 breaths per
                  minute when they are resting.
                </p>
              </div>
              <div>
                <h3 className="heading-large text-light_bg_primary">
                  Oxygen Saturation
                </h3>

                <p className="text-md-regular text-light_bg_secondary mt-2">
                  Oxygen Saturation (SpO2) is a measure of the amount of oxygen
                  present in your blood, which is expressed in terms of
                  percentage.
                </p>
                <p className="text-md-regular text-light_bg_secondary mt-4 mb-6">
                  A normal SpO2 level is typically between 96% and 99%.
                </p>
              </div>
              <div>
                <p className="heading-small">Note</p>
                <p className="text-sm-regular text-light_bg_secondary mt-1">
                  These readings might vary based on factors such as gender, age
                  and athleticism.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LearnMoreModal;
