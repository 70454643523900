import React from "react";
import xclose from "../../src/assets/x-close.svg";
import HandOpenHeart from "../../src/assets/HandOpenHeart.svg";

const HeartRateVariabilityModal = ({
  isOpen,
  onClose,
  heartRateVariability,
}) => {
  const isHRVNormal = heartRateVariability >= 20 && heartRateVariability <= 200;

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      <div
        className={`fixed inset-0 z-50 flex items-end justify-center backdrop-brightness-50 bg-black/30 ${
          isOpen ? "visible" : "invisible"
        }`}
        onClick={handleBackdropClick}
      >
        <div
          className={`w-full max-w-md rounded-t-[16px] bg-base_white shadow-md transition-transform duration-500 max-h-[75%] overflow-y-auto ${
            isOpen ? "translate-y-0" : "translate-y-full"
          }`}
        >
          {/* Modal content */}
          <div className="p-5">
            <div className="flex justify-between items-center mb-[2px]">
              <h2 className="text-[28px] font-inter font-normal leading-[33.6px] tracking-[-0.28px] text-light_bg_primary">
                Heart rate variability
              </h2>
              <button
                onClick={onClose}
                className="p-[6.66px] flex items-center justify-center bg-gray_100 rounded-[133.333px]"
              >
                <img src={xclose} alt="Close" />
              </button>
            </div>
            <p className="text-light_bg_secondary body-large mb-5">
              Interval between a heartbeat & the following
            </p>
            <div className="bg-[#F9FAFB] rounded-[16px]">
              <div className="flex pt-3 px-4 mt-2 items-baseline gap-x-[6px]">
                <h3 className="ph-display-number text-light_bg_primary">
                  {heartRateVariability} ms
                </h3>
                <p className="body-large text-gray_400">millisecond</p>
              </div>
              <p className="text-light_bg_secondary font-inter font-normal leading-[22.4px] pb-3 px-4 mt-[4px]">
                In healthy adults, average HRV is 42 milliseconds. The range is
                between 19 and 75 milliseconds. Athletes and other people who
                are very fit may have a much higher HRV.{" "}
                <span
                  className={
                    isHRVNormal ? "text-success_500" : "text-[#F79009]"
                  }
                >
                  {isHRVNormal
                    ? "Yours lie in the healthy range for adults."
                    : "Your HRV is outside the healthy range for adults."}
                </span>
              </p>
            </div>
            <div className="my-10">
              <div className="bg-[#0BA5EC] rounded-[16px] py-3 px-4 mt-2">
                <div className="flex items-center">
                  <img src={HandOpenHeart} alt="Heart" />
                  <h3 className="ml-2 text-[#F5FBFF] text-[20px] font-[500] font-inter leading-[24px] tracking-[-0.2px]">
                    Our advice
                  </h3>
                </div>
                <p className="text-[#F5FBFF] text-[16px] font-inter font-normal leading-[24px] mt-2">
                  Workout with moderate intensity for 150 minutes/week. While
                  you do so, your activity heart rate between{" "}
                  <span>116-138 beats per minute</span> would be a safe zone.
                </p>
              </div>
            </div>
            <div className="px-2">
              <p className="text-light_bg_primary heading-large mb-2">
                Symptom information
              </p>
              <div className="bg-[#F9FAFB] p-4 rounded-[16px]">
                <ul className="list-disc text-light_bg_secondary pl-5 font-inter text-[16px] leading-[24px]">
                  <li className="mb-2">
                    Low heart rate variability may be a sign of current or
                    future health problems because it shows that your body isn't
                    adapting to changes well. You may have a low heart rate
                    variability if you have a high resting heart rate.
                  </li>
                  <li>
                    People with a low heart rate variability often have medical
                    conditions, such as diabetes, high blood pressure, heart
                    arrhythmia, asthma, anxiety, and depression.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeartRateVariabilityModal;
