import React, { useState, useEffect } from "react";
import { sendAmplitudeData } from "../utils/amplitude"; // Import Amplitude utility

const HealthFacts = ({ facts, day = 0 }) => {
  const [displayFacts, setDisplayFacts] = useState([]);
  const [hasScrolled, setHasScrolled] = useState(false); // To track if the user scrolled

  useEffect(() => {
    const shuffledFacts = [...facts].sort(() => Math.random() - 0.5);
    const factsPerDay = 10;
    const start = day * factsPerDay;
    const end = start + factsPerDay;
    setDisplayFacts(shuffledFacts.slice(start, end));
  }, [facts, day]);

  // Handler for the scroll event
  const handleScroll = () => {
    if (!hasScrolled) {
      setHasScrolled(true); // Set that the user has scrolled
      sendAmplitudeData("SCROLLED_HEALTH_FACTS"); // Log the scroll event to Amplitude
    }
  };

  return (
    <div
      className="overflow-x-auto scroll-smooth snap-x snap-mandatory"
      onScroll={handleScroll} // Attach the scroll event handler
    >
      <div className="inline-flex space-x-2">
        {displayFacts.map((fact, index) => (
          <div
            key={index}
            className="bg-[#111927] rounded-[16px] p-4 max-h-[156px] w-[345px] max-w-[345px] snap-center"
          >
            <h3 className="text-xs-medium text-dark_bg_primary opacity-65">
              Did you know?
            </h3>
            <h3 className=" text-dark_bg_primary text-md-regular mt-2">
              {fact.fact}
            </h3>
            <p className=" text-dark_bg_primary text-md-regular body-large ">
              {fact.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HealthFacts;
