import React, { useState } from 'react';
import lungsIconbw from '../../src/assets/lungsIconbw.svg';
import RespirationRateModal from './RespirationRateModal';

const RespirationRate = ({ respirationRate }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const mockRespirationRate = 15;
  const minRate = 5;
  const maxRate = 28;
  const normalMin = 12;
  const normalMax = 21;
  const rate = respirationRate || mockRespirationRate;

  const calculatePosition = (rate) => {
    const clampedRate = Math.max(minRate, Math.min(maxRate, rate));
    return ((clampedRate - minRate) / (maxRate - minRate)) * 100;
  };

  const getStatusAndColor = (rate) => {
    if (rate >= normalMin && rate <= normalMax) {
      return { status: 'All Good', color: '#32D583' };
    } else if (rate < normalMin - 2 || rate > normalMax + 3) {
      return { status: 'Heads up', color: '#FDB022' };
    } else {
      return { status: 'Borderline', color: '#FDB022' };
    }
  };

  const position = calculatePosition(rate);
  const { color } = getStatusAndColor(rate);

  return (
    <>
      <div
        className="flex p-3 mt-3 flex-col items-start max-w-[175px] w-full h-[175px] max-h-[175px] bg-base_white rounded-[16px] shadow-result_card border-[1px] border-light_bg_primary border-opacity-[0.12]"
        onClick={handleOpenModal}
      >
        <div className="flex items-center gap-[6px]">
          <img
            src={lungsIconbw}
            alt="Lungs Icon"
            className="w-[20px] h-[20px]"
          />
          <p className="text-sm-medium text-light_bg_primary text-opacity-95 text-nowrap">
            Respiration rate
          </p>
        </div>
        <div className="flex items-baseline gap-[6px] mt-1">
          <h3 className="display-number-medium text-light_bg_primary">
            {rate}
          </h3>
          <p className="text-[14px] font-normal leading-[21px] font-inter text-light_bg_secondary">
            Breaths/min
          </p>
        </div>

        {/* Graph component */}
        <div className="relative mt-14 h-[8px] w-full">
          <div
            className="h-[6px] w-full rounded-full"
            style={{
              background:
                'linear-gradient(90deg, rgba(249, 112, 102, 0.80) 10.07%, rgba(253, 176, 34, 0.80) 24.25%, rgba(50, 213, 131, 0.80) 38.44%, rgba(50, 213, 131, 0.80) 59.03%, rgba(253, 176, 34, 0.80) 71.84%, rgba(249, 112, 102, 0.80) 88.77%)',
            }}
          ></div>

          <div
            className="absolute top-[-8px] z-20 border-white rounded-full flex items-center justify-center"
            style={{
              left: `${position}%`,
              transform: 'translateX(-50%)',
              backgroundColor: 'white',
            }}
          >
            <div
              className="h-[22px] w-[22px] border-[3px] border-white rounded-full flex items-center justify-center"
              style={{ backgroundColor: color }}
            >
              {color === '#32D583' ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.2294 0.265979C11.5902 0.620617 11.5902 1.1956 11.2294 1.55024L4.93737 7.73402C4.76408 7.90433 4.52906 8 4.284 8C4.03894 8 3.80392 7.90433 3.63063 7.73402L0.770633 4.92321C0.409789 4.56857 0.409789 3.99359 0.770633 3.63895C1.13148 3.28431 1.71652 3.28431 2.07737 3.63895L4.284 5.80763L9.92263 0.265979C10.2835 -0.0886595 10.8685 -0.0886596 11.2294 0.265979Z"
                    fill="#1F2A37"
                    fillOpacity="0.95"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2"
                  height="10"
                  viewBox="0 0 2 10"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.87551 0.896901C1.90592 0.374093 1.44909 0 1.00073 0C0.552368 0 0.0955341 0.374093 0.125938 0.896901L0.34434 6.79375C0.347002 6.96414 0.415844 7.12699 0.536535 7.24768C0.659647 7.37079 0.826622 7.43995 1.00073 7.43995C1.17483 7.43995 1.34181 7.37079 1.46492 7.24768C1.58561 7.12699 1.65445 6.96414 1.65711 6.79375L1.87551 0.896901ZM0.99214 0.881763C0.992141 0.881765 0.99218 0.88172 0.992252 0.881626C0.992174 0.881715 0.992139 0.881762 0.99214 0.881763ZM1.00931 0.881763C1.00931 0.881762 1.00928 0.881715 1.0092 0.881626C1.00927 0.88172 1.00931 0.881765 1.00931 0.881763Z"
                    fill="#1F2A37"
                    fill-opacity="0.95"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.87695 9.12529C1.87695 8.64188 1.48507 8.25 1.00166 8.25C0.518256 8.25 0.126376 8.64188 0.126376 9.12529C0.126376 9.6087 0.518256 10.0006 1.00166 10.0006C1.48507 10.0006 1.87695 9.6087 1.87695 9.12529Z"
                    fill="#1F2A37"
                    fill-opacity="0.95"
                  />
                </svg>
              )}
            </div>
          </div>
          {/* Lines and labels for 12 and 21 BPM */}
          {[
            {
              value: normalMin,
              position: calculatePosition(normalMin),
            },
            {
              value: normalMax,
              position: calculatePosition(normalMax),
            },
          ].map(({ value, position }) => (
            <React.Fragment key={value}>
              <div
                className="absolute text-xs-medium text-light_bg_secondary bg-white px-[2px] top-[0px]"
                style={{ left: `${position}%` }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2"
                  height="12"
                  viewBox="0 0 2 12"
                  fill="none"
                >
                  <path
                    d="M1 1L1 11"
                    stroke="#1F2A37"
                    strokeOpacity="0.64"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div
                className="absolute ml-[3px] top-[12px] text-xs-medium text-light_bg_secondary transform -translate-x-1/2"
                style={{ left: `${position}%` }}
              >
                {value}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
      <RespirationRateModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        respirationRate={rate}
      />
    </>
  );
};

export default RespirationRate;
