import React from "react";

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen items-center justify-between">
      <div className="w-full max-w-[393px] mx-auto">
        <div className="w-full px-6 flex-grow">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
