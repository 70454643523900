import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CrossIcon from '../../assets/crossIcon.svg';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout';
import HandOpenHeart from '../../assets/HandOpenHeart.svg';
import DocumentText from '../../assets/DocumentText.svg';
import HeartRateDetail from '../../components/Modals/HeartRateDetail';
import CrrRateDetail from '../../components/Modals/CrrRateDetail';
import HeartAgeDetail from '../../components/Modals/HeartAgeDetail';
import DiabetesRiskDetail from '../../components/Modals/DiabetesRiskDetail';
import SleepApneaRiskDetail from '../../components/Modals/SleepApneaRiskDetail';
import Loader from '../../utils/Loader';
import CtaButton from '../../components/CtaButton';

// Fetching appropriate advice based on the metric value
const fetchAdvice = (metric, value) => {
  switch (metric) {
    case 'Resting heart rate':
      if (value < 56) {
        return 'Your heart rate is lower than normal. You should seek immediate medical attention if you experience fainting, dizziness, or shortness of breath.';
      } else if (value > 80) {
        return 'Your heart rate is higher than normal. You should seek immediate medical attention if you experience fainting, dizziness, or shortness of breath.';
      } else {
        return 'Congrats! Your heart rate is normal. You have a healthy heart! Keep it up and stay fit and healthy by exercising regularly.';
      }
    case 'Cardiorespiratory rhythm':
      if (value < 3) {
        return 'Your PRQ or pulse-to-breathing (CRR) rate ratio is very low. You should seek immediate medical attention and focus on your breathing, exercise daily, and manage stress levels.';
      } else if (value > 6) {
        return 'Your PRQ or pulse-to-breathing (CRR) rate ratio is very high. You should seek immediate medical attention and focus on your breathing, exercise daily, and manage stress levels.';
      } else {
        return 'Congrats! Your PRQ or pulse-to-breathing (CRR) rate ratio is normal. Keep maintaining a healthy lifestyle.';
      }
    case 'Diabetes risk score':
      if (value > 66) {
        return 'Your diabetes risk score is higher than normal. You should check your blood glucose regularly and control the level by living a healthy lifestyle.';
      } else if (value > 33) {
        return 'You are at a moderate risk of developing diabetes. Check your blood glucose regularly and live a healthy lifestyle.';
      } else {
        return 'Congrats! You are at a low risk of developing diabetes. Continue to lead a healthy lifestyle.';
      }
    case 'Sleep apnea risk':
      if (value > 5) {
        return 'Your OSA score is higher than normal. You are at a higher risk of obstructive sleep apnea. It is recommended to get checked for OSA and improve your lifestyle.';
      } else {
        return 'Congrats! Your risk of obstructive sleep apnea is low. Keep leading a healthy lifestyle.';
      }
    default:
      return 'Workout with moderate intensity for 150 minutes/week.';
  }
};

const ProfilerResult = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [day, setDay] = useState(0);
  const [showSplash, setShowSplash] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const currentDay = Number(localStorage.getItem('Day'));
    setDay(currentDay);

    if (currentDay === 2 || currentDay <= 2) {
      setShowSplash(true);
    }

    const fetchData = async () => {
      const userId = localStorage.getItem('userId');

      try {
        if (currentDay === 2 || currentDay === 3) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_BASE_URL}/api/scans/${userId}`
          );
          const scanData = response.data;

          const latestScan = scanData[scanData.length - 1];
          const transformedData = {
            HR: latestScan?.vitals?.heart_rate || '',
            prq: latestScan?.metadata?.cardiovascular?.prq || '',
            HeartAge:
              latestScan?.metadata?.cardiovascular?.heart_age || '',
            DiabetesRiskScore:
              latestScan?.metadata?.diabetes?.risk_score || '',
            SleepApneaRiskScore:
              latestScan?.metadata?.sleep_apnea?.risk_score || '',
          };
          setData(transformedData);
          setDataLoaded(true);
        } else {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_BASE_URL}/api/journeys/${userId}`
          );
          setData(response.data);
          setDataLoaded(true);
        }
      } catch (error) {
        console.error('Error fetching data', error);
        setError('Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    if (currentDay === 2) {
      // For day 2, show splash screen for 5 seconds, then fetch data
      setTimeout(() => {
        setShowSplash(false);
        fetchData();
      }, 5000);
    } else if (currentDay > 2) {
      // For days > 2, show loader for 3 seconds, then fetch data
      setTimeout(fetchData, 3000);
    } else {
      // For day 1 or any other case, fetch data immediately
      fetchData();
    }
  }, []);

  const handleCrossClick = () => {
    const day = parseInt(localStorage.getItem('Day')) || 1;
    if (day === 2) {
      navigate('/journey-success');
    } else {
      navigate('/home');
    }
  };

  if (error) {
    return <div>Error fetching data...</div>;
  }

  if (showSplash) {
    return (
      <div className="w-screen h-screen flex justify-center items-center bg-base_white transition-opacity duration-500 ease-in-out">
        <div className="flex flex-col justify-center items-center">
          <div>
            <img src={DocumentText} alt="logo" />
          </div>
          <p className="heading-large mx-16 text-center text-light_bg_primary">
            We are preparing your first report...
          </p>
          <div
            className="w-[245px] mt-4 h-2 rounded-full overflow-hidden"
            style={{ backgroundColor: 'rgba(31, 42, 55, 0.12)' }}
          >
            <div
              className="h-full rounded-full"
              style={{
                backgroundColor: 'rgba(31, 42, 55, 0.85)',
                animation: 'fillProgress 3s linear forwards',
              }}
            ></div>
          </div>
        </div>
        <style>
          {`
            @keyframes fillProgress {
              from { width: 0; }
              to { width: 100%; }
            }
          `}
        </style>
      </div>
    );
  }
  if (loading && day > 2) {
    return (
      <div>
        <Loader />
      </div>
    );
  }
  if (!dataLoaded) {
    return <Loader />;
  }

  const getMetricInfo = () => {
    switch (day) {
      case 2:
        return {
          title: 'Resting heart rate',
          subtitle: 'The speed at which your heart beats',
          value: data?.HR || 0, // Ensure default value
          unit: 'Beats/min',
          normalRange:
            'Adults at rest have a normal resting heart rate between 50 to 84 bpm.',
          yourRange:
            data?.HR && data.HR >= 50 && data.HR <= 84 ? (
              <span className="text-success_500">
                Yours lie in typical range of resting adults.
              </span>
            ) : (
              <span className="text-[#F79009]">
                Yours lie outside the typical range of resting adults.
              </span>
            ),
          symptoms: [
            'A resting heart rate under 50 bpm is of concern. Symptoms can range from dizziness, weakness, etc.',
            "Above 84 bpm can mean there's a heart issue you need to be aware of.",
          ],
        };
      case 3:
        return {
          title: 'Cardiorespiratory rhythm',
          subtitle:
            'Coordination between your heart rate and respiratory rate',
          value: data?.prq || 0, // Ensure default value
          unit: 'CRR=HR/RR',
          normalRange: 'Normal CRR ranges from 2 - 6.',
          yourRange:
            data?.prq && data.prq >= 2 && data.prq <= 6 ? (
              <span className="text-success_500">
                You have a normal coordination between your heart rate
                & respiratory rate.
              </span>
            ) : (
              <span className="text-[#F79009]">
                You've an irregular coordination between your heart
                rate & respiratory rate.
              </span>
            ),
          symptoms: [
            'An irregular CRR may indicate potential cardiovascular or respiratory issues.',
            'Consult a healthcare professional if you experience any related symptoms.',
          ],
        };
      case 4:
        return {
          title: 'Heart age',
          subtitle:
            'Estimated age of your heart based on the risk factors for cardiovascular disease',
          value: Math.ceil(data?.HeartAge) || '',
          unit: 'Years',
          normalRange:
            'Ideally, your heart age should be close to or lower than your actual age.',
          yourRange:
            data?.HeartAge &&
            parseInt(data.HeartAge) >
              parseInt(localStorage.getItem('userAge') || '0') ? (
              <span className="text-[#F79009]">
                Your heart age is older than your actual age. It's
                nothing to panic about, but you might be prone to
                cardiovascular risk.
              </span>
            ) : (
              <span className="text-success_500">
                Your heart age is in line with or lower than your
                actual age.
              </span>
            ),
          symptoms: [
            'Your risk of a heart attack or stroke may be higher if your heart age is greater than your actual age.',
          ],
        };
      case 5:
        return {
          title: 'Diabetes risk score',
          subtitle: 'Indicates your risk of developing diabetes',
          value: data?.DiabetesRiskScore || 0,
          unit: 'out of 100',
          normalRange:
            'A lower score indicates a lower risk of developing diabetes.',
          yourRange:
            data?.DiabetesRiskScore &&
            parseInt(data.DiabetesRiskScore) > 50 ? (
              <span className="text-[#F79009]">
                Your score is not that high but you are at moderate
                risk of developing diabetes.
              </span>
            ) : (
              <span className="text-success_500">
                Your score indicates a lower risk of developing
                diabetes.
              </span>
            ),
          symptoms: [
            'Higher scores may indicate an increased risk of developing type 2 diabetes.',
            'Lifestyle changes can help reduce your risk.',
          ],
        };
      case 6:
        return {
          title: 'Sleep apnea risk',
          subtitle:
            'A screening tool used to determine your risk of developing sleep-related breathing issues',
          value: data?.SleepApneaRiskScore || 0,
          unit: 'out of 10',
          normalRange:
            'Lower scores indicate a lower risk of sleep apnea.',
          yourRange:
            data?.SleepApneaRiskScore &&
            parseInt(data.SleepApneaRiskScore) <= 4.5 ? (
              <span className="text-success_500">
                Adults are more prone to developing Obstructive Sleep
                Apnea (OSA). Yours lie on the lower end of it.
              </span>
            ) : (
              <span className="text-[#F79009]">
                Your score indicates an increased risk of sleep apnea.
              </span>
            ),
          symptoms: [
            'Throat muscles might relax during sleep and block air passage.',
            'Blocked breathing can cause snoring',
          ],
        };
      default:
        return {
          title: 'No data available',
          subtitle: '',
          value: '',
          unit: '',
          normalRange: '',
          yourRange: '',
          symptoms: [],
        };
    }
  };

  const metricInfo = getMetricInfo();
  console.log(metricInfo.title, metricInfo.value);

  const advice = fetchAdvice(metricInfo.title, metricInfo.value);

  const renderModal = () => {
    switch (day) {
      case 2:
        return (
          <HeartRateDetail
            isOpen={isModalOpen}
            onClose={handleCloseModal}
          />
        );
      case 3:
        return (
          <CrrRateDetail
            isOpen={isModalOpen}
            onClose={handleCloseModal}
          />
        );
      case 4:
        return (
          <HeartAgeDetail
            isOpen={isModalOpen}
            onClose={handleCloseModal}
          />
        );
      case 5:
        return (
          <DiabetesRiskDetail
            isOpen={isModalOpen}
            onClose={handleCloseModal}
          />
        );
      case 6:
        return (
          <SleepApneaRiskDetail
            isOpen={isModalOpen}
            onClose={handleCloseModal}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Layout>
      <div className="flex items-center">
        <p className="text-light_bg_secondary heading-large my-5 mx-auto justify-center font-inter text-xs leading-5 font-normal">
          {metricInfo.title}
        </p>
        <div
          onClick={handleCrossClick}
          className="w-6 h-6 p-[5px] flex justify-center items-center bg-gray_100 rounded-full cursor-pointer"
        >
          <img className="h-3 w-3" src={CrossIcon} alt="cross icon" />
        </div>
      </div>
      <p className="body-large text-light_bg_secondary mt-2">
        {metricInfo.subtitle}
      </p>
      <div className="bg-[#F9FAFB] rounded-[16px]">
        <div className="flex pt-3 px-4 mt-2 items-baseline gap-x-[6px]">
          <h3 className="ph-display-number text-light_bg_primary">
            {metricInfo.value}
          </h3>
          <p className="body-large text-gray_400">
            {metricInfo.unit}
          </p>
        </div>

        <p className="text-light_bg_secondary font-inter font-normal leading-[22.4px] pb-3 px-4 mt-[4px] ">
          {metricInfo.normalRange} {metricInfo.yourRange}
        </p>
      </div>

      <div className="my-10">
        <div className="bg-[#0BA5EC] rounded-[16px] py-3 px-4 mt-2 ">
          <div className="flex items-center">
            <img src={HandOpenHeart} alt="Heart" />
            <h3 className="ml-2 text-[#F5FBFF] text-[20px] font-[500] font-inter leading-[24px] tracking-[-0.2px] ">
              Our advice
            </h3>
          </div>

          <p className="text-[#F5FBFF] text-[16px] font-inter font-normal leading-[24px] mt-2 ">
            {advice}
          </p>
        </div>
      </div>
      <div className="px-2">
        <p className="text-light_bg_primary heading-large mb-2">
          Symptom information
        </p>
        <div className="bg-[#F9FAFB] p-4 rounded-[16px]">
          <ul className="list-disc text-light_bg_secondary pl-5 font-inter text-[16px] leading-[24px]">
            {metricInfo.symptoms.map((symptom, index) => (
              <li key={index} className="mb-2">
                {symptom}
              </li>
            ))}
          </ul>
        </div>
        <button onClick={handleOpenModal} className="pb-28">
          <p className="body-large text-primary_500 underline mt-10">
            Learn more about {metricInfo.title}
          </p>
        </button>
      </div>
      <div className="w-full max-w-[393px] bg-[linear-gradient(180deg,rgba(255,255,255,0)_-62.49%,#fff_99.81%)] pt-10 mx-auto fixed bottom-0 left-0 right-0 px-5 pb-5 mt-4">
        <div onClick={handleCrossClick}>
          <CtaButton ButtonText="Done" LinkTo="" />
        </div>
      </div>

      {renderModal()}
    </Layout>
  );
};

export default ProfilerResult;
