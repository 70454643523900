import React from "react";
import Plot from "react-plotly.js";
import heartIconbw from "../assets/heartIconbw.svg";

const HeartRateOverviewCard = ({ heartRates }) => {
  console.log("HeartRateOverviewCard received heartRates:", heartRates);

  if (!Array.isArray(heartRates) || heartRates.length === 0) {
    console.log("HeartRates is not an array or is empty");
    return <div>No heart rate data available</div>;
  }

  // Sort heartRates by date
  const sortedHeartRates = [...heartRates].sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );

  const dates = sortedHeartRates.map((hr) => hr.date);
  const values = sortedHeartRates.map((hr) => hr.value);

  console.log("Sorted dates:", dates);
  console.log("Sorted values:", values);

  const data = [
    {
      x: dates,
      y: values,
      type: "scatter",
      mode: "lines+markers",
      marker: { color: "skyblue" },
    },
  ];

  const layout = {
    xaxis: {
      type: "date",
      tickformat: "%m-%d ",
      range: [dates[0], dates[dates.length - 1]], // Set the x-axis range to the full span of dates
    },
    yaxis: {
      title: "HR",
      range: [Math.min(...values) - 10, Math.max(...values) + 10],
      side: "right", // Move the y-axis to the right side
    },
    autosize: true,
    height: 120,
    width: 210,
    margin: { l: 20, r: 50, t: 0, b: 40 }, // Adjust the margins to accommodate the y-axis on the right
  };

  const latestHeartRate = values[values.length - 1];

  return (
    <div className="rounded-[16px] shadow-result_card border-[1px] border-light_bg_primary border-opacity-[0.12] bg-base_white mt-6">
      <div className="flex flex-col p-5 gap-y-2">
        <div className="flex items-center">
          <img src={heartIconbw} alt="heart icon" className="mr-[6px]" />
          <p className="body-small text-light_bg_secondary">
            Resting Heart Rate
          </p>
        </div>
        <div className="flex justify-between items-start">
          <div className="flex flex-col items-start">
            <p className="ph-display-number text-light_bg_primary">
              {latestHeartRate || 65}
            </p>
            <p className="body-small text-light_bg_secondary">Beats/min</p>
          </div>
          <div style={{ width: "250px", height: "100px" }}>
            <Plot
              data={data}
              layout={layout}
              config={{ responsive: true, displayModeBar: false }}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
      </div>
      <p className="pb-5 px-5 pt-0 font-inter text-[12px] leading-[18px] font-normal text-light_bg_secondary">
        Avg HR for most is 56-80 BPM. Your resting heart rate{" "}
        <span className="text-success_600">indicates a good heart health.</span>{" "}
        For even better health, manage to further lower it down.
      </p>
    </div>
  );
};

export default HeartRateOverviewCard;
