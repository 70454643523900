import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import Mood11 from "../../assets/Emojis/Mood11.png";
import Mood12 from "../../assets/Emojis/Mood12.png";
import Mood13 from "../../assets/Emojis/Mood13.png";
import Mood14 from "../../assets/Emojis/Mood14.png";
import Mood15 from "../../assets/Emojis/Mood15.png";
import Mood16 from "../../assets/Emojis/Mood16.png";
import Mood17 from "../../assets/Emojis/Mood17.png";
import Mood18 from "../../assets/Emojis/Mood18.png";
import Mood19 from "../../assets/Emojis/Mood19.png";
import Mood20 from "../../assets/Emojis/Mood20.png";
import Mood21 from "../../assets/Emojis/Mood21.png";
import Mood22 from "../../assets/Emojis/Mood22.png";
import Mood23 from "../../assets/Emojis/Mood23.png";
import Mood24 from "../../assets/Emojis/Mood24.png";
import Mood25 from "../../assets/Emojis/Mood25.png";
import Mood26 from "../../assets/Emojis/Mood26.png";
import Mood27 from "../../assets/Emojis/Mood27.png";
import Mood28 from "../../assets/Emojis/Mood28.png";
import Mood29 from "../../assets/Emojis/Mood29.png";
import Mood30 from "../../assets/Emojis/Mood30.png";
import Mood31 from "../../assets/Emojis/Mood31.png";
import Mood32 from "../../assets/Emojis/Mood32.png";
import Mood33 from "../../assets/Emojis/Mood33.png";
import Mood34 from "../../assets/Emojis/Mood34.png";
import Mood35 from "../../assets/Emojis/Mood35.png";
import CtaButton from "../../components/CtaButton";
import { setMoodData } from "../../utils/MoodLocalStorage";
import { useNavigate } from "react-router-dom";

const emojiSets = [
  [Mood11, Mood12, Mood13, Mood14, Mood15],
  [Mood16, Mood17, Mood18, Mood19, Mood20],
  [Mood21, Mood22, Mood23, Mood24, Mood25],
  [Mood26, Mood27, Mood28, Mood29, Mood30],
  [Mood31, Mood32, Mood33, Mood34, Mood35],
];

const moodDescriptions = [
  { left: "Restless", right: "Full of energy" },
  { left: "Relaxed", right: "Angry" },
  { left: "Bored", right: "Enthusiastic" },
  { left: "Calm", right: "Nervous" },
  { left: "Unhappy", right: "Happy" },
];

const MoodCard = ({ index, value, onChange, description }) => {
  const emojiIndex = Math.round(value);
  const emojis = emojiSets[index];

  return (
    <div className="p-4 shadow-journey_card bg-white rounded-[24px] mb-6">
      <div className="flex justify-center">
        <img
          src={emojis[emojiIndex]}
          alt="Mood Emoji"
          className="transition duration-500 ease-in-out h-[120px]"
        />
      </div>
      <div className="flex relative justify-center mt-6">
        <input
          type="range"
          min="0"
          max="4"
          step="1"
          value={value}
          onChange={(e) => onChange(index, Number(e.target.value))}
          className="w-full mini-slider"
        />
      </div>
      <div className="flex justify-between w-full mt-4 body-small text-light_bg_primary">
        <span>{description.left}</span>
        <span>{description.right}</span>
      </div>
    </div>
  );
};

const MoodLogDay4 = ({ shouldAnimate = true }) => {
  const [sliderValues, setSliderValues] = useState([2, 2, 2, 2, 2]);
  const [dayValue, setDayValue] = useState(null);
  const [isAnimating, setIsAnimating] = useState(shouldAnimate);
  const navigate = useNavigate();

  useEffect(() => {
    const day = localStorage.getItem("Day");
    setDayValue(day);

    if (shouldAnimate) {
      const timer = setTimeout(() => setIsAnimating(false), 50);
      return () => clearTimeout(timer);
    }
  }, [shouldAnimate]);

  const handleSliderChange = (index, value) => {
    const newValues = [...sliderValues];
    newValues[index] = value;
    setSliderValues(newValues);
  };

  const handleNext = () => {
    sliderValues.forEach((value, index) => {
      setMoodData(dayValue, `mood${index + 1}`, value);
    });
    navigate("/intro-questionnaire/questions", { replace: true });
  };

  return (
    <div
      className={`w-screen h-screen transition-transform duration-300 ease-out ${
        isAnimating ? "translate-x-full" : "translate-x-0"
      }`}
    >
      <Layout>
        <div className="flex-col items-center mb-10">
          <div className="flex justify-center">
            <h1 className="mt-5 mb-6 text-light_bg_secondary heading-large">
              Share your mood
            </h1>
          </div>
          <h1 className="font-inter text-[28px] leading-[33.6px] tracking-[-0.28px] text-gray_neutral_800">
            How are you feeling today?
          </h1>
          <p className="mt-2 text-sm-regular text-gray_neutral_500">
            This information is helpful for us to compute your health and
            produce accurate results.{" "}
            <span className="text-primary_500">Learn more</span>
          </p>
        </div>

        <div className="space-y-6">
          {sliderValues.map((value, index) => (
            <MoodCard
              key={index}
              index={index}
              value={value}
              onChange={handleSliderChange}
              description={moodDescriptions[index]}
            />
          ))}
        </div>

        <div
          onClick={handleNext}
          className="w-full max-w-[393px] mx-auto bottom-0 left-0 right-0 pb-5 mt-6"
        >
          <CtaButton ButtonText="Next" LinkTo="/intro-scan/scan/mood2" />
        </div>
      </Layout>
    </div>
  );
};

export default MoodLogDay4;
