import React from "react";
import Layout from "../../components/Layout";
import IntroQuestionnaire from "../../assets/IntroQuestionnaire.png";
import personQuestionMark from "../../assets/personQuestionMark.svg";
import documentHeart from "../../assets/documentHeart.svg";
import CtaButton from "../../components/CtaButton";

const IntroScan = () => {
  return (
    <>
      <div className="relative">
        <img src={IntroQuestionnaire} alt="Scan" className="w-full" />
      </div>
      <Layout>
        <div className="flex-col items-center">
          <div className="flex items-start mt-6">
            <img
              src={personQuestionMark}
              alt="heart"
              className="shadow-icon mr-4"
            />
            <div className="flex-col">
              <h3 className="heading-small text-light_bg_primary mb-1">
                Answer questions
              </h3>
              <h4 className="text-sm-regular text-light_bg_secondary">
                These answers will help us learn about your current health and
                detect any underlying problems to give solutions to
              </h4>
            </div>
          </div>
          <div className="flex items-start mt-8">
            <img src={documentHeart} alt="mood" className="shadow-icon mr-4" />
            <div className="flex-col">
              <h3 className="heading-small text-light_bg_primary mb-1">
                Get accurate results
              </h3>
              <h4 className="text-sm-regular text-light_bg_secondary">
                Together with the scan, these answers will help aid us in giving
                you a more detailed analysis of your health
              </h4>
            </div>
          </div>
          <div className="w-full max-w-[393px] mx-auto fixed bottom-0 left-0 right-0 px-5 pb-5">
            <div>
              <CtaButton
                ButtonText="Continue"
                LinkTo="/intro-questionnaire/questions"
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default IntroScan;
