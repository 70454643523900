import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout';
import CrossIcon from '../../assets/crossIcon.svg';
import { Link } from 'react-router-dom';
import TodaysResultCard from '../../components/TodaysResultCard';
import axios from 'axios';

const TodaysResult = () => {
  const [lastScanResult, setLastScanResult] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchScanDetails = async () => {
      const userId = localStorage.getItem('userId');
      if (!userId) {
        setError('User ID not found');
        setIsLoading(false);
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/scans/${userId}`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        if (response.data && response.data.length > 0) {
          const latestScan = response.data[response.data.length - 1];
          console.log('Latest scan:', latestScan);
          setLastScanResult(latestScan);
        } else {
          setError('No scan results found');
        }
      } catch (error) {
        console.error('Error fetching scan details:', error);
        setError('Failed to fetch scan details');
      } finally {
        setIsLoading(false);
      }
    };

    fetchScanDetails();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
    <Layout>
      <div className="flex justify-between items-center py-5">
        <div className="flex-grow text-center">
          <h1 className=" text-gray_neutral_800 font-inter text-xl font-[500] tracking-[0.28]">
            Today's result
          </h1>
          <h2 className=" text-gray_500 font-inter text-[12px] leading-[18px] uppercase tracking-[0.168px] mt-2">
            BASELINE YOUR HEART HEALTH
          </h2>
        </div>
        <div className="flex-shrink-0">
          <Link to="/">
            <div className="w-6 h-6 p-[5px] flex justify-center items-center bg-gray_100 rounded-full">
              <img
                className="h-3 w-3"
                src={CrossIcon}
                alt="cross icon"
              />
            </div>
          </Link>
        </div>
      </div>
      <TodaysResultCard
        heartRate={lastScanResult.vitals.heart_rate}
      />
    </Layout>
  );
};

export default TodaysResult;
