import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout';
import Mood1 from '../../assets/Emojis/Mood1.png';
import Mood2 from '../../assets/Emojis/Mood2.png';
import Mood3 from '../../assets/Emojis/Mood3.png';
import Mood4 from '../../assets/Emojis/Mood4.png';
import Mood5 from '../../assets/Emojis/Mood5.png';
import CtaButton from '../../components/CtaButton';
import { setMoodData } from '../../utils/MoodLocalStorage';
import { useNavigate } from 'react-router-dom';
import { sendAmplitudeData } from '../../utils/amplitude';

const emojis = [Mood1, Mood2, Mood3, Mood4, Mood5];

const MoodLog2 = ({ shouldAnimate = true }) => {
  const [sliderValue, setSliderValue] = useState(2);
  const [dayValue, setDayValue] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [isAnimating, setIsAnimating] = useState(shouldAnimate);
  const navigate = useNavigate();

  useEffect(() => {
    const day = localStorage.getItem('Day');
    setDayValue(day);

    if (shouldAnimate) {
      const timer = setTimeout(() => setIsAnimating(false), 50);
      return () => clearTimeout(timer);
    }
  }, [shouldAnimate]);

  const handleSliderChange = (e) => {
    setSliderValue(Number(e.target.value));
  };

  const handleSliderRelease = () => {
    sendAmplitudeData('MOOD_SLIDER1_CHANGE');
  };

  const handleNext = () => {
    setMoodData(dayValue, 'mood1', sliderValue);
    navigate('/intro-scan/scan/mood2', { replace: true });
  };

  const emojiIndex = Math.round(sliderValue);

  return (
    <Layout>
      <div className="flex-col items-center">
        <div className="flex justify-center">
          <h1 className=" mt-5 mb-6 text-light_bg_secondary heading-large">
            Share your mood
          </h1>
        </div>
        <h1 className="display-title text-light_bg_primary text-opacity-95">
          How pleasant are you feeling today?
        </h1>
        <p className="mt-2 text-md-regular text-light_bg_secondary">
          This information is helpful for us to compute your overall
          health and produce accurate results
        </p>
      </div>
      <div className="flex justify-center mt-[33px] mb-[40px]">
        <img
          src={emojis[emojiIndex]}
          alt="Mood Emoji"
          className="transition duration-500 ease-in-out"
        />
      </div>
      <div className="flex justify-center">
        <input
          type="range"
          min="0"
          max="4"
          step="0.01"
          value={sliderValue}
          onChange={handleSliderChange}
          onMouseUp={handleSliderRelease} // Send event when the mouse button is released
          onTouchEnd={handleSliderRelease} // Send event when the touch ends
          className="w-full slider"
        />
      </div>
      <div className="flex justify-between w-full mt-[22px] mb-6 text-sm-regular text-light_bg_primary">
        <span>Very unpleasant</span>
        <span>Very pleasant</span>
      </div>
      <div className="w-full z-50 max-w-[393px] mx-auto fixed bottom-0 left-0 right-0 px-5 pb-5">
        <div onClick={handleNext}>
          <CtaButton
            ButtonText="Next"
            LinkTo="/intro-scan/scan/mood2"
          />
        </div>
      </div>
    </Layout>
  );
};

export default MoodLog2;
