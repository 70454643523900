import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HomeIcon from "../../src/assets/HomeIcon.svg";
import HomeIconSelected from "../../src/assets/HomeIconSelected.svg";
import ScanIcon from "../../src/assets/ScanIcon.svg";
import ResultsIcon from "../../src/assets/ResultsIcon.svg";
import ResultsIconSelected from "../../src/assets/ResultsIconSelected.svg";
import axios from "axios";
import { sendAmplitudeData } from "../utils/amplitude"; // Import Amplitude utility

const BottomNav = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  const isHome = pathname.endsWith("/home") || pathname === "/home";
  const isResults = pathname.endsWith("/results") || pathname === "/results";

  const [currentDay, setCurrentDay] = useState(0);
  const [scanAllowed, setScanAllowed] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkScanAvailability = async () => {
      let storedDay = parseInt(localStorage.getItem("Day"), 10);
      if (!storedDay || storedDay < 1) {
        storedDay = 0;
      }
      setCurrentDay(storedDay);

      if (storedDay > 5) {
        setScanAllowed(false);
        setLoading(false);
        return;
      }

      const userId = localStorage.getItem("userId");
      if (!userId) {
        console.error("User ID not found in local storage.");
        setLoading(false); // Stop loading if no user ID found
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/scans/${userId}`
        );
        const scans = response.data;

        if (scans && scans.length > 0) {
          const latestScan = scans[scans.length - 1];
          const latestScanTimeIST = new Date(latestScan.scan_completion_time);
          const currentTimeIST = new Date();

          const next6AM = new Date(latestScanTimeIST);
          next6AM.setHours(6, 0, 0, 0);

          if (next6AM <= latestScanTimeIST) {
            next6AM.setDate(next6AM.getDate() + 1);
          }

          setScanAllowed(currentTimeIST >= next6AM);
        } else {
          setScanAllowed(true); // Allow scan if no scans exist
        }
      } catch (error) {
        console.error("Error fetching scans:", error);
        setScanAllowed(false); // No scan allowed in case of an error
      }
      setLoading(false);
    };

    checkScanAvailability();
  }, []);

  // handle navigation based on scan availability
  const handleScanButtonClick = () => {
    sendAmplitudeData("TAP_DAILY_SCAN_BUTTON");

    if (scanAllowed) {
      navigate("/intro-scan/scan"); // Navigate to scan if allowed
    } else {
      navigate("/daily-health-scan"); // Navigate to default screen if scan not allowed
    }
  };

  const eventName = `TAP_RESULT_BUTTON_${currentDay}`;

  // Log "Results tab" Amplitude click event
  const handleResultsTabClick = () => {
    sendAmplitudeData(eventName);
  };

  return (
    <nav className="fixed bottom-0 left-0 right-0 bg-white border-[1px] z-50 border-[#F3F4F6] ">
      <div className="flex justify-evenly items-center h-[73px]">
        <Link to="/home" className="flex flex-col items-center">
          <img src={isHome ? HomeIconSelected : HomeIcon} alt="Home" />
          <span
            className={`text-sm-regular text-light_bg_primary ${
              isResults ? "opacity-45" : "opacity-85"
            }`}
          >
            Home
          </span>
        </Link>

        {/* Scan Button */}
        <button
          className="flex gap-x-2 bg-primary_100 py-[10px] text-md-regular text-primary_500 px-4 rounded-full"
          onClick={handleScanButtonClick}
          disabled={loading} // Disable button while loading
        >
          <img src={ScanIcon} alt="Scan" />
          Scan
        </button>

        {/* Results Tab */}
        <Link
          to="/results"
          className="flex flex-col items-center"
          onClick={handleResultsTabClick}
        >
          <img
            src={isResults ? ResultsIconSelected : ResultsIcon}
            alt="Results"
          />
          <span
            className={`text-sm-regular text-light_bg_primary ${
              isResults ? "opacity-85" : "opacity-45"
            }`}
          >
            Results
          </span>
        </Link>
      </div>
    </nav>
  );
};

export default BottomNav;
