import React, { useState } from 'react';
import heartIconbw from '../../src/assets/heartIconbw.svg';
import HeartRateVariabilityModal from './HeartRateVariabilityModal';

const HeartRateVariability = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const mockHRV = 42;
  const minHRV = 20;
  const maxHRV = 200;
  const rate = mockHRV;

  const calculatePosition = (rate) => {
    const clampedRate = Math.max(minHRV, Math.min(maxHRV, rate));
    return ((clampedRate - minHRV) / (maxHRV - minHRV)) * 100;
  };

  const getStatusAndColor = (rate) => {
    // Show green tick if between 20 and 200
    if (rate >= minHRV && rate <= maxHRV) {
      return { status: 'All Good', color: '#32D583' }; // Green
    } else {
      return { status: 'Heads up', color: '#F97066' }; // Red for out of range
    }
  };

  const position = calculatePosition(rate);
  const { color } = getStatusAndColor(rate);

  return (
    <>
      <div
        className="p-3 bg-base_white shadow-result_card h-[175px] max-h-[175px] rounded-[16px] max-w-[175px] mt-3 border-[1px] border-light_bg_primary border-opacity-[0.12]"
        onClick={handleOpenModal}
      >
        <div className="flex gap-[6px] items-center">
          <img src={heartIconbw} alt="heart" />
          <p className="text-sm-medium text-light_bg_primary text-opacity-95 text-nowrap">
            Heart rate variability
          </p>
        </div>
        <div className="flex items-baseline gap-[6px] mt-[3px]">
          <h3 className="ph-display-number text-light_bg_primary">
            {rate}
          </h3>
          <p className="body-small text-light_bg_secondary">ms</p>
        </div>
        <div className="relative mt-[32px] h-[8px]">
          <div
            className="h-[6px] w-full rounded-full"
            style={{
              background:
                'linear-gradient(90deg, #F97066 0%, #FDB022 30%, #32D583 100%)',
            }}
          ></div>
          <div
            className="absolute top-[-8px] z-20 border-white rounded-full flex items-center justify-center"
            style={{
              left: `${position}%`,
              transform: 'translateX(-50%)',
              backgroundColor: 'white',
            }}
          >
            <div
              className="h-[22px] w-[22px] border-[3px] border-white rounded-full flex items-center justify-center"
              style={{ backgroundColor: color }}
            >
              {color === '#32D583' ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.2294 0.265979C11.5902 0.620617 11.5902 1.1956 11.2294 1.55024L4.93737 7.73402C4.76408 7.90433 4.52906 8 4.284 8C4.03894 8 3.80392 7.90433 3.63063 7.73402L0.770633 4.92321C0.409789 4.56857 0.409789 3.99359 0.770633 3.63895C1.13148 3.28431 1.71652 3.28431 2.07737 3.63895L4.284 5.80763L9.92263 0.265979C10.2835 -0.0886595 10.8685 -0.0886596 11.2294 0.265979Z"
                    fill="#1F2A37"
                    fillOpacity="0.95"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2"
                  height="10"
                  viewBox="0 0 2 10"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.87551 0.896901C1.90592 0.374093 1.44909 0 1.00073 0C0.552368 0 0.0955341 0.374093 0.125938 0.896901L0.34434 6.79375C0.347002 6.96414 0.415844 7.12699 0.536535 7.24768C0.659647 7.37079 0.826622 7.43995 1.00073 7.43995C1.17483 7.43995 1.34181 7.37079 1.46492 7.24768C1.58561 7.12699 1.65445 6.96414 1.65711 6.79375L1.87551 0.896901ZM0.99214 0.881763C0.992141 0.881765 0.99218 0.88172 0.992252 0.881626C0.992174 0.881715 0.992139 0.881762 0.99214 0.881763ZM1.00931 0.881763C1.00931 0.881762 1.00928 0.881715 1.0092 0.881626C1.00927 0.88172 1.00931 0.881765 1.00931 0.881763Z"
                    fill="#1F2A37"
                    fillOpacity="0.95"
                  />
                </svg>
              )}
            </div>
          </div>
        </div>
        <p className="body-small text-light_bg_secondary mt-2 leading-[38.4px]">
          Normal range lies in 20 to 200 ms
        </p>
      </div>
      <HeartRateVariabilityModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        heartRateVariability={rate}
      />
    </>
  );
};

export default HeartRateVariability;
