import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SubscriptionSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/intro-questionnaire/questions");
    }, 4800);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-white">
      <div className=" w-[124px] h-[124px] bg-success_400 rounded-full flex items-center justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="65"
          height="64"
          viewBox="0 0 65 64"
          fill="none"
        >
          <path
            d="M53.8317 16L24.4984 45.3333L11.165 32"
            stroke="#F6FEF9"
            stroke-width="6.85714"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <h1 className=" display-sm-regular text-gray_800 mt-6">
        Congratulations!
      </h1>
      <p className=" text-sm-regular mt-2 text-light_bg_secondary text-center px-4">
        You've subscribed to the{" "}
        <span className=" font-[600]">Profiler Journey</span>
      </p>
      <p className="text-sm-regular mt-3 px-6 text-center text-light_bg_secondary">
        Your health report is almost done, please help us by answering a
        questionnaire to get a detailed and accurate report.
      </p>
    </div>
  );
};

export default SubscriptionSuccess;
