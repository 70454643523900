import React, { useState, useEffect } from 'react';
import axios from 'axios';
import chevronLeft from '../../assets/chevronLeft.svg';
import Layout from '../../components/Layout';
import { Link, useNavigate } from 'react-router-dom';
import CtaButton from '../../components/CtaButton';
import PreOnboarding from '../../assets/PreOnboarding.svg';

// Star Icon Component
const StarIcon = ({ filled, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    onClick={onClick}
    className="cursor-pointer transition-all duration-300 ease-in-out mr-2"
  >
    <path
      d="M21.803 6.08642C22.7017 4.26547 25.2983 4.26546 26.197 6.08642L31.0493 15.9184L41.8996 17.495C43.9091 17.787 44.7115 20.2566 43.2574 21.674L35.4061 29.3271L37.2595 40.1335C37.6028 42.1349 35.5021 43.6611 33.7047 42.7162L24 37.6141L14.2952 42.7162C12.4978 43.6611 10.3971 42.1349 10.7404 40.1335L12.5938 29.3271L4.74255 21.674C3.28843 20.2566 4.09083 17.787 6.10037 17.495L16.9506 15.9184L21.803 6.08642Z"
      fill={filled ? '#FAC515' : '#1F2A37'}
      fillOpacity={filled ? '1' : '0.12'}
    />
  </svg>
);

const Feedback = () => {
  const [rating, setRating] = useState(0);
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [userId, setUserId] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const savedRating = localStorage.getItem('userRating');
    const userIdFromStorage = localStorage.getItem('userId'); // Fetch userId from localStorage

    if (savedRating) {
      setRating(parseInt(savedRating));
    }
    if (userIdFromStorage) {
      setUserId(userIdFromStorage);
    }
  }, []);

  const handleStarClick = (value) => {
    setRating(value);
    localStorage.setItem('userRating', value.toString());
  };

  const handleSubmit = async () => {
    try {
      console.log(userId, message, email, rating);
      // Send feedback data to backend API
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/feedback`,
        {
          userId,
          message,
          email,
          rating,
        }
      );

      if (response.status === 201) {
        console.log('Feedback submitted successfully');
        // Redirect to success page
        navigate('/feedback-success');
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${PreOnboarding})`,
        backgroundRepeat: 'no-repeat',
        position: 'relative',
      }}
    >
      <Layout>
        <div className="relative flex items-center justify-center my-5">
          <Link to="/home" className="absolute left-0">
            <div className="w-6 h-6 p-[14px] flex justify-center items-center bg-white rounded-full">
              <img
                className="min-h-[18px] min-w-[18px] shadow-sm text-light_bg_primary"
                src={chevronLeft}
                alt="Back Button"
              />
            </div>
          </Link>
          <p className="text-light_bg_primary opacity-65 heading-large">
            Share your feedback
          </p>
        </div>
        <h1 className="mt-10 font-inter text-[28px] font-normal leading-[33.6px] tracking-[-0.28px]">
          We’d love to hear how we can make this app better
        </h1>
        <div className="mt-6">
          <p className="text-sm-medium text-light_bg_primary opacity-65">
            Your message
          </p>
          <textarea
            className="placeholder-custom-color border-[1px] body-large text-light_bg_primary border-black border-opacity-[0.07] rounded-xl backdrop-blur-sm mt-2 w-full pt-4 px-4 pb-20 bg-[#ffffff] bg-opacity-60 "
            type="text"
            placeholder="Type here..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>
        <div className="mt-6">
          <p className="text-sm-medium text-light_bg_primary opacity-65">
            Your email address
          </p>
          <input
            className="placeholder-custom-color border-[1px] body-large text-light_bg_primary border-black border-opacity-[0.07] rounded-xl backdrop-blur-sm mt-2 w-full p-4 bg-[#ffffff] bg-opacity-60 "
            type="text"
            placeholder="yourname@email.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></input>
        </div>
        <div className="mt-6">
          <p className="text-sm-medium text-light_bg_primary opacity-65">
            Rate your overall experience
          </p>
          <div className="flex mt-2">
            {[1, 2, 3, 4, 5].map((value) => (
              <StarIcon
                key={value}
                filled={value <= rating}
                onClick={() => handleStarClick(value)}
              />
            ))}
          </div>
        </div>
        <div onClick={handleSubmit}>
          <div className="w-full max-w-[393px] mx-auto fixed bottom-0 left-0 right-0 px-5 pb-5">
            <CtaButton ButtonText="Submit" />
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Feedback;
