import React from 'react';
import Bedbw from '../assets/Bedbw.svg';
import Syringebw from '../assets/Syringebw.svg';
import Lockbw from '../assets/Lockbw.svg';

const LockedGaugeGraph = ({ Score, CardName, Icon, day }) => {
  const score = Score !== undefined ? Score : 2;

  const getRiskLevel = (score) => {
    if (score <= 4) return 'Low';
    return 'High';
  };

  const getRiskText = (score) => {
    if (score <= 4) return 'Congrats! Your OSA score is normal.';
    return 'Your OSA score is higher than normal.';
  };

  // FIXME: Unused code
  // const minScore = 0;
  // const maxScore = 8;
  // const calculatePosition = (score) => {
  //   if (score < minScore) score = minScore;
  //   if (score > maxScore) score = maxScore;
  //   return (score / maxScore) * 100;
  // };

  // const getColorAtPosition = (position) => {
  //   const colors = ["#32d583", "#fdb022", "#f97066"];
  //   const index = Math.floor((position / 100) * (colors.length - 1));
  //   return colors[index];
  // };

  // const position = calculatePosition(score);
  // const indicatorColor = getColorAtPosition(position);

  const renderIcon = () => {
    if (Icon === 1) {
      return <img src={Bedbw} alt="Bed Icon" className="w-[20px] h-[20px]" />;
    } else if (Icon === 2) {
      return (
        <img src={Syringebw} alt="Syringe Icon" className="w-[20px] h-[20px]" />
      );
    }
    return null;
  };

  return (
    <div className="bg-base_white pt-3 px-3 pb-1 rounded-[16px] border border-[#E5E7EB] shadow-result_card mt-2 relative">
      <div className="flex justify-between">
        <div className="flex flex-col justify-between">
          <div className="flex gap-[6px]">
            {renderIcon()}
            <p className="text-sm-medium text-nowrap text-light_bg_primary">
              {CardName}
            </p>
          </div>
          <div className="flex-grow"></div>
          <p className="text-[14px] blur-sm font-normal leading-[21px] text-light_bg_secondary mb-3">
            {getRiskText(score)}
          </p>
        </div>

        <div className="flex blur-lg items-start">
          <div className="relative w-[135px] h-[120px]">
            <svg viewBox="0 0 200 200" className="w-full h-full">
              <defs>
                <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="0%" stopColor="#f97066" />
                  <stop offset="50%" stopColor="#fdb022" />
                  <stop offset="100%" stopColor="#32d583" />
                </linearGradient>
              </defs>
              <circle
                cx="100"
                cy="100"
                r="80"
                fill="none"
                stroke="url(#gradient)"
                strokeWidth="10"
                strokeDasharray={340}
                strokeDashoffset={0}
                strokeLinecap="round"
                transform="rotate(149 100 100)"
              />
              <text
                x="45"
                y="160"
                textAnchor="start"
                className="text-sm-regular text-light_bg_secondary"
                style={{
                  fontSize: '20px',
                  color: '#1F2A37',
                  opacity: '0.64',
                  lineHeight: '20.3px',
                  fontFamily: 'Prodigy Sans',
                }}
              >
                Low
              </text>
              <text
                x="150"
                y="160"
                textAnchor="end"
                className="text-sm-regular text-light_bg_secondary"
                style={{
                  fontSize: '20px',
                  color: '#1F2A37',
                  opacity: '0.64',
                  lineHeight: '20.3px',
                  fontFamily: 'Prodigy Sans',
                }}
              >
                High
              </text>
              <text
                x="100"
                y="105"
                textAnchor="middle"
                className="display-number-medium text-light_bg_primary"
                style={{
                  fontSize: '32px',
                  color: '#1F2A37',
                  fontWeight: '500',
                  lineHeight: '38.4px',
                  fontFamily: 'Prodigy Sans',
                }}
              >
                {getRiskLevel(score)}
              </text>
            </svg>
          </div>
        </div>
      </div>

      {/* Centered Lock icon */}
      <div className="absolute inset-0 flex flex-col items-center justify-center z-10">
        <img src={Lockbw} alt="Lock Icon" className="w-[40px] h-[40px]" />
        <p className="text-sm-medium text-[#98A2B3]">Unlocks on Day {day}</p>
      </div>
    </div>
  );
};

export default LockedGaugeGraph;
