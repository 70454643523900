import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FeedbackSuccessbg from '../../assets/FeedbackSuccessbg.svg';

const FeedbackSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Automatically route after 3000ms (3 seconds)
    const timer = setTimeout(() => {
      navigate('/home');
    }, 4800);

    // Cleanup the timer if the component unmounts before the timeout completes
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div
      style={{
        backgroundImage: `url(${FeedbackSuccessbg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
      }}
    >
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="w-[124px] h-[124px] bg-success_400 rounded-full flex items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="65"
            height="64"
            viewBox="0 0 65 64"
            fill="none"
          >
            <path
              d="M53.8317 16L24.4984 45.3333L11.165 32"
              stroke="#F6FEF9"
              strokeWidth="6.85714"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <h2 className="ph-display-title-small mx-8 text-light_bg_primary mt-2 text-center">
          Thank you for sharing your feedback
        </h2>
        <p className="text-center mx-12 text-light_bg_secondary body-large mt-2">
          Your information is safe with us. We’ll get back to you.
        </p>
      </div>
    </div>
  );
};

export default FeedbackSuccess;
