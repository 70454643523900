import React from "react";
import { Link } from "react-router-dom";

const CtaButton = ({ ButtonText, LinkTo }) => {
  return (
    <Link to={LinkTo} className="w-full max-w-[353px]">
      <button
        className="heading-small font-inter
       flex w-full max-w-[353px] justify-center items-center bg-primary_500 px-5 py-3 border border-primary_500 border-solid rounded-lg mb-3 text-base_white shadow-xs z-10"
      >
        {ButtonText}
      </button>
    </Link>
  );
};

export default CtaButton;
