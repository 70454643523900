import React, { useEffect, useState } from 'react';

const HeartAnimation = () => {
  // eslint-disable-next-line no-unused-vars
  const [isBeating, setIsBeating] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsBeating((prev) => !prev);
    }, 1500);
    return () => clearInterval(interval);
  }, []);

  const heartStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '41px',
    height: '38px',
    animation: 'heartbeat 1s infinite ease-in-out',
  };

  return (
    <>
      <style>
        {`
          @keyframes heartbeat {
            0%, 100% { transform: translate(-50%, -50%) scale(1); }
            50% { transform: translate(-50%, -50%) scale(1.1); }
          }
        `}
      </style>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="41"
        height="38"
        viewBox="0 0 53 50"
        style={heartStyles}
      >
        <path
          d="M53 16.1496C53 34.3831 28.4425 49.1418 27.3967 49.7513C27.1211 49.9146 26.813 50 26.5 50C26.187 50 25.8789 49.9146 25.6033 49.7513C24.5575 49.1418 0 34.3831 0 16.1496C0.00438371 11.868 1.55134 7.76305 4.30148 4.73545C7.05162 1.70785 10.7804 0.00482597 14.6696 0C19.5556 0 23.8334 2.31305 26.5 6.22282C29.1666 2.31305 33.4444 0 38.3304 0C42.2196 0.00482597 45.9484 1.70785 48.6985 4.73545C51.4487 7.76305 52.9956 11.868 53 16.1496Z"
          fill="white"
          fillOpacity="0.3"
        />
      </svg>
    </>
  );
};

export default HeartAnimation;
