import React from "react";

const CornerSVG = () => {
  return (
    <>
      <div>
        <div className="absolute top-1 right-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
          >
            <path
              d="M2 2H26C34.8366 2 42 9.16345 42 18V42"
              stroke="white"
              strokeWidth="4"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <div className="absolute bottom-1 right-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
          >
            <path
              d="M2 42H26C34.8366 42 42 34.8366 42 26V2"
              stroke="white"
              strokeWidth="4"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <div className="absolute bottom-1 left-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
          >
            <path
              d="M42 42H18C9.16344 42 2 34.8366 2 26V2"
              stroke="white"
              strokeWidth="4"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <div className="absolute top-1 left-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
          >
            <path
              d="M42 2H18C9.16344 2 2 9.16345 2 18V42"
              stroke="white"
              strokeWidth="4"
              strokeLinecap="round"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default CornerSVG;
