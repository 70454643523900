import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import HeartRate from "../../components/HeartRate";
import RespirationRate from "../../components/RespirationRate";
import OxygenSaturation from "../../components/OxygenSaturation";
import BloodPressureCard from "../../components/BloodPressureCard";
import LearnMoreModal from "../../components/LearnMoreModal";
import HeartRateVariability from "../../components/HeartRateVariability";
import CtaButton from "../../components/CtaButton";

const DailyVitalResults = () => {
  const [scanResult, setScanResult] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  //deleting the previous saved vital result from localstorage
  const handleDeleteScandata = () => {
    localStorage.removeItem("dailyScanData");
  };

  useEffect(() => {
    const fetchScanDetails = () => {
      const storedScanData = localStorage.getItem("dailyScanData");
      console.log("storedScanData", JSON.parse(storedScanData));
      if (storedScanData) {
        const parsedData = JSON.parse(storedScanData);
        setScanResult(parsedData);
        setIsLoading(false);
      } else {
        setError("No scan data found");
        setIsLoading(false);
      }
    };

    fetchScanDetails();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="bg-gray_neutral_50">
      <Layout>
        <h1 className="display-sm-regular mt-3 text-gray_neutral_800">
          Your scan results
        </h1>
        <p className="text-sm-regular mt-2 text-light_bg_secondary mb-4">
          These are your body's vital signs from the finger scan. To understand
          them better{" "}
          <span className="text-primary_500 font-inter text-[14px] font-[500] leading-5">
            <button onClick={handleOpenModal}>learn more</button>
          </span>
        </p>
        {scanResult && (
          <div className="flex flex-col items-stretch space-y-1 w-full">
            <HeartRate heartRate={scanResult.vitals.heart_rate} />
            <div className="flex justify-center items-stretch w-full gap-3">
              <div className="flex-1 min-w-0">
                <RespirationRate
                  respirationRate={scanResult.vitals.resp_rate}
                />
              </div>
              <div className="flex-1 min-w-0">
                <BloodPressureCard
                  systolic={scanResult.vitals.systolic}
                  diastolic={scanResult.vitals.diastolic}
                />
              </div>
            </div>
            <div className="flex justify-center items-stretch w-full gap-3">
              <div className="flex-1 min-w-0">
                <HeartRateVariability />
              </div>
              <div className="flex-1 min-w-0">
                <OxygenSaturation
                  oxygenSaturation={scanResult.vitals.oxy_sat_prcnt}
                />
              </div>
            </div>
          </div>
        )}
        <div
          className="w-full max-w-[393px] mx-auto bottom-0 left-0 right-0 pb-5 mt-8"
          onClick={handleDeleteScandata}
        >
          <CtaButton ButtonText="Done" LinkTo="/home" />
        </div>
      </Layout>
      <LearnMoreModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </div>
  );
};

export default DailyVitalResults;
