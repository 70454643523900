import React from "react";
import { Navigate } from "react-router-dom";

const AuthWrapper = ({ children }) => {
  const login = localStorage.getItem("Login");

  if (login === "true") {
    return <Navigate to="/home" />;
  }

  return children;
};

export default AuthWrapper;
