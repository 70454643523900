import React, { useEffect, useState } from 'react';
import CtaButton from './CtaButton';
import GreenOngoing from '../assets/GreenOngoing.svg';
import HeartPulsebw from '../assets/HeartPulsebw.svg';
import Syringebw from '../assets/Syringebw.svg';
import Lungsbw from '../assets/lungsIconbw.svg';
import Bedbw from '../assets/Bedbw.svg';
import clockbw from '../assets/clockbw.svg';
import ProfilerGradient from '../../src/assets/ProfilerGradientbg.png';
import Tickbw from '../assets/Tickbw.svg';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { sendAmplitudeData } from '../utils/amplitude';
import Loader from '../utils/Loader';

// Set this flag to true to disable the scan restriction for testing
const DEV_MODE = false;

const FullScreenLoader = () => (
  <div className="fixed inset-0 bg-white z-[100] flex items-center justify-center">
    <Loader />
  </div>
);

const ProfilerJourney = ({ completedSteps = 0 }) => {
  const navigate = useNavigate();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [dayValue, setDayValue] = useState('');
  const [isCompleted, setIsCompleted] = useState(false);
  const [scanAllowed, setScanAllowed] = useState(DEV_MODE);
  const [loading, setLoading] = useState(false);
  const journeyCompletionDay = 6;

  useEffect(() => {
    const storedDayValue = localStorage.getItem('Day');
    if (storedDayValue) {
      const day = parseInt(storedDayValue, 10);
      setIsSubscribed(true);
      setDayValue(storedDayValue);
      setIsCompleted(day >= journeyCompletionDay); // Mark as completed if day is greater than or equal to 6
    }

    if (!DEV_MODE && isSubscribed) {
      checkScanAvailability();
    }

    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
  }, [isSubscribed]);

  const checkScanAvailability = async () => {
    if (DEV_MODE) {
      setScanAllowed(true);
      return;
    }

    const userId = localStorage.getItem('userId');
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/scans/${userId}`
      );
      const scans = response.data;

      if (scans && scans.length > 0) {
        const latestScan = scans[scans.length - 1];

        const latestScanTimeIST = new Date(
          latestScan.scan_completion_time
        );
        const currentTimeIST = new Date(); // Assuming client's clock is in IST

        const next6AM = new Date(latestScanTimeIST);
        next6AM.setHours(6, 0, 0, 0);

        if (next6AM <= latestScanTimeIST) {
          // If the latest scan was at or after 6 AM, set next6AM to 6 AM on the next day
          next6AM.setDate(next6AM.getDate() + 1);
        }

        if (currentTimeIST >= next6AM) {
          // Allow scan
          setScanAllowed(true);
        } else {
          // Scan not allowed yet
          setScanAllowed(false);
        }
      } else {
        setScanAllowed(true); // No scans exist, allow the scan
      }
    } catch (error) {
      console.error('Error fetching scans:', error);
      setScanAllowed(false);
    }
  };

  const handleSubscribe = async () => {
    setLoading(true);
    const userId = localStorage.getItem('userId');

    if (!userId) {
      console.error('User ID not found in local storage.');
      setLoading(false);
      return;
    }

    try {
      await Promise.all([
        axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/questionnaires/${userId}`
        ),
        axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/journeys/${userId}`
        ),
      ]);

      console.log(
        'Questionnaire, Journey created, and Day value set successfully'
      );

      // Set Day value in local storage and update component state
      localStorage.setItem('Day', '1');
      setDayValue('1');
      navigate('/intro-questionnaire');
      setIsSubscribed(true);
      setLoading(false);
      sendAmplitudeData('RESTING_HEART_SIGN_UP');
    } catch (error) {
      console.error(
        'Error creating questionnaire or journey:',
        error
      );
    }
  };

  const steps = [
    {
      title: 'Resting heart rate',
      description: 'Day 1',
      image: HeartPulsebw,
    },
    {
      title: 'Cardiorespiratory rhythm',
      description: 'Day 2',
      image: Lungsbw,
    },
    {
      title: 'Heart Age',
      description: 'Day 3',
      image: clockbw,
    },
    {
      title: 'Diabetes risk score, Heart energy',
      description: 'Day 4',
      image: Syringebw,
    },
    {
      title: 'Sleep apnea risk score',
      description: 'Day 5',
      image: Bedbw,
    },
  ];
  const CompletedAssessment = () => (
    <>
      <h2 className="heading-small text-light_bg_primary mb-2">
        Assessment complete! What's next?
      </h2>
      <p className="body-small text-light_bg_secondary mb-4">
        Start working on changes and improvements in your lifestyle.
        Go through this assessment again in 30 days to learn about
        your heart health's progress.
      </p>
      <div className="mb-4">
        <div className="flex items-start">
          {' '}
          <h3 className="heading-small text-light_bg_primary mb-2 mr-1">
            Summary
          </h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
          >
            <path
              d="M3.20041 6.23966C3.48226 5.93613 3.95681 5.91856 4.26034 6.20041L8 9.72652L11.7397 6.20041C12.0432 5.91856 12.5177 5.93613 12.7996 6.23966C13.0815 6.5432 13.0639 7.01775 12.7603 7.2996L8.51034 11.2996C8.22258 11.5668 7.77743 11.5668 7.48967 11.2996L3.23966 7.2996C2.93613 7.01775 2.91856 6.5432 3.20041 6.23966Z"
              fill="#1F2A37"
            />
          </svg>
        </div>

        <ul className="space-y-2">
          {[
            'Heart rate and HRV show positive trends. Your cardiovascular health is in good shape',
            'Blood pressure has been fluctuating on the higher side.',
            'Lungs are showing positive signs due to optimum CRR',
          ].map((item, index) => (
            <li key={index} className="flex items-start">
              <img
                src={Tickbw}
                alt="Checkmark"
                className="w-4 h-4 mt-1 mr-2"
              />
              <span className="body-small text-light_bg_secondary">
                {item}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex gap-x-2">
        <Link className="" to="/results">
          <button className="text-light_bg_primary max-h-12 min-w-[129px] px-5 py-4 heading-small mb-3 flex items-center justify-center border-[1px] border-[#D0D5DD] rounded-xl shadow-xs">
            View report
          </button>
        </Link>
        <button
          className="heading-small
       flex p-4 justify-center max-h-12 items-center w-full bg-primary_500 border border-primary_500 border-solid rounded-xl mb-3 text-base_white shadow-xs"
        >
          Remind in 30 days
        </button>
      </div>
    </>
  );

  const OngoingAssessment = () => (
    <>
      <div className="flex">
        <div className="flex-grow pr-4">
          {steps.map((step, index) => (
            <div key={index} className="mb-2">
              <div className="flex gap-x-4">
                <div className="min-w-8 max-w-8 mt-[2px] max-h-8 min-h-8 flex items-center justify-center bg-[#F9FAFB] rounded-[8px]">
                  <img
                    src={step.image}
                    alt={step.title}
                    className="max-w-[20px] max-h-[20px]"
                  ></img>
                </div>
                <div>
                  <h3 className="text-sm-medium text-light_bg_primary">
                    {step.title}
                  </h3>
                  <p className="text-sm-regular text-light_bg_secondary line-clamp-2">
                    {step.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="relative mr-4 ml-4 mt-3">
          <div
            className="absolute left-1/2 transform -translate-x-1/2 w-px bg-[#E5E7EB]"
            style={{ height: `${(steps.length - 1) * 20}%` }}
          ></div>
          {steps.map((_, index) => (
            <div
              key={index}
              className={`absolute ${
                index < completedSteps
                  ? 'w-5 h-5 rounded-full -left-[0.6rem] bg-success_400'
                  : index === completedSteps
                  ? 'w-6 h-6 -left-[0.75rem]'
                  : 'w-5 h-5 rounded-full -left-[0.6rem] bg-base_white border-[1px] border-[#E5E7EB]'
              }`}
              style={{ top: `${(index / (steps.length - 1)) * 80}%` }}
            >
              {index < completedSteps ? (
                <svg
                  className="w-3 h-3 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M5 13l4 4L19 7"></path>
                </svg>
              ) : index === completedSteps ? (
                <img
                  src={GreenOngoing}
                  alt="Ongoing step"
                  className="absolute rounded-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-6 w-6"
                />
              ) : null}
            </div>
          ))}
        </div>
      </div>

      {isSubscribed ? (
        <div className="flex justify-center gap-4">
          <div className={scanAllowed ? 'w-full' : 'w-full'}>
            {scanAllowed ? (
              <CtaButton
                LinkTo="/intro-scan/scan"
                ButtonText={`Begin day ${dayValue} scan`}
              />
            ) : (
              // Custom button for when scan is not allowed
              <button className="text-sm-regular font-inter flex w-full max-w-[353px] justify-center items-center bg-[#F3F4F6] px-5 py-3 rounded-lg mb-3 text-light_bg_secondary shadow-xs z-10">
                Come back tomorrow for your next report
              </button>
            )}
          </div>
        </div>
      ) : (
        <div onClick={handleSubscribe}>
          <CtaButton
            LinkTo=""
            ButtonText="Get your heart care report"
          />
        </div>
      )}
    </>
  );

  return (
    <>
      {loading && <FullScreenLoader />}
      <div className="max-w-md mx-auto bg-white rounded-[16px] overflow-hidden shadow-journey_card">
        <div
          style={{
            backgroundImage: `url(${ProfilerGradient})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
          className="pb-4 pl-4 pr-4 pt-5"
        >
          <h2 className="heading-large text-light_bg_primary opacity-85">
            Resting heart journey
          </h2>
          <p className="text-sm-regular text-light_bg_primary opacity-65">
            Monitor your resting heart rate across 5 days, capturing
            variations due to sleep, stress, and daily rhythms.
          </p>
        </div>

        <div className="px-4 pt-4 pb-1">
          {isCompleted ? (
            <CompletedAssessment />
          ) : (
            <OngoingAssessment />
          )}
        </div>
      </div>
    </>
  );
};

export default ProfilerJourney;
