import React from "react";
import refer from "../../src/assets/Refer.png";

const Refer = () => {
  return (
    <div className="w-full pb-26">
      <div
        style={{
          backgroundImage: `url(${refer})`,
          backgroundRepeat: "no-repeat",
        }}
        className="w-full pb-[100.36%] bg-cover rounded-2xl bg-center"
      ></div>
    </div>
  );
};

export default Refer;
