import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import preOnboardSuccessLogo from "../../assets/preOnboardSuccessLogo.svg";

const UserDetailSuccess = () => {
  const [opacity, setOpacity] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    // Fade in animation
    setOpacity(1);

    // Navigate to IntroScan after 2000ms
    const timer = setTimeout(() => {
      navigate("/intro-scan", { state: { animate: true }, replace: true });
    }, 4800);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="w-screen h-screen flex items-center justify-center bg-base_white">
      <div
        className="flex flex-col items-center justify-center text-center transition-opacity duration-600 ease-in-out"
        style={{ opacity }}
      >
        <img src={preOnboardSuccessLogo} alt="logo" className="" />
        <h1 className="heading-large text-light_bg_primary text-opacity-95 mt-1">
          Thank you for sharing
        </h1>
        <p className="text-sm-regular text-light_bg_secondary mt-2 max-w-[307px]">
          Your information is safe with us. We're excited to start this heart
          health journey with you!
        </p>
      </div>
    </div>
  );
};

export default UserDetailSuccess;
