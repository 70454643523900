const MOOD_DATA_KEY = "mood_data";

const getMoodDataObject = () => {
  const data = localStorage.getItem(MOOD_DATA_KEY);
  return data ? JSON.parse(data) : {};
};

const setMoodDataObject = (data) => {
  localStorage.setItem(MOOD_DATA_KEY, JSON.stringify(data));
};

export const setMoodData = (day, moodType, value) => {
  const data = getMoodDataObject();
  if (!data[day]) {
    data[day] = {};
  }
  data[day][moodType] = value;
  setMoodDataObject(data);
};

export const getMoodData = (day, moodType) => {
  const data = getMoodDataObject();
  return data[day] && data[day][moodType];
};

export const calculateAverageMood = () => {
  const data = getMoodDataObject();
  let totalMood = 0;
  let count = 0;

  Object.values(data).forEach((dayData) => {
    if (dayData.mood1) {
      totalMood += parseFloat(dayData.mood1);
      count++;
    }
    if (dayData.mood2) {
      totalMood += parseFloat(dayData.mood2);
      count++;
    }
  });

  return count > 0 ? totalMood / count : 0;
};
