import React from "react";
import heartIconbw from "../assets/heartIconbw.svg";
import TodaysResultGraph from "../../src/assets/TodaysResultGraph.png";
import { Link } from "react-router-dom";
import ShareIcon from "../../src/assets/ShareIcon.svg";
import CtaButton from "./CtaButton";
//hardcoded values for now

const TodaysResultCard = ({ heartRate }) => {
  return (
    <>
      <div className="rounded-[16px] shadow-TodaysResultCard bg-base_white ">
        <div className="flex p-5 gap-x-[29px] border-b border-[#E5E7EB] ">
          <div className="flex-col">
            <div className="flex">
              <img src={heartIconbw} alt="heart icon" className="mr-[6px]" />
              <p className="body-small text-light_bg_secondary">Heart Rate</p>
            </div>
            <div className="flex items-end gap-x-[6px]">
              <p className="ph-display-number text-success_500">
                {heartRate || 65}
              </p>
              <p className="body-small text-light_bg_secondary">BPM</p>
            </div>
          </div>
          <div>
            <img src={TodaysResultGraph} alt="Graph" />
          </div>
        </div>
        <div className="p-5">
          <p className=" text-base leading-[22.4px] font-inter text-light_bg_secondary">
            Your resting heart rate indicates a good heart health. For even
            better health, manage to further lower it down.
          </p>
          <h2 className="mt-6 heading-small text-light_bg_primary">
            Our advice
          </h2>
          <p className="body-small text-light_bg_secondary mt-1">
            Workout with moderate intensity for 150 minutes/week. While you do
            so, your activity heart rate between{" "}
            <span className="body-small text-light_bg_primary">
              116-138 beats per minute
            </span>{" "}
            would be a safe zone.
          </p>
          <div className="flex gap-2 mt-5">
            <button className=" body-large text-light_bg_primary flex-grow py-3 rounded-[8px] border border-[#D0D5DD] bg-base_white shadow-xs ">
              Learn more
            </button>
            <Link>
              <img src={ShareIcon} alt="Share" className=" rounded-[8px]"></img>
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full max-w-[393px] mx-auto fixed bottom-0 left-0 right-0 px-5 pb-5 mt-4">
        <CtaButton ButtonText="Go to home" LinkTo="/home" />
      </div>
    </>
  );
};

export default TodaysResultCard;
