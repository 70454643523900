import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout';
import chevronLeft from '../../assets/chevronLeft.svg';
import CtaButton from '../../components/CtaButton';
import { Link, useNavigate } from 'react-router-dom';
import PreOnboarding from '../../assets/PreOnboarding.svg';
import axios from 'axios';
import calendarIcon from '../../assets/calendarIcon.svg';

const UserDetails = () => {
  const [formData, setFormData] = useState({
    firstname: '',
    dob: '',
    gender: '',
    height: '',
    weight: '',
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [dobError, setDobError] = useState(false); // New state for DOB error
  const navigate = useNavigate();

  useEffect(() => {
    const storedDob = localStorage.getItem('dob');
    if (storedDob) {
      setFormData((prevData) => ({ ...prevData, dob: storedDob }));
    }
  }, []);

  useEffect(() => {
    const { firstname, dob, gender, height, weight } = formData;

    const isValidDOB = validateDOB(dob);
    const isValidHeight = height > 0 && height < 200; // reasonable height range
    const isValidWeight = weight > 0 && weight < 150; // reasonable weight range

    if (
      firstname &&
      isValidDOB &&
      gender &&
      isValidHeight &&
      isValidWeight
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [formData]);

  const validateDOB = (dob) => {
    // Return true (no error) if the dob is empty (i.e. not filled yet)
    if (!dob) {
      setDobError(false);
      return true;
    }

    const [day, month, year] = dob.split('/').map(Number);
    if (!day || !month || !year) {
      setDobError(true);
      return false;
    }

    const userDOB = new Date(year, month - 1, day);
    const today = new Date();
    const age = today.getFullYear() - userDOB.getFullYear();

    const isOldEnough =
      age > 18 ||
      (age === 18 &&
        today >=
          new Date(
            today.getFullYear(),
            userDOB.getMonth(),
            userDOB.getDate()
          ));

    const isTooOld = age <= 100;
    const isValid = isOldEnough && isTooOld;

    setDobError(!isValid); // Set error only if DOB is invalid and filled
    return isValid;
  };

  // Format date as DD/MM/YYYY
  const formatDate = (input) => {
    const cleanedInput = input.replace(/[^\d]/g, '');
    let formattedDate = '';

    for (let i = 0; i < cleanedInput.length && i < 8; i++) {
      if (i === 2 || i === 4) {
        formattedDate += '/';
      }
      formattedDate += cleanedInput[i];
    }

    return formattedDate;
  };

  // Handle text input date change
  const handleDateInput = (e) => {
    const input = formatDate(e.target.value);
    setFormData({ ...formData, dob: input });
  };

  // Handle calendar input date change
  const handleDateChange = (e) => {
    const date = new Date(e.target.value).toLocaleDateString('en-GB'); // Format as DD/MM/YYYY
    setFormData({ ...formData, dob: date });
  };

  // Handle generic input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle gender selection
  const handleGenderClick = (gender) => {
    setFormData({ ...formData, gender });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) {
      alert('Please fill out all fields before submitting.');
      return;
    }
    navigate('/intro-scan', { replace: true });

    try {
      const dataToSend = {
        ...formData,
        height: Number(formData.height),
        weight: Number(formData.weight),
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/users`,
        dataToSend,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('response', response);
      localStorage.setItem('userId', response.data._id);
      localStorage.setItem('dob', formData.dob);
    } catch (error) {
      console.error('Error submitting form:', error);
      navigate('/create-account/user-details', { replace: true });
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${PreOnboarding})`,
        backgroundRepeat: 'repeat-x',
        position: 'relative',
        backgroundSize: 'contain',
      }}
    >
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: '200px',
          background:
            'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)',
        }}
      />
      <Layout>
        <form onSubmit={handleSubmit}>
          <div className="flex fixed bg-transparent top-0 left-0 right-0 px-5 items-center">
            <Link to="/">
              <div className="w-6 h-6 p-[5px] flex justify-center items-center bg-white rounded-full">
                <img
                  className="min-h-[18px] min-w-[18px]"
                  src={chevronLeft}
                  alt="Back Button"
                />
              </div>
            </Link>
            <p className="text-light_bg_primary opacity-65 heading-large my-5 mx-auto justify-center ">
              Let’s get to know you
            </p>
            <div className="w-6 h-6"></div>
          </div>
          <h1 className="font-inter text-[28px] font-normal leading-[33.6px] tracking-[-0.28px] text-light_bg_primary opacity-85 mt-20">
            Help us give you accurate results
          </h1>
          <h2 className="text-sm-regular text-[#142A37] opacity-[0.50] mt-2">
            Every heart is unique. A few quick details go a long way
            in making your heart checks more accurate and meaningful.
          </h2>
          <div className="flex flex-col mt-10">
            <div className="flex justify-center items-start gap-x-4">
              <div className="flex flex-col">
                <label
                  htmlFor="firstname"
                  className="text-sm-medium text-light_bg_primary opacity-65 mb-[6px]"
                >
                  First Name
                </label>
                <div>
                  <input
                    type="text"
                    name="firstname"
                    id="firstname"
                    value={formData.firstname}
                    onChange={handleInputChange}
                    className="text-md-regular hover:shadow-user_details_input hover:border-secondary_500 hover:border-[1px] bg-[#ffffff99] rounded-[12px] border-[#00000012] border-[1px] text-gray_800 placeholder-light_bg_primary_0.35 flex-1 min-w-0 block w-full px-3 py-3 pl-[10px] focus:outline-none"
                    placeholder="Rohan"
                    required
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="dob"
                  className="text-sm-medium text-light_bg_primary opacity-65 mb-[6px]"
                >
                  Date Of Birth
                </label>
                <div className="relative">
                  <input
                    type="text"
                    name="dob"
                    id="dob"
                    value={formData.dob}
                    onChange={handleDateInput}
                    className={`text-md-regular z-10 ${
                      dobError
                        ? 'shadow-user_details_input_error border-[#F97066] border-[1px]'
                        : 'hover:shadow-user_details_input hover:border-secondary_500 hover:border-[1px]'
                    } bg-[#ffffff99] rounded-[12px] border-[#00000012] border-[1px] text-gray_800 placeholder-light_bg_primary_0.35 flex-1 min-w-0 block w-full px-3 py-3 pl-[10px] pr-[40px] focus:outline-none`}
                    placeholder="DD/MM/YYYY"
                    required
                  />
                  <img
                    src={calendarIcon}
                    alt="Calendar Icon"
                    className="absolute max-h-5 max-w-5 right-3 top-1/2 transform -translate-y-1/2 pointer-events-none"
                  />
                  <input
                    type="date"
                    onChange={handleDateChange}
                    style={{ zIndex: 1 }}
                    className="absolute max-h-5 max-w-5 right-3 top-1/2 transform -translate-y-1/2 opacity-0 cursor-pointer"
                  />
                </div>
                {dobError && (
                  <p className="text-sm-medium text-[#F97066] mt-2">
                    invalid date
                  </p>
                )}
              </div>
            </div>
            <label className="text-sm-medium text-light_bg_primary opacity-65 mt-4">
              Gender
            </label>
            <div className="flex justify-between gap-x-4 items-center mt-[6px] min-w-0">
              <button
                type="button"
                onClick={() => handleGenderClick('Male')}
                className={`font-inter text-light_bg_primary text-opacity-85 text-[16px] font-normal leading-[24px] min-w-0 flex justify-center rounded-full border-[#00000012] border-[1px] px-9 py-2 ${
                  formData.gender === 'Male'
                    ? 'bg-secondary_500 text-white'
                    : 'bg-[#ffffff99] text-gray_500'
                }`}
              >
                Male
              </button>
              <button
                type="button"
                onClick={() => handleGenderClick('Female')}
                className={`font-inter text-light_bg_primary text-opacity-85 text-[16px] font-normal leading-[24px] min-w-0 flex justify-center rounded-full border-[#00000012] border-[1px] px-9 py-2 ${
                  formData.gender === 'Female'
                    ? 'bg-secondary_500 text-white'
                    : 'bg-[#ffffff99] text-gray_500'
                }`}
              >
                Female
              </button>
              <button
                type="button"
                onClick={() => handleGenderClick('Other')}
                className={`font-inter text-light_bg_primary text-opacity-85 text-[16px] font-normal leading-[24px] min-w-0 flex justify-center rounded-full border-[#00000012] border-[1px] px-9 py-2 ${
                  formData.gender === 'Other'
                    ? 'bg-secondary_500 text-white'
                    : 'bg-[#ffffff99] text-gray_500'
                }`}
              >
                Other
              </button>
            </div>
            <div className="flex justify-center items-center gap-x-4 mt-6 pb-36">
              <div className="flex flex-col">
                <label
                  htmlFor="height"
                  className="text-sm-medium text-light_bg_primary opacity-65 mb-[6px]"
                >
                  Height
                </label>
                <div className="relative">
                  <input
                    type="number"
                    name="height"
                    id="height"
                    value={formData.height}
                    onChange={handleInputChange}
                    className="text-md-regular hover:shadow-user_details_input hover:border-secondary_500 hover:border-[1px] bg-[#ffffff99] rounded-[12px] border-[#00000012] border-[1px] text-gray_800 placeholder-light_bg_primary_0.35 flex-1 min-w-0 block w-full px-3 py-3 pl-[10px] focus:outline-none"
                    placeholder="170"
                    required
                  />
                  <span className="text-md-regular absolute right-3 top-1/2 transform -translate-y-1/2 text-light_bg_primary opacity-65">
                    cm
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="weight"
                  className="text-sm-medium text-light_bg_primary opacity-65 mb-[6px]"
                >
                  Weight
                </label>
                <div className="relative">
                  <input
                    type="number"
                    name="weight"
                    id="weight"
                    value={formData.weight}
                    onChange={handleInputChange}
                    className="text-md-regular hover:shadow-user_details_input hover:border-secondary_500 hover:border-[1px] bg-[#ffffff99] rounded-[12px] border-[#00000012] border-[1px] text-gray_800 placeholder-light_bg_primary_0.35 flex-1 min-w-0 block w-full px-3 py-3 pl-[10px] focus:outline-none"
                    placeholder="75"
                    required
                  />
                  <span className="text-md-regular absolute right-3 top-1/2 transform -translate-y-1/2 text-light_bg_primary opacity-65">
                    kg
                  </span>
                </div>
              </div>
            </div>
            <div
              className={`w-full max-w-[393px] mx-auto fixed bottom-0 left-0 right-0 px-5 pb-5 mt-4 ${
                isFormValid ? 'opacity-100' : 'opacity-50'
              }`}
            >
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  height: '100px',
                  background:
                    'linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)',
                }}
                className="z-[-1]"
              />
              <button
                onClick={handleSubmit}
                className="w-full"
                disabled={!isFormValid}
              >
                <CtaButton ButtonText="Submit" LinkTo="" />
              </button>
            </div>
          </div>
        </form>
      </Layout>
    </div>
  );
};

export default UserDetails;
