import React, { useState, useEffect, useRef } from 'react';
import BottomNav from '../../components/BottomNav';
import Layout from '../../components/Layout';
import HeartRate from '../../components/HeartRate';
import ResultPRqCard from '../../components/ResultPRqCard';
import HeartAgeCard from '../../components/HeartAgeCard';
import HeartEnergy from '../../components/HeartEnergy';
import DiabetesCard from '../../components/DiabetesCard';
import SleepApneaCard from '../../components/SleepApneaCard';
import HeartRateOverviewCard from '../../components/HeartRateOverviewCard';
import RespirationRate from '../../components/RespirationRate';
import OxygenSaturation from '../../components/OxygenSaturation';
import NoteSummary from '../../assets/NoteSummary.svg';
import CheckGray from '../../assets/CheckGray.svg';
import BloodPressureCard from '../../components/BloodPressureCard';
import axios from 'axios';
import MoodCard from '../../components/MoodCard';
import Loader from '../../utils/Loader';
import HeartRateVariability from '../../components/HeartRateVariability';
import CtaButton from '../../components/CtaButton';
import resultImage1 from '../../assets/Resultimage1.svg';
import resultImage2 from '../../assets/Resultimage2.svg';
import LockedGaugeGraph from '../../components/LockedGaugeCard';
import LockedLinearCard from '../../components/LockedLinearCard';
import { sendAmplitudeData } from '../../utils/amplitude';
import { useNavigate } from 'react-router-dom';

const Result = () => {
  const [data, setData] = useState(null);
  const userId = localStorage.getItem('userId');
  const day = Number(localStorage.getItem('Day'));
  const [journeySelected, setJourneySelected] = useState(true);
  const [lastScanResult, setLastScanResult] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [timeStamps, setTimeStamps] = useState({});
  const [heartRates, setHeartRates] = useState([]);
  const [profilerCompleted, setProfilerCompleted] = useState(false);
  const contentRef = useRef(null);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [dayValue, setDayValue] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/journeys/${userId}`
        );
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };

    fetchData();
  }, [userId]);

  useEffect(() => {
    const fetchScanDetails = async () => {
      const userId = localStorage.getItem('userId');
      if (!userId) {
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/scans/${userId}`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        if (response.data && response.data.length > 0) {
          const latestScan = response.data[response.data.length - 1];
          setLastScanResult(latestScan);

          //get last 5 scans
          const last5Scans = response.data.slice(-5);
          const heartRateData = last5Scans.map((scan) => ({
            date: new Date(scan.entry_time).toISOString(),
            value: scan.vitals.heart_rate,
          }));
          setHeartRates(heartRateData);
        }
      } catch (error) {
        console.error('Error fetching scan details:', error);
      }
    };

    fetchScanDetails();
  }, []);

  useEffect(() => {
    const savedTimeStamps = localStorage.getItem('timeStamps');
    if (savedTimeStamps) {
      setTimeStamps(JSON.parse(savedTimeStamps));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('timeStamps', JSON.stringify(timeStamps));
  }, [timeStamps]);

  useEffect(() => {
    const profilerCompletedStatus = localStorage.getItem('profilerCompleted');
    setProfilerCompleted(profilerCompletedStatus === 'true');
  }, []);

  const getTimeStamp = (key) => {
    if (!timeStamps[key]) {
      const now = new Date();
      const optionsDate = { month: 'long', day: 'numeric' };
      const optionsTime = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      };

      const formattedDate = now.toLocaleDateString('en-US', optionsDate);
      const formattedTime = now.toLocaleTimeString('en-US', optionsTime);

      const newTimeStamp = `${formattedDate}, ${formattedTime}`;
      setTimeStamps((prev) => ({ ...prev, [key]: newTimeStamp }));
      return newTimeStamp;
    }
    return timeStamps[key];
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const content = contentRef.current;
    if (content) {
      content.style.maxHeight = isExpanded
        ? `${content.scrollHeight}px`
        : '0px';
    }
  }, [isExpanded]);

  const journeyCompleteHandler = () => {
    localStorage.setItem('profilerCompleted', 'true');
    setProfilerCompleted(true);
  };

  const handleSubscribe = async () => {
    setLoading(true);
    const userId = localStorage.getItem('userId');

    if (!userId) {
      console.error('User ID not found in local storage.');
      setLoading(false);
      return;
    }

    try {
      await Promise.all([
        axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/questionnaires/${userId}`
        ),
        axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/journeys/${userId}`
        ),
      ]);

      console.log(
        'Questionnaire, Journey created, and Day value set successfully'
      );

      localStorage.setItem('Day', '1');
      setDayValue('1');

      setLoading(false);
      navigate('/intro-questionnaire');

      sendAmplitudeData('RESTING_HEART_SIGN_UP');
    } catch (error) {
      console.error('Error creating questionnaire or journey:', error);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (day <= 1) {
    return (
      <div className="bg-gray_neutral_50 h-screen pb-12">
        <Layout>
          <h3 className="text-center heading-large text-light_bg_secondary mt-4 mb-4">
            Results
          </h3>
          <p className="text-gray_500 text-sm-regular">
            All of your health results from your frequent scans are present here
          </p>
          <div className="flex flex-col justify-center items-center mt-20">
            <img
              src={resultImage1}
              alt="result"
              className="max-w-[215px] max-h-[77px]"
            ></img>
            <img
              src={resultImage2}
              alt="result"
              className="max-w-[215px] max-h-[77px]"
            ></img>
          </div>
          <p className="text-sm-regular text-light_bg_secondary text-center">
            Looks like you need to answer a few more questions before we can
            show you todays result{' '}
          </p>
          <div onClick={handleSubscribe} className="mt-4">
            <CtaButton LinkTo="" ButtonText="Get your heart care report" />
          </div>
        </Layout>
        <BottomNav />
      </div>
    );
  }

  if (!data) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <div className="bg-gray_neutral_50 h-screen pb-12">
      <Layout>
        <div className="pb-24">
          {day === 6 ? (
            <h3 className="text-center heading-large text-light_bg_secondary mt-4 mb-4">
              Final report
            </h3>
          ) : (
            <>
              <h3 className="text-center heading-large text-light_bg_secondary mt-4 mb-4">
                Results
              </h3>
              <p className="text-gray_500 text-sm-regular">
                All of your health results from your frequent scans are present
                here
              </p>
            </>
          )}
          {day === 6 && (
            <div className="flex items-center justify-center gap-x-2 bg-[#f3f4f6] p-1 rounded-[12px]">
              <button
                className={`w-full py-2 px-4 rounded-[8px] transition-all duration-300 ease-in-out ${
                  journeySelected ? 'bg-white' : ''
                } ${
                  !journeySelected
                    ? 'shadow-none'
                    : 'shadow-final_report_button'
                }`}
                onClick={() => setJourneySelected(true)}
              >
                Journey report
              </button>
              <button
                className={`w-full py-2 px-4 rounded-[8px] transition-all duration-300 ease-in-out ${
                  !journeySelected ? 'bg-white' : ''
                } ${
                  journeySelected ? 'shadow-none' : 'shadow-final_report_button'
                }`}
                onClick={() => setJourneySelected(false)}
              >
                Vitals overview
              </button>
            </div>
          )}

          {day === 6 && journeySelected && (
            <div>
              <div
                className={`relative p-4 bg-base_white rounded-[16px] mt-6 shadow-journey_card transition-all duration-300 ease-in-out transform

                `}
                style={{
                  transition: 'all 0.6s cubic-bezier(0.25, 0.1, 0.25, 1)',
                }}
              >
                <div>
                  <div className="flex">
                    <img src={NoteSummary} alt="Note" />
                    <h4 className="heading-large text-light_bg_primary ml-[6px]">
                      Summary
                    </h4>
                  </div>
                  <p className="text-light_bg_secondary body-small mt-2 mb-4">
                    Cardiovascular health is good overall. Heart and lung
                    function is positive. Blood pressure is elevated and needs
                    monitoring.
                  </p>
                  <div
                    ref={contentRef}
                    className="overflow-hidden transition-all duration-300 ease-in-out"
                    style={{
                      maxHeight: isExpanded ? '500px' : '0px',
                      transition:
                        'max-height 0.3s cubic-bezier(0.25, 0.1, 0.25, 1)',
                    }}
                  >
                    <p className="body-large text-light_bg_secondary mt-2 flex items-start">
                      <img
                        src={CheckGray}
                        alt="check"
                        className="bg-light_bg_primary opacity-15 rounded-full mt-1 p-1 mr-2"
                      />
                      Heart rate and HRV show positive trends. Your
                      cardiovascular health is in good shape.
                    </p>
                    <p className="body-large text-light_bg_secondary mt-2 flex items-start">
                      <img
                        src={CheckGray}
                        alt="check"
                        className="bg-light_bg_primary opacity-15 rounded-full mt-1 p-1 mr-2"
                      />
                      Blood pressure has been fluctuating on the higher side.
                    </p>
                    <p className="body-large text-light_bg_secondary mt-2 flex items-start">
                      <img
                        src={CheckGray}
                        alt="check"
                        className="bg-light_bg_primary opacity-15 rounded-full mt-1 p-1 mr-2"
                      />
                      Lungs are showing positive signs due to optimum CRR.
                    </p>
                  </div>
                </div>
                <div className="absolute bottom-2 right-4">
                  <button
                    onClick={toggleExpand}
                    className={`border font-bold text-light_bg_primary text-[11px] opacity-85 h-6 w-6 rounded-full flex items-center justify-center transition-colors duration-300 ${
                      isExpanded
                        ? ' bg-light_bg_primary text-white'
                        : 'border-[#E5E7EB] rotate-45'
                    }`}
                  >
                    {isExpanded ? '✕' : '✕'}
                  </button>
                </div>
              </div>

              <h3 className="font-inter text-[28px] leading-[33.6px] tracking-[-0.28px] mt-12 text-light_bg_primary">
                Journey report
              </h3>
              <p className=" text-light_bg_secondary mt-2">
                All of your health results from your 5 days of scanning
              </p>
            </div>
          )}

          {day === 6 && !journeySelected && (
            <div>
              <div className="relative p-4 bg-base_white rounded-[16px] mt-6 shadow-journey_card transition-all duration-300 ease-in-out">
                <div>
                  <div className="flex">
                    <img src={NoteSummary} alt="Note" />
                    <h4 className="heading-large text-light_bg_primary ml-[6px]">
                      Summary
                    </h4>
                  </div>
                  <p className="text-light_bg_secondary body-small mt-2 mb-4">
                    Cardiovascular health is good overall. Heart and lung
                    function is positive. Blood pressure is elevated and needs
                    monitoring.
                  </p>
                  {isExpanded && (
                    <div className="mt-4 transition-all duration-300 ease-in-out">
                      <p className="body-large text-light_bg_primary opacity-85 mt-2 flex items-center">
                        <img
                          src={CheckGray}
                          alt="check"
                          className="bg-light_bg_primary opacity-15 rounded-full p-1 mr-2"
                        />
                        Heart rate and HRV show positive trends. Your
                        cardiovascular health is in good shape.
                      </p>
                      <p className="body-large text-light_bg_primary opacity-85 mt-2 flex items-center">
                        <img
                          src={CheckGray}
                          alt="check"
                          className="bg-light_bg_primary opacity-15 rounded-full p-1 mr-2"
                        />
                        Blood pressure has been fluctuating on the higher side.
                      </p>
                      <p className="body-large text-light_bg_primary opacity-85 mt-2 flex items-center">
                        <img
                          src={CheckGray}
                          alt="check"
                          className="bg-light_bg_primary opacity-15 rounded-full p-1 mr-2"
                        />
                        Lungs are showing positive signs due to optimum CRR.
                      </p>
                    </div>
                  )}
                </div>
                <div className="absolute bottom-2 right-4">
                  <button
                    onClick={toggleExpand}
                    className={`border text-light_bg_primary opacity-85 h-6 w-6 rounded-full flex items-center justify-center transition-colors duration-300 ${
                      isExpanded ? 'bg-black text-white' : 'border-[#E5E7EB]'
                    }`}
                  >
                    {isExpanded ? '✕' : '+'}
                  </button>
                </div>
              </div>
              <h3 className="font-inter text-[28px] leading-[33.6px] tracking-[-0.28px] mt-12 text-light_bg_primary">
                Vitals overview
              </h3>
              <p className=" text-light_bg_secondary mt-2">
                Here are the readings of your body’s vital signatures based on
                the scanning done in the past 5 days
              </p>
            </div>
          )}

          {day === 6 && !journeySelected && (
            <HeartRateOverviewCard heartRates={heartRates} />
          )}
          {day === 6 && !journeySelected && lastScanResult && (
            <div className="flex flex-col space-y-1 w-full">
              <div className="flex justify-center items-stretch w-full gap-3">
                <div className="flex-1 min-w-0">
                  <OxygenSaturation
                    oxygenSaturation={lastScanResult.vitals.oxy_sat_prcnt}
                  />
                </div>
                <div className="flex-1 min-w-0">
                  <HeartRateVariability />
                </div>
              </div>
              <div className="flex justify-center items-stretch w-full gap-3">
                <div className="flex-1 min-w-0">
                  <BloodPressureCard
                    bloodPressureDia={lastScanResult.vitals.bp_dia}
                    bloodPressureSys={lastScanResult.vitals.bp_sys}
                  />
                </div>
                <div className="flex-1 min-w-0">
                  <RespirationRate
                    respirationRate={lastScanResult.vitals.resp_rate}
                  />
                </div>
              </div>
            </div>
          )}

          {day === 6 && !journeySelected && lastScanResult && (
            <div className="flex ">
              <MoodCard />
            </div>
          )}

          {data?.SleepApneaRiskScore && journeySelected && (
            <h3 className="heading-small text-light_bg_secondary mt-6">
              {getTimeStamp('SleepApneaCard')}
            </h3>
          )}
          {data?.SleepApneaRiskScore && journeySelected && (
            <SleepApneaCard SleepApneaRiskScore={data.SleepApneaRiskScore} />
          )}
          {data?.DiabetesRiskScore && journeySelected && (
            <h3 className="heading-small text-light_bg_secondary mt-6">
              {getTimeStamp('DiabetesCard')}
            </h3>
          )}
          {data?.DiabetesRiskScore && journeySelected && (
            <DiabetesCard DiabetesRiskScore={data.DiabetesRiskScore} />
          )}
          {data?.HeartEnergy && journeySelected && (
            <h3 className="heading-small text-light_bg_secondary mt-6">
              {getTimeStamp('HeartEnergy')}
            </h3>
          )}
          {data?.HeartEnergy && journeySelected && (
            <HeartEnergy HeartEnergy={Math.floor(data.HeartEnergy)} />
          )}
          {data?.HeartAge && journeySelected && (
            <h3 className="heading-small text-light_bg_secondary mt-6">
              {getTimeStamp('HeartAge')}
            </h3>
          )}
          {data?.HeartAge && journeySelected && (
            <HeartAgeCard HeartAge={data.HeartAge} />
          )}
          {data?.prq && journeySelected && (
            <h3 className="heading-small text-light_bg_secondary mt-6">
              {getTimeStamp('prq')}
            </h3>
          )}
          {data?.prq && journeySelected && <ResultPRqCard prq={data.prq} />}
          {data?.HR && journeySelected && (
            <h3 className="heading-small text-light_bg_secondary mt-6">
              {getTimeStamp('HR')}
            </h3>
          )}
          {data?.HR && journeySelected && <HeartRate heartRate={data.HR} />}

          {day < 6 && (
            <p className="heading-small text-light_bg_secondary mt-6 mb-4">
              Come back everyday to unlock more results
            </p>
          )}

          <>
            {!data?.HR && (
              <LockedLinearCard CardName="Heart Rate" Icon={3} day={2} />
            )}

            {!data?.prq && (
              <LockedLinearCard
                CardName="Cardiorespiratory rhythm"
                Icon={3}
                day={2}
              />
            )}

            {!data?.HeartAge && (
              <LockedLinearCard CardName="Heart age" Icon={2} day={3} />
            )}

            {!data?.HeartEnergy && (
              <LockedLinearCard CardName="Heart energy" Icon={1} day={4} />
            )}

            {!data?.DiabetesRiskScore && (
              <LockedGaugeGraph
                Score={data.DiabetesRiskScore}
                CardName="Diabetes"
                Icon={2}
                day={4}
              />
            )}

            {!data?.SleepApneaRiskScore && (
              <LockedGaugeGraph
                Score={data.SleepApneaRiskScore}
                CardName="Sleep Apnea"
                Icon={1}
                day={5}
              />
            )}
          </>
        </div>
      </Layout>

      {day > 5 && profilerCompleted === false ? (
        <div
          className="flex justify-center ml-5 mr-5"
          onClick={journeyCompleteHandler}
        >
          <CtaButton LinkTo="/profiler-success" ButtonText="Complete journey" />
        </div>
      ) : (
        <BottomNav />
      )}
    </div>
  );
};

export default Result;
