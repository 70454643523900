import React from "react";
import { Navigate } from "react-router-dom";

const LoginAuthWrapper = ({ children }) => {
  const login = localStorage.getItem("Login");

  if (login === "false" || login == null) {
    return <Navigate to="/" />;
  }

  return children;
};

export default LoginAuthWrapper;
