import React from "react";
import Layout from "../../components/Layout";
import introScanimage from "../../assets/IntroScanImage.jpeg";
import cameraIcon from "../../assets/cameraIcon.svg";
import scanStepIcon from "../../assets/scanStepIcon.svg";
import CtaButton from "../../components/CtaButton";

const CameraPermission = () => {
  return (
    <Layout>
      <div className="flex-col items-center">
        <div className="flex justify-center">
          <h1 className=" mt-5 mb-5 text-gray_neutral_800 text-[20px] font-inter font-[500] leading-5 tracking-[0.28]">
            Health Scan
          </h1>
        </div>

        <div className="mt-5">
          <img
            src={introScanimage}
            alt="Scan"
            className="opacity-70 rounded-2xl"
          />
        </div>
        <h1 className="mt-12 text-[16px] font-normal font-inter leading-6 uppercase text-gray_400">
          How to scan
        </h1>
      </div>
      <div className="flex mt-6">
        <img src={cameraIcon} alt="heart" className=" shadow-icon mr-4"></img>
        <div className="flex-col">
          <h3 className="text-md-medium text-gray_neutral_700 mb-1">
            Allow your camera’s access
          </h3>
          <h4 className="text-sm-regular text-gray_neutral_500">
            Please give permission to access your camera since that’ll help the
            app in scanning your vitals.
          </h4>
        </div>
      </div>
      <hr className="mt-4 mb-4 text-gray_neutral_200" />
      <div className="flex ">
        <img src={scanStepIcon} alt="mood" className=" shadow-icon mr-4"></img>
        <div className="flex-col">
          <h3 className="text-md-medium text-gray_neutral_700 mb-1">
            Follow the steps
          </h3>
          <h4 className="text-sm-regular text-gray_neutral_500">
            To successfully complete the scan please follow the steps in the
            next screens
          </h4>
        </div>
      </div>
      <div className="w-full max-w-[393px] mx-auto fixed bottom-0 left-0 right-0 px-5 pb-5">
        <p className="text-xs-regular text-gray_400 text-center mb-3 mx-16">
          By continuing, you agree to allow Philips to access your phone’s
          Camera
        </p>
        <div>
          <CtaButton
            ButtonText="Allow and Continue"
            LinkTo="/intro-scan/scan"
          />
        </div>
      </div>
    </Layout>
  );
};

export default CameraPermission;
