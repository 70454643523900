import React from "react";

const RectangleProgressBar = ({ color, stroke, progress }) => {
  const width = 270;
  const height = 224;
  const radius = 14;
  const pathLength = 2 * (width + height) - 8 * radius + 2 * Math.PI * radius;
  const progressRatio =
    pathLength * (1 - Math.max(Math.min(progress, 100), 0) / 100);

  // SVG path for a rounded rectangle
  const path = `
    M ${radius + stroke / 2} ${stroke / 2}
    H ${width - radius + stroke / 2}
    A ${radius} ${radius} 0 0 1 ${width + stroke / 2} ${radius + stroke / 2}
    V ${height - radius + stroke / 2}
    A ${radius} ${radius} 0 0 1 ${width - radius + stroke / 2} ${
    height + stroke / 2
  }
    H ${radius + stroke / 2}
    A ${radius} ${radius} 0 0 1 ${stroke / 2} ${height - radius + stroke / 2}
    V ${radius + stroke / 2}
    A ${radius} ${radius} 0 0 1 ${radius + stroke / 2} ${stroke / 2}
  `;

  return (
    <div className="flex">
      <svg
        style={{
          width: "100%",
          height: "100%",
          marginTop: "5px",
          position: "absolute",
          top: 0,
          right: 5,
          transform: "scaleX(-1)",
          pointerEvents: "none",
        }}
      >
        {/* Gray background path */}
        <path
          style={{
            fill: "transparent",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            opacity: 0.12,
          }}
          stroke="#1F2A37"
          strokeWidth={stroke}
          d={path}
        />

        {/* Green progress path */}
        <path
          style={{
            strokeDashoffset: `${progressRatio}`,
            fill: "transparent",
            transition: "stroke-dashoffset 0.3s linear",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
          stroke={color}
          strokeWidth={stroke}
          strokeDasharray={pathLength}
          d={path}
        />
      </svg>
    </div>
  );
};

export default RectangleProgressBar;
