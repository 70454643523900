import React, { useState, useEffect } from 'react';
import HeartPulsebw from '../assets/HeartPulsebw.svg';
import HeartRateModal from './HeartRateModal';

// Simulating a database fetch function
const fetchHeartRateText = (heartRate) => {
  if (heartRate < 56) {
    return {
      resultText: 'Your heart rate is lower than normal.',
      guidanceText:
        'Your heart rate is lower than normal. You should seek medical attention if you experience fainting, dizziness, or shortness of breath. Avoid stress and ensure proper sleep.',
    };
  } else if (heartRate > 80) {
    return {
      resultText: 'Your heart rate is higher than normal.',
      guidanceText:
        'Your resting heart rate is higher than normal. Seek medical attention if you experience fainting, dizziness, or shortness of breath. Maintain a healthy lifestyle to restore a normal heart rate.',
    };
  } else {
    return {
      resultText: 'Congrats! Your heart rate is normal.',
      guidanceText:
        'You have a healthy heart! Keep it up and stay fit and healthy by exercising regularly.',
    };
  }
};

const HeartRate = ({ heartRate, label = 'Resting heart rate' }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resultText, setResultText] = useState('');
  const [guidanceText, setGuidanceText] = useState('');

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const mockHeartRate = 65;
  const displayedHeartRate = heartRate || mockHeartRate;

  // Graph logic
  const minRate = 40;
  const maxRate = 100;
  const normalMin = 56;
  const normalMax = 80;
  const rate = displayedHeartRate;

  const calculatePosition = (rate) => {
    const clampedRate = Math.max(minRate, Math.min(maxRate, rate));
    return ((clampedRate - minRate) / (maxRate - minRate)) * 100;
  };

  const getStatusAndColor = (rate) => {
    if (rate >= normalMin && rate <= normalMax) {
      return { status: 'All Good', color: '#32D583' };
    } else if (rate < normalMin - 5 || rate > normalMax + 15) {
      return { status: 'Heads up', color: '#F97066' };
    } else {
      return { status: 'Borderline', color: '#FDB022' };
    }
  };

  const position = calculatePosition(rate);
  const { color } = getStatusAndColor(rate);

  useEffect(() => {
    const { resultText, guidanceText } = fetchHeartRateText(
      displayedHeartRate
    );
    setResultText(resultText);
    setGuidanceText(guidanceText);
  }, [displayedHeartRate]);

  return (
    <>
      <div
        className="bg-base_white p-3 rounded-[16px] shadow-result_card mt-2 border-[1px] border-light_bg_primary border-opacity-[0.12]"
        onClick={handleOpenModal}
      >
        <div className="flex items-center gap-[6px]">
          <img
            src={HeartPulsebw}
            alt="Heart Icon"
            className="w-[20px] h-[20px]"
          />
          <p className="text-sm-medium text-light_bg_primary text-opacity-95">
            {label}
          </p>
        </div>
        <div className="flex items-baseline gap-[6px] mt-1">
          <h3 className="display-number-medium text-light_bg_primary">
            {displayedHeartRate}
          </h3>
          <p className="text-[14px] font-normal leading-[21px] font-inter text-light_bg_secondary">
            Beats/min
          </p>
        </div>

        {/* Graph component */}
        <div className="relative mt-[14px] h-[8px] w-full">
          <div
            className="h-[6px] w-full rounded-full"
            style={{
              background:
                'linear-gradient(90deg, rgba(249, 112, 102, 0.80) 10.07%, rgba(253, 176, 34, 0.80) 24.25%, rgba(50, 213, 131, 0.80) 38.44%, rgba(50, 213, 131, 0.80) 59.03%, rgba(253, 176, 34, 0.80) 71.84%, rgba(249, 112, 102, 0.80) 88.77%)',
            }}
          ></div>

          <div
            className="absolute top-[-8px] z-20 border-white rounded-full flex items-center justify-center"
            style={{
              left: `${position}%`,
              transform: 'translateX(-50%)',
              backgroundColor: 'white',
            }}
          >
            <div
              className="h-[22px] w-[22px] border-[3px] border-white rounded-full flex items-center justify-center"
              style={{ backgroundColor: color }}
            >
              {color === '#32D583' ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.2294 0.265979C11.5902 0.620617 11.5902 1.1956 11.2294 1.55024L4.93737 7.73402C4.76408 7.90433 4.52906 8 4.284 8C4.03894 8 3.80392 7.90433 3.63063 7.73402L0.770633 4.92321C0.409789 4.56857 0.409789 3.99359 0.770633 3.63895C1.13148 3.28431 1.71652 3.28431 2.07737 3.63895L4.284 5.80763L9.92263 0.265979C10.2835 -0.0886595 10.8685 -0.0886596 11.2294 0.265979Z"
                    fill="#1F2A37"
                    fillOpacity="0.95"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2"
                  height="10"
                  viewBox="0 0 2 10"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.87356 0.896901C1.90397 0.374093 1.44713 0 0.998773 0C0.550415 0 0.093581 0.374093 0.123985 0.896901L0.342387 6.79375C0.345049 6.96414 0.413891 7.12699 0.534581 7.24768C0.657694 7.37079 0.824669 7.43995 0.998773 7.43995C1.17288 7.43995 1.33985 7.37079 1.46297 7.24768C1.58366 7.12699 1.6525 6.96414 1.65516 6.79375L1.87356 0.896901ZM0.990187 0.881763C0.990188 0.881765 0.990227 0.88172 0.990299 0.881626C0.990221 0.881715 0.990185 0.881762 0.990187 0.881763ZM1.00736 0.881763C1.00736 0.881762 1.00733 0.881715 1.00725 0.881626C1.00732 0.88172 1.00736 0.881765 1.00736 0.881763Z"
                    fill="#1F2A37"
                    fill-opacity="0.95"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.875 9.12529C1.875 8.64188 1.48312 8.25 0.999711 8.25C0.516303 8.25 0.124423 8.64188 0.124423 9.12529C0.124423 9.6087 0.516303 10.0006 0.999711 10.0006C1.48312 10.0006 1.875 9.6087 1.875 9.12529Z"
                    fill="#1F2A37"
                    fill-opacity="0.95"
                  />
                </svg>
              )}
            </div>
          </div>
          {/* Lines and labels for 56 and 80 BPM */}
          {[
            {
              value: normalMin,
              position: calculatePosition(normalMin),
            },
            {
              value: normalMax,
              position: calculatePosition(normalMax),
            },
          ].map(({ value, position }) => (
            <React.Fragment key={value}>
              <div
                className="absolute text-xs-medium text-light_bg_secondary bg-white px-[2px] top-[0px]"
                style={{ left: `${position}%` }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2"
                  height="12"
                  viewBox="0 0 2 12"
                  fill="none"
                >
                  <path
                    d="M1 1L1 11"
                    stroke="#1F2A37"
                    strokeOpacity="0.64"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div
                className="absolute ml-[3px] top-[12px] text-xs-medium text-light_bg_secondary transform -translate-x-1/2"
                style={{ left: `${position}%` }}
              >
                {value}
              </div>
            </React.Fragment>
          ))}
        </div>
        <p className="text-sm-regular text-light_bg_secondary mt-6">
          {resultText}
        </p>
      </div>
      <HeartRateModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        heartRate={displayedHeartRate}
        guidanceText={guidanceText}
      />
    </>
  );
};

export default HeartRate;
