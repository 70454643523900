import React, { useState } from "react";

const ProfilerDetailCard = ({
  image,
  title,
  description,
  expandedContent,
  expandable,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    if (expandable) {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <div>
      <div
        className={`relative p-4 ${
          !isExpanded ? "bg-gray_neutral_50" : "bg-[#FD6F8E] pb-10"
        } rounded-[16px] mt-4 transition-all duration-700 ease-in-out`}
      >
        {!isExpanded && (
          <div className="transition-opacity ease-in-out duration-500">
            <div className="flex items-center">
              <img src={image} alt={title} />
              <h4 className="heading-large text-light_bg_primary text-opacity-95 ml-[6px]">
                {title}
              </h4>
            </div>
            <p className="text-light_bg_secondary text-sm-regular mt-2 mb-4">
              {description}
            </p>
          </div>
        )}
        {isExpanded && (
          <div
            className={`mt-2 transition-transform duration-500 ease-in-out transform ${
              isExpanded
                ? "translate-y-0 opacity-100"
                : "translate-y-full opacity-0"
            }`}
          >
            {expandedContent}
          </div>
        )}
        {expandable && (
          <div
            className={`absolute ${
              isExpanded ? "bottom-2" : "bottom-2"
            } right-4`}
          >
            <button
              onClick={toggleExpand}
              className={`border text-light_bg_primary opacity-85 h-6 w-6 rounded-full flex items-center justify-center transition-colors duration-300 ${
                isExpanded
                  ? "bg-white text-black"
                  : "border-[#E5E7EB] rotate-45"
              }`}
            >
              {isExpanded ? "✕" : "✕"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfilerDetailCard;
