import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ScanSuccessTransition = () => {
  const [message, setMessage] = useState("");
  const [description, setDescription] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the 'day' value from localStorage
    const dayValue = localStorage.getItem("Day");

    // Determine the message and description to display based on the 'day' value
    let displayMessage;
    let messageDescription;
    if (!dayValue || dayValue === "null") {
      displayMessage = "You’ve completed your first heart scan!";
      messageDescription =
        "Share a few details with us, and we'll show you the results. ";
    } else {
      const day = parseInt(dayValue, 10);
      switch (day) {
        case 2:
        case 3:
          displayMessage =
            "You're one step closer to completing the diabetes risk assessment!";
          messageDescription = "";
          break;
        case 4:
          displayMessage =
            "You’ve completed the Diabetes risk assessment! We’ve compiled a detailed report based on your first scan.";
          messageDescription = "";
          break;
        case 5:
          displayMessage = "You’ve completed all 5 days!";
          messageDescription =
            "We’ve compiled a detailed report based on all of your scans.";
          break;
        default:
          displayMessage = `Completed day ${dayValue} scan.`;
          messageDescription = "";
          break;
      }
    }

    setMessage(displayMessage);
    setDescription(messageDescription);

    // Automatically route after 3000ms (3 seconds)
    const timer = setTimeout(() => {
      const day = parseInt(dayValue, 10);

      if (day === 4) {
        navigate("/intro-scan/scan/day4/mood1", { replace: true });
      } else {
        navigate("/intro-scan/scan/mood1", { replace: true });
      }
    }, 4800);

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="w-[124px] h-[124px] rounded-full flex items-center justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="165"
          height="166"
          viewBox="0 0 165 166"
          fill="none"
        >
          <g filter="url(#filter0_f_12948_22333)">
            <circle
              cx="82.4998"
              cy="83.0003"
              r="50.1057"
              fill="url(#paint0_linear_12948_22333)"
            />
          </g>
          <g filter="url(#filter1_f_12948_22333)">
            <circle
              cx="82.894"
              cy="82.9062"
              r="40"
              fill="url(#paint1_linear_12948_22333)"
            />
          </g>
          <path
            d="M100.666 71L76.8328 94.8333L65.9995 84"
            stroke="white"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <defs>
            <filter
              id="filter0_f_12948_22333"
              x="0.174042"
              y="0.67453"
              width="164.651"
              height="164.651"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="16.11"
                result="effect1_foregroundBlur_12948_22333"
              />
            </filter>
            <filter
              id="filter1_f_12948_22333"
              x="22.674"
              y="22.6863"
              width="120.44"
              height="120.44"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="10.11"
                result="effect1_foregroundBlur_12948_22333"
              />
            </filter>
            <linearGradient
              id="paint0_linear_12948_22333"
              x1="82.4998"
              y1="32.8945"
              x2="82.4998"
              y2="133.106"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FEA3B4" />
              <stop offset="1" stop-color="#F7B27A" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_12948_22333"
              x1="82.894"
              y1="42.9062"
              x2="82.894"
              y2="122.906"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FEA3B4" />
              <stop offset="1" stop-color="#F7B27A" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <h2 className="heading-large text-light_bg_primary ">Well done!</h2>
      <h2 className="text-sm-regular mx-12 text-light_bg_secondary mt-2 text-center">
        {message}
      </h2>
      {description && (
        <p className="text-center mx-12 text-light_bg_secondary text-sm-regular mt-4">
          {description}
        </p>
      )}
    </div>
  );
};

export default ScanSuccessTransition;
