import React, { useState } from 'react';
import Desktop1 from '../../assets/Desktop1.png';
import Desktop2 from '../../assets/Desktop2.png';
import DesktopQR from '../../assets/DesktopQR.png';

const DesktopHome = () => {
  // eslint-disable-next-line no-unused-vars
  const [copySuccess, setCopySuccess] = useState('');

  const handleShareClick = () => {
    const link = 'https://heartcheck.app';
    navigator.clipboard.writeText(link).then(
      () => {
        setCopySuccess('');
      },
      () => {
        setCopySuccess('');
      }
    );
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center mt-6">
        <div className="max-w-[137px] max-h-[38px]">
          <img src={Desktop1} alt="logo" className="" />
        </div>
        <p className="font-inter text-center mt-12 text-[24px] font-normal leading-[26.4px] tracking-[-0.24px]">
          Keep a check on your heart
        </p>
        <p className="mt-2 text-[#F63D68] text-center font-inter text-[48px] font-[500] leading-[52.8px] tracking-[-0.48px]">
          Try our app on your mobile
        </p>
        <p className="text-[#F63D68] text-center font-inter text-[48px] font-[500] leading-[52.8px] tracking-[-0.48px]">
          device for the best experience
        </p>
        <button
          className="text-center text-[#F9FAFB] font-inter text-[16px] font-[500] leading-[15.2px] py-[10px] px-4 bg-[#F63D68] rounded-[8px] flex justify-center items-center mt-9"
          onClick={handleShareClick}
        >
          Invite your family and friends
        </button>
      </div>
      <div className="flex gap-x-20 justify-center items-center bottom-0 left-0 right-0 absolute">
        <img
          src={Desktop2}
          alt="desktop"
          className="max-w-[465px] max-h-[531px]"
        />
        <div>
          <p className="font-inter text-[#111927] flex justify-center">
            Scan to get started
          </p>
          <img
            src={DesktopQR}
            alt="qr"
            className="max-w-[241px] max-h-[241px] mt-6"
          />
        </div>
      </div>
    </>
  );
};

export default DesktopHome;
