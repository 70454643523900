import React, { useState } from 'react';
import axios from 'axios';
import xclose from '../../assets/x-close.svg';
import flag from '../../assets/flag.svg';
import CtaButton from '../CtaButton';
import { useNavigate } from 'react-router-dom';
import { sendAmplitudeData } from '../../utils/amplitude';

const Whatsapp = ({ isOpen, onClose, userId }) => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState('');

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleSubmit = async () => {
    console.log('Form submitted, processing...');

    if (!phone) {
      console.error('Phone number is required');
      alert('Please enter your phone number');
      return;
    }

    let formattedPhone = phone.startsWith('91') ? phone : `91${phone}`;
    console.log('Formatted phone number:', formattedPhone);

    try {
      console.log('Sending PATCH request to update phone number...');
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/users/${userId}/phone`,
        { phone: formattedPhone }
      );

      if (response.status === 200) {
        //verifying number (marking true for notification)
        await axios.patch(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/users/${userId}/verify-number`
        );
        // After updating the phone number, send the Day 1 message and schedule Day 2
        await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/send-initial-messages`,
          {
            userId,
          }
        );

        navigate('/home');
        sendAmplitudeData('PHONE_NUMBER_SIGNUP');
      }

      onClose();
    } catch (error) {
      console.error('Error updating phone number:', error);
      alert('Failed to update phone number');
    }
  };

  return (
    <>
      <div
        className={`fixed inset-0 z-[100] flex items-end justify-center backdrop-brightness-50 bg-black/30 ${
          isOpen ? 'visible' : 'invisible'
        }`}
        onClick={handleBackdropClick}
      >
        <div
          className={`w-full max-w-md rounded-t-[16px] bg-base_white shadow-md transition-transform duration-500 max-h-[85%] overflow-y-auto ${
            isOpen ? 'translate-y-0' : 'translate-y-full'
          }`}
        >
          {/* Modal content */}
          <div className="p-5">
            <div className="flex justify-between items-center mb-[2px]">
              {/* SVG Icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
              >
                {/* SVG Path */}
                <path
                  d="M38.0996 9.82011C36.2657 7.96832 34.0817 6.50004 31.6746 5.50083C29.2676 4.50162 26.6858 3.99147 24.0796 4.00011C13.1596 4.00011 4.25961 12.9001 4.25961 23.8201C4.25961 27.3201 5.17961 30.7201 6.89961 33.7201L4.09961 44.0001L14.5996 41.2401C17.4996 42.8201 20.7596 43.6601 24.0796 43.6601C34.9996 43.6601 43.8996 34.7601 43.8996 23.8401C43.8996 18.5401 41.8396 13.5601 38.0996 9.82011ZM24.0796 40.3001C21.1196 40.3001 18.2196 39.5001 15.6796 38.0001L15.0796 37.6401L8.83961 39.2801L10.4996 33.2001L10.0996 32.5801C8.4547 29.9542 7.58146 26.9187 7.57961 23.8201C7.57961 14.7401 14.9796 7.34011 24.0596 7.34011C28.4596 7.34011 32.5996 9.06011 35.6996 12.1801C37.2348 13.7078 38.4515 15.5252 39.2789 17.5268C40.1063 19.5283 40.528 21.6743 40.5196 23.8401C40.5596 32.9201 33.1596 40.3001 24.0796 40.3001ZM33.1196 27.9801C32.6196 27.7401 30.1796 26.5401 29.7396 26.3601C29.2796 26.2001 28.9596 26.1201 28.6196 26.6001C28.2796 27.1001 27.3396 28.2201 27.0596 28.5401C26.7796 28.8801 26.4796 28.9201 25.9796 28.6601C25.4796 28.4201 23.8796 27.8801 21.9996 26.2001C20.5196 24.8801 19.5396 23.2601 19.2396 22.7601C18.9596 22.2601 19.1996 22.0001 19.4596 21.7401C19.6796 21.5201 19.9596 21.1601 20.1996 20.8801C20.4396 20.6001 20.5396 20.3801 20.6996 20.0601C20.8596 19.7201 20.7796 19.4401 20.6596 19.2001C20.5396 18.9601 19.5396 16.5201 19.1396 15.5201C18.7396 14.5601 18.3196 14.6801 18.0196 14.6601H17.0596C16.7196 14.6601 16.1996 14.7801 15.7396 15.2801C15.2996 15.7801 14.0196 16.9801 14.0196 19.4201C14.0196 21.8601 15.7996 24.2201 16.0396 24.5401C16.2796 24.8801 19.5396 29.8801 24.4996 32.0201C25.6796 32.5401 26.5996 32.8401 27.3196 33.0601C28.4996 33.4401 29.5796 33.3801 30.4396 33.2601C31.3996 33.1201 33.3796 32.0601 33.7796 30.9001C34.1996 29.7401 34.1996 28.7601 34.0596 28.5401C33.9196 28.3201 33.6196 28.2201 33.1196 27.9801Z"
                  fill="url(#paint0_linear_14086_13325)"
                />
                {/* Gradient */}
                <defs>
                  <linearGradient
                    id="paint0_linear_14086_13325"
                    x1="23.9996"
                    y1="4"
                    x2="23.9996"
                    y2="44.0001"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FEA3B4" />
                    <stop offset="1" stopColor="#F7B27A" />
                  </linearGradient>
                </defs>
              </svg>
              <button
                onClick={onClose}
                className="p-[6.66px] flex items-center justify-center bg-gray_100 rounded-[133.333px]"
              >
                <img src={xclose} alt="Close" />
              </button>
            </div>
            <p className="heading-large mt-3 text-light_bg_primary text-opacity-85">
              Notifications on Whatsapp
            </p>
            <p className="text-sm-regular text-light_bg_secondary mb-3">
              Enter your phone number to get your daily scan reminder on
              Whatsapp.
            </p>
            <label className="text-sm-medium text-[#98A2B3]">Phone No.</label>
            <div className="flex mb-6 items-center rounded-[12px] border-[1px] border-[#98A2B3] focus-within:border-[#111927]">
              <img src={flag} alt="Flag" className="ml-3 mr-2 w-6 h-4" />
              <input
                name="phone"
                id="phone"
                type="tel"
                inputMode="numeric"
                value={phone}
                onChange={handlePhoneChange}
                className="text-md-regular text-light_bg_primary bg-[#ffffff] placeholder-light_bg_primary_0.35 flex-1 min-w-0 block w-full px-3 py-3 focus:outline-none rounded-[12px]"
                placeholder="9559799301"
                required
              />
            </div>
            {/* Div to handle the onClick event */}
            <div onClick={handleSubmit} className="mt-4 w-full">
              <CtaButton
                ButtonText="Notify me on Whatsapp"
                className="w-full py-3 bg-blue-600 text-white rounded-md"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Whatsapp;
