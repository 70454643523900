import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout';
import { useLocation } from 'react-router-dom';
import introScanimage from '../../assets/IntroScanImage.png';
import CtaButton from '../../components/CtaButton';

const IntroScan = () => {
  // eslint-disable-next-line no-unused-vars
  const [isSliding, setIsSliding] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (location.state?.animate) {
      const timer = setTimeout(() => setIsSliding(false), 50);
      return () => clearTimeout(timer);
    } else {
      setIsSliding(false);
    }
  }, [location.state]);

  return (
    <div className="w-screen h-screen overflow-hidden flex flex-col">
      <div className="relative">
        <img src={introScanimage} alt="Scan" className="w-full" />

      </div>
      <Layout className="flex-grow flex flex-col justify-between">
      <h1 className="font-inter text-[28px] text-light_bg_primary text-opacity-95 leading-[33.6px] tracking-[-0.28px] mt-3">
      Scan your heart using your finger tip and camera
        </h1>
        <p className='text-md-regular text-light_bg_secondary mt-3'>Gently place your finger on the back camera lens until the view turns red</p>
        <div className="flex-col items-center mt-4">
        </div>
        <div className="w-full max-w-[393px] mx-auto fixed bottom-0 left-0 right-0 px-5 pb-5 mt-4">
          <p className="font-inter text-[12px] font-normal leading-[18px] text-gray_neutral_400 text-center px-6 mb-3">
            By continuing, you agree to our{' '}
            <span className="text-gray_neutral_800">
              Terms of Service
            </span>{' '}
            and allow us to access your device's camera
          </p>
          <div>
            <CtaButton
              ButtonText="Start Scan"
              LinkTo="/intro-scan/scan"
            />
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default IntroScan;
