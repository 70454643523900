import React from "react";
import Layout from "../../components/Layout";
import JourneySuccessImage from "../../assets/JourneySuccessImage.png";
import JourneySuccessCards from "../../assets/JourneySuccessCards.png";
import { Link } from "react-router-dom";

const JourneySuccess = () => {
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundSize: "cover",
          backgroundPosition: "center",
          opacity: 0.4,
          zIndex: -1,
        }}
        className="bg-[#F9FAFB]"
      />
      <div style={{ position: "relative" }}>
        <Layout>
          <h1 className="display-title text-light_bg_primary opacity-85 mt-6">
            Well done!
          </h1>
          <p className="text-md-regular text-light_bg_primary opacity-65 mt-1">
            You’ve successfully completed Day 1 of Resting heart journey.
          </p>
          <div className="flex items-center justify-center mt-5 rounded-[16px]">
            <div>
              <img src={JourneySuccessImage} alt="journey"></img>
            </div>
          </div>
          <p className="heading-large mt-6 text-light_bg_primary opacity-65">
            Do these tasks daily
          </p>
          <div className="flex-col items-center pb-8 justify-center mt-2">
            <img src={JourneySuccessCards} alt="cards"></img>
            <Link
              className="w-full max-w-[393px] mx-auto fixed bottom-0 left-0 right-0 px-5 pb-5 mt-4"
              to="/home"
            >
              <button className="mt-[33px] heading-small flex w-full max-w-[353px] bg-primary_500  justify-center items-center px-5 py-3 min-h-12 rounded-lg mb-3 text-base_white shadow-xs z-10">
                Okay, got it
              </button>
            </Link>
          </div>
        </Layout>
      </div>
    </div>
  );
};

export default JourneySuccess;
