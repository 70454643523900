import React, { useState, useEffect } from 'react';
import introPermissionImage from '../../assets/introPermissionImage.png';
import Apps from '../../assets/Apps.svg';
import whatsapp from '../../assets/whatsapp.svg';
import Layout from '../../components/Layout';
import CtaButton from '../../components/CtaButton';
import { Switch } from '@headlessui/react';
import axios from 'axios';
import Whatsapp from '../../components/Modals/Whatsapp';
import { sendAmplitudeData } from '../../utils/amplitude';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ToggleButton = ({ enabled, onChange, disabled = false }) => {
  return (
    <Switch
      checked={enabled}
      onChange={onChange}
      disabled={disabled}
      className={classNames(
        enabled ? 'bg-[#32D583]' : 'bg-gray-200',
        disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
        'relative inline-flex h-8 w-14 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none'
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? 'translate-x-6' : 'translate-x-0',
          'pointer-events-none inline-block h-7 w-7 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        )}
      />
    </Switch>
  );
};

const Permissions = () => {
  const [isInstallable, setIsInstallable] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [notificationsEnabled, setNotificationsEnabled] = useState(true);
  const [installEnabled, setInstallEnabled] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallable(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    if (window.matchMedia('(display-mode: standalone)').matches) {
      setInstallEnabled(true);
      setIsInstallable(false);
    }

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallToggle = async () => {
    if (!installEnabled && deferredPrompt) {
      try {
        deferredPrompt.prompt();
        const { outcome } = await deferredPrompt.userChoice;
        if (outcome === 'accepted') {
          setInstallEnabled(true);
        }
      } catch (error) {
        console.error('Error during app installation:', error);
      } finally {
        setDeferredPrompt(null);
        setIsInstallable(false);
      }
    } else if (installEnabled) {
      alert('App is already installed.');
    } else {
      alert('App cannot be installed on this device.');
    }
  };

  const handleNotificationToggle = () => {
    setNotificationsEnabled((prev) => !prev);
  };

  const handleContinue = async () => {
    if (notificationsEnabled) {
      const userId = localStorage.getItem('userId');
      try {
        await axios.patch(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/users/${userId}/verify-number`
        );
        setIsModalVisible(true);
        sendAmplitudeData('PUSH_NOTIFICATIONS_ENABLED');
      } catch (error) {
        console.error('Error verifying number:', error);
      }
    } else {
      window.location.href = '/home';
    }
  };

  return (
    <>
      <div className="relative">
        <img src={introPermissionImage} alt="permissions" />
      </div>

      <Layout>
        <div className="flex items-start gap-x-4 mt-6">
          <div className="bg-base_white rounded-[12px] shadow-permission_icon p-2 flex items-center justify-center">
            <img
              src={Apps}
              alt="download"
              className="min-h-[32px] min-w-[32px]"
            />
          </div>

          <div>
            <h3 className="heading-small text-light_bg_primary">
              Install app on your device
            </h3>
            <p className="text-sm-regular mt-1 text-light_bg_secondary">
              Find the app easily whenever you want on your homescreen
            </p>
          </div>
          <div>
            <ToggleButton
              enabled={installEnabled}
              onChange={handleInstallToggle}
              disabled={!isInstallable}
            />
          </div>
        </div>
        <hr className="text-[#E5E7EB] my-4" />
        <div className="flex items-start gap-x-4 mt-6">
          <div className="bg-base_white rounded-[12px] shadow-permission_icon flex items-center justify-center p-2">
            <img
              src={whatsapp}
              alt="download"
              className="min-w-[32px] min-h-[28px]"
            />
          </div>

          <div>
            <h3 className="heading-small text-light_bg_primary">
              Notifications on Whatsapp
            </h3>
            <p className="text-sm-regular mt-1 text-light_bg_secondary">
              Get timely reminders to scan on Whatsapp
            </p>
          </div>
          <div>
            <ToggleButton
              enabled={notificationsEnabled}
              onChange={handleNotificationToggle}
            />
          </div>
        </div>
        <div
          onClick={handleContinue}
          className="w-full max-w-[393px] mx-auto fixed bottom-0 left-0 right-0 px-5 pb-5 mt-4"
        >
          <CtaButton ButtonText="Continue" />
        </div>

        {/* Whatsapp Modal */}
        <Whatsapp
          isOpen={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          userId={localStorage.getItem('userId')}
        />
      </Layout>
    </>
  );
};

export default Permissions;
