import React, { useState } from "react";
import oxygenIconbw from "../../src/assets/oxygenIconbw.svg";
import angularGradientScale from "../../src/assets/angularGradientScale.png";
import OxygenSaturationModal from "./OxygenSaturationModal";

const OxygenSaturation = ({ oxygenSaturation }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const mockOxygenSaturation = 98;
  const saturationValue = oxygenSaturation || mockOxygenSaturation;

  return (
    <>
      <div
        className="flex p-3 mt-3 flex-col items-center max-w-[175px] w-full h-[175px] max-h-[175px] bg-base_white rounded-[16px] shadow-result_card border-[1px] border-light_bg_primary border-opacity-[0.12]"
        onClick={handleOpenModal}
      >
        <div className="flex items-start gap-[6px] mb-2">
          <img src={oxygenIconbw} alt="Oxygen Icon" className="w-5 h-5" />
          <p className="text-sm-medium text-light_bg_primary text-nowrap">
            Oxygen saturation
          </p>
        </div>
        <div className="relative">
          <img
            src={angularGradientScale}
            alt="Scale"
            className="w-[115px] h-[115px]"
          />
          <div className="absolute inset-0 flex flex-col items-center justify-center">
            <p className="display-number-medium text-light_bg_primary">
              {saturationValue}%
            </p>
            <p className="text-light_bg_primary text-opacity-35 text-sm-medium">
              SpO2
            </p>
          </div>
          <div className="absolute flex text-sm-regular text-light_bg_secondary left-2 right-0 bottom-1 justify-between">
            <p>0%</p>
            <p className="">100%</p>
          </div>
        </div>
      </div>
      <OxygenSaturationModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        oxygenSaturation={saturationValue}
      />
    </>
  );
};

export default OxygenSaturation;
