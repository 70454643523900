import React from "react";
import xclose from "../../src/assets/x-close.svg";
import HandOpenHeart from "../../src/assets/HandOpenHeart.svg";

const HeartRateModal = ({ isOpen, onClose, heartRate, guidanceText }) => {
  const isHeartRateNormal = heartRate >= 56 && heartRate <= 80;

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      <div
        className={`fixed inset-0 z-50 flex items-end justify-center backdrop-brightness-50 bg-black/30 ${
          isOpen ? "visible" : "invisible"
        }`}
        onClick={handleBackdropClick}
      >
        <div
          className={`w-full max-w-md rounded-t-[16px] bg-base_white shadow-md transition-transform duration-500 max-h-[75%] overflow-y-auto ${
            isOpen ? "translate-y-0" : "translate-y-full"
          }`}
        >
          {/* Modal content */}
          <div className="p-5">
            <div className="flex justify-between items-center mb-[2px]">
              <h2 className="display-title text-light_bg_primary">
                Heart rate
              </h2>
              <button
                onClick={onClose}
                className="p-[6.66px] flex items-center justify-center bg-gray_100 rounded-[133.333px]"
              >
                <img src={xclose} alt="Close" />
              </button>
            </div>
            <p className="text-light_bg_secondary text-md-regular mb-5">
              The speed at which your heart beats
            </p>
            <div className="bg-[#F9FAFB] rounded-[16px]">
              <div className="flex pt-3 px-4 mt-2 items-baseline gap-x-[6px]">
                <h3 className="display-number-medium text-light_bg_primary">
                  {heartRate}
                </h3>
                <p className="text-sm-regular text-light_bg_secondary">
                  Beats/min
                </p>
              </div>
              <p className="text-light_bg_secondary text-sm-regular pb-3 px-4 mt-[4px]">
                Adults at rest have a normal resting heart rate between 50 to 84
                bpm.{" "}
                <span
                  className={
                    isHeartRateNormal ? "text-success_500" : "text-[#F79009]"
                  }
                >
                  {isHeartRateNormal
                    ? "Yours lies in the typical range of resting adults."
                    : "Your heart rate is outside the typical range."}
                </span>
              </p>
            </div>
            <div className="my-6">
              <div className="bg-[#0BA5EC] rounded-[16px] py-3 px-4 mt-2">
                <div className="flex items-center">
                  <img src={HandOpenHeart} alt="Heart" />
                  <h3 className="ml-2 text-[#F5FBFF] text-[20px] font-[500] font-inter leading-[24px] tracking-[-0.2px]">
                    Our advice
                  </h3>
                </div>
                <p className="text-[#F5FBFF] text-md-regular mt-2">
                  {guidanceText}
                </p>
              </div>
            </div>
            <div className="">
              <p className="text-light_bg_primary heading-large mb-2">
                Symptom information
              </p>
              <div className="bg-[#F9FAFB] p-4 rounded-[16px]">
                <ul className="list-disc text-light_bg_secondary pl-5 text-md-regular">
                  <li className="mb-2">
                    A resting heart rate under 50 bpm is of concern. Symptoms
                    can range from dizziness, weakness, etc.
                  </li>
                  <li>
                    Above 84 bpm can mean there's a heart issue you need to be
                    aware of.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeartRateModal;
