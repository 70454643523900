import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SignUP from './pages/SignUP/SignUP';
import DesktopHome from './pages/SignUP/DesktopHome';
import Login from './pages/SignUP/Login';
import CreateAccount from './pages/SignUP/CreateAccount';
import UserDetails from './pages/SignUP/UserDetails';
import Permissions from './pages/SignUP/Permissions';
import IntroScan from './pages/Scan/IntroScan';
import CameraPermission from './pages/Scan/CameraPermission';
import HealthScan from './pages/Scan/HealthScan';
import MoodLog1 from './pages/Mood/MoodLog1';
import MoodLog2 from './pages/Mood/MoodLog2';
import VitalResultsFirst from './pages/Results/VitalResultsFirst';
import Home from './pages/Home/Home';
import Profiler from './pages/Journeys/Profiler';
import ProfilerSubscription from './pages/Success/ProfilerSubscription';
import IntroQuestionnaire from './pages/Questionnaire/IntroQuestionnaire';
import Questionnaire from './pages/Questionnaire/Questionnaire';
import Day1 from './pages/Success/Day1';
import TodaysResult from './pages/Results/TodaysResult';
import PreQuestionnaire from './pages/Questionnaire/PreQuestionnaire';
import AuthWrapper from './utils/AuthWrapper';
import LoginAuthWrapper from './utils/LoginAuthWrapper';
import OnboardAuthWrapper from './utils/OnboardAuthWrapper';
import Day1Result from './pages/Results/Day1Results';
import Result from './pages/Results/Result';
import ProfilerResult from './pages/Results/ProfilerResult';
import JourneySuccess from './pages/Success/JourneySuccess';
import Blog1 from './pages/Blogs/Blog1';
import Blog2 from './pages/Blogs/Blog2';
import Blog3 from './pages/Blogs/Blog3';
import ProfilerSuccess from './pages/Success/ProfilerSuccess';
import ScanSuccessTransition from './pages/Success/ScanSuccessTransition';
import UserDetailSuccess from './pages/Success/UserDetailSuccess';
import DailyVitalResults from './pages/Results/DailyVitalResults';
import DailyHealthScan from './pages/Scan/DailyHealthScan';
import MoodLogDay4 from './pages/Mood/MoodLogDay4';
import Feedback from './pages/Feedback/Feedback';
import FeedbackSuccess from './pages/Success/FeedbackSuccess';
import PageTransition from './pages/Success/PageTransition';
import ScrollToTop from './utils/ScrollToTop';
import useIsMobile from './utils/useIsMobile';

function App() {
  const isMobile = useIsMobile();
  return (
    <BrowserRouter>
      <ScrollToTop />
      <PageTransition>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <OnboardAuthWrapper>
                {isMobile ? <SignUP /> : <DesktopHome />}
              </OnboardAuthWrapper>
            }
          />
          <Route
            path="/signup"
            element={
              <OnboardAuthWrapper>
                <SignUP />
              </OnboardAuthWrapper>
            }
          />
          <Route
            path="/signup/questionnaire"
            element={<PreQuestionnaire />}
          />
          <Route path="/intro-scan" element={<IntroScan />} />
          <Route path="/intro-scan/scan" element={<HealthScan />} />
          <Route
            path="/scan-success"
            element={<ScanSuccessTransition />}
          />
          <Route
            path="/intro-scan/scan/mood1"
            element={
              <AuthWrapper>
                <MoodLog1 />
              </AuthWrapper>
            }
          />
          <Route
            path="/intro-scan/scan/mood2"
            element={
              <AuthWrapper>
                <MoodLog2 />
              </AuthWrapper>
            }
          />
          <Route
            path="intro-scan/camera-permission"
            element={<CameraPermission />}
          />
          <Route
            path="/intro-scan/results"
            element={
              <AuthWrapper>
                <VitalResultsFirst />
              </AuthWrapper>
            }
          />
          <Route
            path="/create-account"
            element={
              <AuthWrapper>
                <CreateAccount />
              </AuthWrapper>
            }
          />
          {/**No auth, first onboarding */}
          <Route
            path="/create-account/user-details"
            element={<UserDetails />}
          />
          <Route
            path="/create-account/success"
            element={<UserDetailSuccess />}
          />
          <Route
            path="/create-account/permissions"
            element={<Permissions />}
          />
          <Route
            path="/home"
            element={
              <LoginAuthWrapper>
                <Home />
              </LoginAuthWrapper>
            }
          />
          <Route
            path="/daily-vital-results"
            element={
              <LoginAuthWrapper>
                <DailyVitalResults />
              </LoginAuthWrapper>
            }
          />
          <Route
            path="/daily-health-scan"
            element={
              <LoginAuthWrapper>
                <DailyHealthScan />
              </LoginAuthWrapper>
            }
          />
          <Route
            path="/intro-scan/scan/day4/mood1"
            element={
              <LoginAuthWrapper>
                <MoodLogDay4 />
              </LoginAuthWrapper>
            }
          />
          <Route
            path="/home/profiler-journey"
            element={
              <LoginAuthWrapper>
                <Profiler />
              </LoginAuthWrapper>
            }
          />
          <Route
            path="/intro-questionnaire"
            element={
              <LoginAuthWrapper>
                <IntroQuestionnaire />
              </LoginAuthWrapper>
            }
          />
          <Route
            path="/intro-questionnaire/questions"
            element={
              <LoginAuthWrapper>
                <Questionnaire />
              </LoginAuthWrapper>
            }
          />
          <Route
            path="/intro-questionnaire/day1"
            element={
              <LoginAuthWrapper>
                <Day1 />
              </LoginAuthWrapper>
            }
          />
          <Route
            path="todays-result"
            element={
              <LoginAuthWrapper>
                <TodaysResult />
              </LoginAuthWrapper>
            }
          />
          <Route
            path="/profilerresult"
            element={
              <LoginAuthWrapper>
                <ProfilerResult />
              </LoginAuthWrapper>
            }
          />
          <Route
            path="/journey-success"
            element={
              <LoginAuthWrapper>
                <JourneySuccess />
              </LoginAuthWrapper>
            }
          />
          <Route
            path="/day1-result"
            element={
              <LoginAuthWrapper>
                <Day1Result />
              </LoginAuthWrapper>
            }
          />
          <Route
            path="/results"
            element={
              <LoginAuthWrapper>
                <Result />
              </LoginAuthWrapper>
            }
          />

          <Route
            path="/home/blog1"
            element={
              <LoginAuthWrapper>
                <Blog1 />
              </LoginAuthWrapper>
            }
          />
          <Route
            path="/home/blog2"
            element={
              <LoginAuthWrapper>
                <Blog2 />
              </LoginAuthWrapper>
            }
          />
          <Route
            path="/home/blog3"
            element={
              <LoginAuthWrapper>
                <Blog3 />
              </LoginAuthWrapper>
            }
          />
          <Route
            path="/profiler-success"
            element={
              <LoginAuthWrapper>
                <ProfilerSuccess />
              </LoginAuthWrapper>
            }
          />
          <Route
            path="/home/profiler-journey/subscription-success"
            element={
              <LoginAuthWrapper>
                <ProfilerSubscription />
              </LoginAuthWrapper>
            }
          />
          <Route
            path="/feedback"
            element={
              <LoginAuthWrapper>
                <Feedback />
              </LoginAuthWrapper>
            }
          />
          <Route
            path="/feedback-success"
            element={
              <LoginAuthWrapper>
                <FeedbackSuccess />
              </LoginAuthWrapper>
            }
          />
        </Routes>
      </PageTransition>
    </BrowserRouter>
  );
}

export default App;
