import React from "react";
import xclose from "../../src/assets/x-close.svg";
import HandOpenHeart from "../../src/assets/HandOpenHeart.svg";
import CaretUp from "../assets/CaretUp.svg";
import CaretDown from "../assets/CaretDown.svg";

// Simulating a database fetch function for guidance text
const fetchBloodPressureText = (systolic, diastolic) => {
  let systolicText = "";
  let diastolicText = "";

  if (systolic < 90) {
    systolicText =
      "Your systolic BP is very low. You should seek immediate medical attention to control your BP.";
  } else if (systolic > 140) {
    systolicText =
      "Your systolic BP is very high. You should seek immediate medical attention to control your BP.";
  } else if (systolic >= 90 && systolic <= 120) {
    systolicText =
      "Congrats! Your systolic BP is within the normal range. Continue managing stress, ensure proper sleep, and maintain a healthy lifestyle.";
  } else {
    systolicText =
      "Your systolic BP is higher than normal. Take action now to control your BP. Manage stress, reduce sodium intake, and ensure proper sleep.";
  }

  if (diastolic < 60) {
    diastolicText =
      "Your diastolic BP is very low. You should seek immediate medical attention to control your BP.";
  } else if (diastolic > 90) {
    diastolicText =
      "Your diastolic BP is very high. You should seek immediate medical attention to control your BP.";
  } else if (diastolic >= 60 && diastolic <= 80) {
    diastolicText =
      "Congrats! Your diastolic BP is within the normal range. Continue managing stress and maintaining a healthy lifestyle.";
  } else {
    diastolicText =
      "Your diastolic BP is higher than normal. Take action now to control your BP. Avoid smoking, manage stress, and live a healthy lifestyle.";
  }

  return {
    systolicText,
    diastolicText,
  };
};

const BloodPressureModal = ({ isOpen, onClose, systolic, diastolic }) => {
  const isSystolicNormal = systolic <= 120 && systolic >= 90;
  const isDiastolicNormal = diastolic <= 80 && diastolic >= 60;

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const { systolicText, diastolicText } = fetchBloodPressureText(
    systolic,
    diastolic
  );

  return (
    <>
      <div
        className={`fixed inset-0 z-50 flex items-end justify-center backdrop-brightness-50 bg-black/30 ${
          isOpen ? "visible" : "invisible"
        }`}
        onClick={handleBackdropClick}
      >
        <div
          className={`w-full max-w-md rounded-t-[16px] bg-base_white shadow-md max-h-[75%] overflow-y-auto transition-transform duration-500 ${
            isOpen ? "translate-y-0" : "translate-y-full"
          }`}
        >
          {/* Modal content */}
          <div className="p-5 pb-24">
            <div className="flex justify-between items-center mb-[2px]">
              <h2 className="display-title text-light_bg_primary">
                Blood pressure
              </h2>
              <button
                onClick={onClose}
                className="p-[6.66px] flex items-center justify-center bg-gray_100 rounded-[133.333px]"
              >
                <img src={xclose} alt="Close" />
              </button>
            </div>
            <p className="text-light_bg_secondary text-md-regular mb-5">
              Measures how hard your heart pushes blood
            </p>
            <div className="bg-[#F9FAFB] rounded-[16px]">
              <div className="flex pt-3 px-4 mt-2 items-baseline gap-x-[6px]">
                <h3 className="ph-display-number text-light_bg_primary">
                  {systolic}/{diastolic}
                </h3>
                <p className="body-large text-gray_400">mmHg</p>
              </div>
              <p className="text-light_bg_secondary text-sm-regular pb-3 px-4 mt-[4px]">
                Normal blood pressure for most adults is defined as a systolic
                pressure of less than 120 and a diastolic pressure of less than
                80.{" "}
                <span
                  className={
                    isSystolicNormal && isDiastolicNormal
                      ? "text-success_500"
                      : "text-[#F79009]"
                  }
                >
                  {isSystolicNormal && isDiastolicNormal
                    ? "Yours lie in the healthy range."
                    : "Your readings are outside the healthy range."}
                </span>
              </p>
              <div className="flex justify-start items-center px-4">
                <p className="text-gray_800 font-inter text-[16px] font-[600] leading-[22.4px]">
                  Systolic - <span className="ml-1">{systolic}</span>
                </p>
                <img
                  src={isSystolicNormal ? CaretDown : CaretUp}
                  alt={isSystolicNormal ? "caret down" : "caret up"}
                  className="ml-[2px]"
                />
              </div>
              <p className="mx-4 mt-[2px] body-large text-[#667085]">
                The highest pressure when your heart contracts.
              </p>
              <div className="flex justify-start items-center px-4">
                <p className="text-gray_800 font-inter text-[16px] font-[600] leading-[22.4px]">
                  Diastolic - <span>{diastolic}</span>
                </p>
                <img
                  src={isDiastolicNormal ? CaretDown : CaretUp}
                  alt={isDiastolicNormal ? "caret down" : "caret up"}
                  className="ml-[2px]"
                />
              </div>
              <p className="mx-4 mt-[2px] body-large text-[#667085]">
                The lowest pressure when your heart contracts.
              </p>
            </div>
            <div className="my-6">
              <div className="bg-[#0BA5EC] rounded-[16px] py-3 px-4 mt-2">
                <div className="flex items-center">
                  <img src={HandOpenHeart} alt="Heart" />
                  <h3 className="ml-2 text-[#F5FBFF] text-[20px] font-[500] font-inter leading-[24px] tracking-[-0.2px]">
                    Our advice
                  </h3>
                </div>
                <p className="text-[#F5FBFF] text-md-regular mt-2">
                  {systolicText}
                </p>
                <p className="text-[#F5FBFF] text-md-regular mt-2">
                  {diastolicText}
                </p>
              </div>
            </div>
            <div>
              <p className="text-light_bg_primary heading-large mb-2">
                Symptom information
              </p>
              <div className="bg-[#F9FAFB] p-4 rounded-[16px]">
                <ul className="list-disc text-light_bg_secondary pl-5 text-md-regular">
                  <li className="mb-2">
                    High BP, often known as Hypertension, is defined as a
                    systolic reading of 140 mmHg at rest or a diastolic reading
                    of 90 mmHg or higher.
                  </li>
                  <li>
                    The higher the values, the more severe the condition is. For
                    example, a regular BP reading is of 120/80 mmHg.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BloodPressureModal;
