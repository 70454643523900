import React, { useState, useEffect } from "react";
import facts from "../components/health_facts.json";
import { sendAmplitudeData } from "../utils/amplitude";

const Facts = () => {
  const [selectedFacts, setSelectedFacts] = useState([]);

  useEffect(() => {
    const getRandomFacts = () => {
      const shuffled = [...facts].sort(() => 0.5 - Math.random());
      return shuffled.slice(0, 5);
    };

    setSelectedFacts(getRandomFacts());
  }, []);

  const handleScroll = () => {
    sendAmplitudeData("SCROLLED_FACTS");
  };

  return (
    <div className="bg-gray_neutral_100 rounded-[16px] pb-3">
      <h2 className="text-gray_400 font-inter leading-6 font-[400] ml-5 py-2">
        FACTS FOR HEALTH
      </h2>
      <div
        className="flex overflow-x-auto space-x-3 ml-5 snap-x snap-mandatory"
        onScroll={handleScroll}
      >
        {selectedFacts.map((fact, index) => (
          <div
            key={index}
            className="min-w-[336px] max-h-[250px] rounded-[12px] bg-base_white snap-center"
          >
            <p className="text-xl-regular pt-[35px] px-6 pb-[35px] text-gray_800">
              {fact.fact}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Facts;
