import React, { useEffect, useRef, useState } from 'react';
import Facts from '../../components/Facts';
import Layout from '../../components/Layout';
import { fingerScan } from 'careplix-web-algo';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import greenTick from '../../assets/greenTick.svg';
import redFail from '../../assets/redFail.png';
import ScanTouch from '../../assets/ScanTouch.svg';
import ScanGreenTick from '../../assets/ScanGreenTick.svg';
import GreenOngoing from '../../assets/GreenOngoing.svg';
import SquareProgressBar from '../../components/SquareProgressBar';
import xclose from '../../assets/x-close.svg';
import HeartAnimation from '../../components/HeartAnimation';
import CornerSVG from './CornerSVG';
import { Link } from 'react-router-dom';

const DailyHealthScan = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const [step1Complete, setStep1Complete] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [step2Complete, setStep2Complete] = useState(false);
  const [step1Ongoing, setStep1Ongoing] = useState(false);
  const [step2Ongoing, setStep2Ongoing] = useState(false);
  const [showFacts, setShowFacts] = useState(false);
  const [showSteps, setShowSteps] = useState(true);
  const [error, setError] = useState(false);
  const [scanPercentage, setScanPercentage] = useState(0);
  const [showScanTouch, setShowScanTouch] = useState(false);
  const [showScanGreenTick, setShowScanGreenTick] = useState(false);
  const [showScanPercentage, setShowScanPercentage] = useState(false);
  const [scanStarted, setScanStarted] = useState(false);

  const convertDobFormat = (dob) => {
    const [day, month, year] = dob.split('/');
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const initializeScan = async () => {
      try {
        const userId = localStorage.getItem('userId');

        const userResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/users/${userId}`
        );
        const { height, weight, gender, dob } = userResponse.data;
        const formattedDob = convertDobFormat(dob);

        const tokenResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/vitals/create-token`,
          {
            userId: userId,
          }
        );
        localStorage.setItem(
          'scan_token',
          tokenResponse.data.scan_token
        );

        fingerScan.onFrame(({ type, percentage, isFingerInView }) => {
          if (
            type === 'calibration' &&
            percentage > 0 &&
            isFingerInView
          ) {
            setStep1Ongoing(true);
            setShowScanTouch(true);
          }
          if (
            type === 'calibration' &&
            percentage > 50 &&
            isFingerInView
          ) {
            setStep1Complete(true);
            setShowScanTouch(false);
            setShowScanGreenTick(true);
            setStep2Ongoing(true);
          }
          if (
            type === 'calibration' &&
            percentage === 100 &&
            isFingerInView
          ) {
            setShowScanGreenTick(false);
          }
          if (type === 'scan' && percentage > 0 && isFingerInView) {
            setScanPercentage(Math.min(percentage, 99));
            setShowScanPercentage(true);
          }
          if (type === 'scan' && percentage > 10 && isFingerInView) {
            setShowSteps(false);
            setShowFacts(true);
          }
        });

        fingerScan.onError((err) => {
          setShowScanPercentage(false);
          setShowScanGreenTick(false);
          setShowFacts(false);
          setError(true);
        });

        fingerScan.onScanFinish(
          async ({ raw_intensity, ppg_time, average_fps }) => {
            const data = {
              api_key: process.env.REACT_APP_CAREPLIX_API_KEY,
              scan_token: localStorage.getItem('scan_token'),
              user_id: userId,
              posture: 'Resting',
              long: null,
              lat: null,
              ref_id: ' ',
              metadata: {
                physiological_scores: {
                  height: height,
                  weight: String(weight),
                },
                ppg_time,
                raw_intensity,
                device: 'RPPG_CAREPLIX_FINGER_ANDROID',
                fps: 126,
              },
              gender: gender,
              dob: formattedDob,
              platform: 'ios',
              app_version: '5.0.0',
            };
            try {
              const postResponse = await axios.post(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/api/vitals/add-scan`,
                data,
                {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                }
              );

              localStorage.setItem(
                'dailyScanData',
                JSON.stringify(postResponse.data)
              );

              navigate('/daily-vital-results');
            } catch (error) {
              setError(true);
            }
          }
        );

        await fingerScan.startScan({
          calibrationTime_inSec: 15,
          scanTime_inSec: 30,
          strict_mode: false,
          videoElement: videoRef.current,
          canvasElement: canvasRef.current,
        });
        setScanStarted(true);
      } catch (error) {
        setError(true);
      }
    };

    initializeScan();

    return () => {
      fingerScan.stopScan(true);
    };
  }, [navigate]);

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <>
      <Layout>
        <div className="relative flex-col items-center w-full">
          <div className="flex justify-center w-full items-center mb-1">
            <h1 className="mt-5 mb-5 text-light_bg_secondary text-[20px] font-inter font-[500] leading-6 tracking-[0.2px]">
              Scan
            </h1>
          </div>
          <Link to="/home">
            <button className="absolute top-[16px] right-0 p-[6.33px] flex items-center justify-center bg-gray_100 rounded-[133.333px]">
              <img src={xclose} alt="Close" />
            </button>
          </Link>
          <div className="h-[250px] flex justify-center relative">
            <video
              id="videoInput"
              ref={videoRef}
              style={{
                position: 'fixed',
                right: '1rem',
                top: '1rem',
                height: '1px',
                width: '1px',
              }}
              autoPlay
              muted
              playsInline
            ></video>
            <div
              className="relative"
              style={{
                maxWidth: '284px',
                maxHeight: '238px',
                width: '100%',
                backgroundColor: scanStarted
                  ? 'transparent'
                  : '#111927',
                borderRadius: '15px',
              }}
            >
              <canvas
                id="canvasOutput"
                ref={canvasRef}
                style={{
                  width: '100%',
                  height: '100%',
                  transform: 'scaleX(-1)',
                  borderRadius: '15px',
                }}
              ></canvas>
              {!scanStarted && <CornerSVG />}
              {!scanStarted && <HeartAnimation />}
              {showScanPercentage && (
                <SquareProgressBar
                  color="#32D583"
                  stroke={4}
                  progress={scanPercentage}
                />
              )}
              {showScanTouch && (
                <img
                  src={ScanTouch}
                  alt="Scan Touch"
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                />
              )}
              {showScanGreenTick && (
                <img
                  src={ScanGreenTick}
                  alt="Scan Green Tick"
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                />
              )}
              {showScanPercentage && (
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 display-md-regular text-white">
                  <div className="text-center">
                    {scanPercentage}%
                    <div className="text-[14px] font-inter text-white text-nowrap">
                      {scanPercentage < 75 &&
                        "Scanning, don't lift your finger yet"}
                      {scanPercentage >= 75 &&
                        scanPercentage < 90 &&
                        'Hang tight'}
                      {scanPercentage >= 90 && 'Almost there'}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {error && (
              <div className="text-sm-regular absolute mt-24 flex-col items-center justify-center text-white text-center">
                <p>Finger not detected</p>
                <p>Refresh to try again</p>
              </div>
            )}
          </div>
        </div>
        <div className="flex-col items-center mt-10">
          {showSteps && (
            <>
              <h2 className="text-md-regular mb-3 text-light_bg_secondary">
                FOLLOW THESE STEPS
              </h2>
              <div className="relative">
                <div className="absolute left-[11px] top-3 bottom-7 -z-10 w-[1px] bg-[#E5E7EB]"></div>
                <ul className="list-none p-0">
                  <li className="flex items-center mb-4 relative">
                    <span
                      className={`indicator flex-shrink-0 mr-3 ${
                        step1Complete ? 'complete' : ''
                      }`}
                    >
                      {error ? (
                        <img
                          src={redFail}
                          alt="Scan Failed"
                          className="w-6 h-6 rounded-full"
                        />
                      ) : step1Complete ? (
                        <img
                          src={greenTick}
                          alt="Step 1 Complete"
                          className="w-6 h-6 bg-success_200 rounded-full pt-[4px] pb-[4.67px] px-[2.67px] border border-success_200"
                        />
                      ) : step1Ongoing ? (
                        <img
                          src={GreenOngoing}
                          alt="Step 1 Ongoing"
                          className="w-6 h-6 bg-success_200 rounded-full pt-[4px] pb-[4.67px] px-[2.67px] border border-success_200"
                        />
                      ) : (
                        <div className="w-6 h-6 rounded-full border-[1px] border-[#E5E7EB] bg-white z-10"></div>
                      )}
                    </span>
                    <span className="text-md-regular text-light_bg_primary pt-1">
                      Lightly place your finger on the back camera
                      lens till the view turns{' '}
                      <span
                        style={{
                          color: showScanGreenTick
                            ? '#F63D68'
                            : 'inherit',
                        }}
                      >
                        red
                      </span>
                    </span>
                  </li>
                  <li className="flex items-center relative">
                    <span
                      className={`indicator flex-shrink-0 mr-3 ${
                        step2Complete ? 'complete' : ''
                      }`}
                    >
                      {error ? (
                        <img
                          src={redFail}
                          alt="Scan Failed"
                          className="w-6 h-6 rounded-full"
                        />
                      ) : step2Complete ? (
                        <img
                          src={greenTick}
                          alt="Step 2 Complete"
                          className="w-6 h-6 bg-success_200 rounded-full pt-[4px] pb-[4.67px] px-[2.67px] border border-success_200"
                        />
                      ) : step2Ongoing ? (
                        <img
                          src={GreenOngoing}
                          alt="Step 2 Ongoing"
                          className="w-6 h-6 bg-success_200 rounded-full pt-[4px] pb-[4.67px] px-[2.67px] border border-success_200"
                        />
                      ) : (
                        <div className="w-6 h-6 rounded-full border-[1px] border-[#E5E7EB] bg-white z-10"></div>
                      )}
                    </span>
                    <span className="text-md-regular text-light_bg_primary pt-1">
                      Hold it until the camera view is fully covered
                      till the scan is complete
                    </span>
                  </li>
                </ul>
              </div>
            </>
          )}
          {error && (
            <div className="mt-6 flex justify-center">
              <button
                onClick={handleRefresh}
                className=" bg-light_bg_primary w-full px-5 py-3 max-w-[345px] text-base_white rounded-[8px]"
              >
                Refresh
              </button>
            </div>
          )}
        </div>
        <div className="text-light_bg_primary text-opacity-95 text-sm-regular bottom-8 absolute">
          <ul className="list-disc pl-5 pr-5">
            <li>Make sure you are in a well-lit room.</li>
            <li>
              If you are in a dimly-lit room, point your camera
              towards a light source or take help of your mobile's
              flashlight.
            </li>
          </ul>
        </div>
      </Layout>

      {showFacts && (
        <div className=" bottom-0 fixed overflow-x-auto w-full z-20">
          <Facts />
        </div>
      )}
    </>
  );
};

export default DailyHealthScan;
