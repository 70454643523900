import React from "react";
import preOnboardSuccessLogo from "../assets/preOnboardSuccessLogo.svg";

const Loader = () => {
  const loaderStyle = {
    width: "130px",
    height: "130px",
    animation: "heartBeat 1.5s infinite cubic-bezier(0.215, 0.61, 0.355, 1)",
  };

  const containerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
    position: "fixed",
    top: "0",
    left: "0",
  };

  const keyframesStyle = `
    @keyframes heartBeat {
      0% { transform: scale(0.95); }
      5% { transform: scale(1.1); }
      39% { transform: scale(0.85); }
      45% { transform: scale(1); }
      60% { transform: scale(0.95); }
      100% { transform: scale(0.9); }
    }
  `;

  return (
    <div style={containerStyle}>
      <style>{keyframesStyle}</style>
      <img src={preOnboardSuccessLogo} alt="Loader" style={loaderStyle} />
    </div>
  );
};

export default Loader;
